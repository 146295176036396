import { useEffect, useState } from "react";
import { Prompt } from "react-router";
import {
  IonPage,
  IonLoading,
  useIonRouter,
  IonAlert,
  IonList,
} from "@ionic/react";
import {
  useDynamicDataProvider,
  useZeusDataProvider,
} from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerText,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerDatePicker,
  PartnerItem,
} from "@understandit/partnerplattformen-storybook-30876108";
import dayjs from "dayjs";

import { useAppState } from "../../app-context";
import { getSetting } from "../../settings";
import { makeid } from "../../helpers";
import { useCamera } from "../../hooks";
import DynamicTextArea from "../../components/DynamicTextarea";
import PartnerAccesField from "../../components/PartnerAccessField";
import TermAccesField from "../../components/TermAccessField";

export const AdminCreateEvent = () => {
  const { formState, setFormState } = useAppState();
  const [validationError, setValidationError] = useState("");
  const [image, setImage] = useState(null);
  const [myPartner] = useDynamicDataProvider("my-partner", null);
  const [dateRange, setDateRange] = useState({
    start: dayjs().format(),
    end: dayjs().format(),
  });
  const { data, setData, isLoading, error, status } = useZeusDataProvider(
    "admin-create-event",
    null
  );
  const router = useIonRouter();
  const { getPhoto } = useCamera();

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  const updateGuestState = (e) => {
    setFormState((current) => {
      return {
        ...current,
        field_survey: {
          ...current.field_survey,
          guests: e.detail.checked ? 1 : 0,
        },
      };
    });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const doSubmit = (e) => {
    e?.preventDefault();

    if (!formState.label) {
      setValidationError("Du måste fylla i en rubrik!");
      return;
    }

    const data = {
      ...formState,
      field_date: {
        value: dateRange.start,
        end_value: dateRange.end,
      },
      field_survey: JSON.stringify(formState.field_survey),
      status: false,
      _group: myPartner.id,
      _type: "event",
    };

    if (image) {
      data._files = [
        {
          field: "field_image",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    data._relationships = [
      {
        field: "field_event_access_term",
        data: formState.field_event_access_term.map((id) => ({
          id,
          type: "taxonomy_term--partner_type",
        })),
      },
      {
        field: "field_event_access_partner",
        data: formState.field_event_access_partner.map((id) => ({
          id,
          type: "group--partner",
        })),
      },
    ];

    delete data.field_event_access_partner;
    delete data.field_event_access_term;

    setData(data);
  };

  useEffect(() => {
    setFormState({
      label: "",
      field_location: "",
      field_description: "",
      field_preamble: "",
      field_link: "",
      field_contact_email: "",
      field_event_access_term: [],
      field_event_access_partner: [],
      field_survey: {
        version: "1.0",
        components: [],
        guests: 0,
      },
    });
  }, [setFormState]);

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error !== ""}
        header={getSetting("APP_NAME")}
        message={error}
      />
      <IonAlert
        isOpen={validationError !== ""}
        header={getSetting("APP_NAME")}
        message={validationError}
        onDidDismiss={() => setValidationError("")}
        buttons={["Ok"]}
      />
      <IonAlert
        isOpen={data !== null}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() =>
          router.canGoBack()
            ? router.goBack()
            : router.push("/u/admin/content", "back")
        }
        message="Eventet har skapats."
      />
      <Prompt
        message={(location) => {
          if (
            !location.pathname.includes("survey") &&
            !location.pathname.includes("create/event")
          ) {
            return getSetting("NAVIGATION_PROMPT");
          }
          return true;
        }}
        when={status !== "created"}
      />

      <PageWithCollapsableHeader
        title="Skapa event"
        mode="back"
        defaultHref={`/u/admin/content`}
      >
        <IonList>
          <PartnerDatePicker
            label="Från"
            value={dateRange.start}
            format="D MMM, YYYY HH:mm"
            onChange={(ev) =>
              setDateRange({ ...dateRange, start: ev.detail.value })
            }
          />
          <PartnerDatePicker
            label="Till"
            value={dateRange.end}
            format="D MMM, YYYY HH:mm"
            onChange={(ev) =>
              setDateRange({ ...dateRange, end: ev.detail.value })
            }
          />
          <PartnerAccesField
            value={formState.field_event_access_partner}
            onChange={updateFormState("field_event_access_partner")}
          />
          <TermAccesField
            value={formState.field_event_access_term}
            onChange={updateFormState("field_event_access_term")}
          />
        </IonList>

        <PartnerContainer>
          <PartnerText modifiers="partner-text--center partner-text--small-margin-bottom partner-text--mini">
            Ladda upp bild
          </PartnerText>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            img={image?.webPath}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>

        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.label}
              onChange={updateFormState("label")}
              label="Rubrik"
            />
            <PartnerInput
              value={formState.field_location}
              onChange={updateFormState("field_location")}
              label="Plats"
            />
            <DynamicTextArea
              value={formState.field_preamble}
              onChange={updateFormState("field_preamble")}
              label="Ingress"
            />
            <DynamicTextArea
              value={formState.field_description}
              onChange={updateFormState("field_description")}
              label="Beskrivning"
            />
            <PartnerInput
              value={formState.field_link}
              onChange={updateFormState("field_link")}
              label="Länk"
            />
            <PartnerInput
              value={formState.field_contact_email}
              onChange={updateFormState("field_contact_email")}
              label="E-post för kontakt"
            />
            <PartnerItem
              label="Tillåt gästanmälan"
              toggle={true}
              modifiers="partner-item--no-padding"
              lines="none"
              toggleChecked={formState.field_survey?.guests > 0}
              toggleChanged={updateGuestState}
            />
            <PartnerButton
              text="Hantera anmälningsformulär"
              expand="block"
              modifiers="partner-button--margin-top-mega partner-button--secondary"
              routerLink="/u/admin/create/event/survey"
            />
            <PartnerButton
              text="Spara"
              expand="block"
              modifiers="partner-button--margin-top"
              onClick={doSubmit}
            />
          </form>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id="admin-create-event" send-tags="group-event">
        <zeus-data-source-drupal-subgroup-create
          base-url={getSetting("BACKEND")}
        ></zeus-data-source-drupal-subgroup-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminCreateEvent;
