import { PartnerSelect } from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";
import { usePartnerOptions } from "../hooks";

const PartnerAccesField = ({ onChange, value }) => {
  const partnerOptions = usePartnerOptions();

  return (
    <PartnerSelect
      icon="icons/chevron-down16.svg"
      multiple
      interface="alert"
      interfaceOptions={{
        message: getSetting("HELP_TEXT_ACCESS_PARTNER"),
      }}
      label="Specifika partners"
      placeholder="Välj"
      options={partnerOptions}
      value={value}
      onChange={onChange}
    />
  );
};
export default PartnerAccesField;
