import { useEffect, useState } from "react";
import { IonLoading, IonPage, useIonRouter, IonAlert } from "@ionic/react";
import { useParams } from "react-router-dom";
import {
  PartnerContainer,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerText,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import { useCamera } from "../hooks";
import { makeid } from "../helpers";

export const PartnerSettings = () => {
  const router = useIonRouter();
  const { id } = useParams();
  const providerId = `edit-partner-${id}`;
  const {
    data: partner,
    setData,
    isLoading,
    status,
  } = useZeusDataProvider(providerId);
  const { getPhoto } = useCamera();
  const [image, setImage] = useState(null);
  const [formState, setFormState] = useState({
    label: "",
    field_email: "",
    field_social_facebook: "",
    field_social_instagram: "",
    field_social_twitter: "",
    field_social_linkedin: "",
    field_description: "",
  });

  useEffect(() => {
    if (partner) {
      setFormState({
        label: partner.label,
        field_email: partner.field_email,
        field_social_facebook: partner.field_social_facebook,
        field_social_instagram: partner.field_social_instagram,
        field_social_twitter: partner.field_social_twitter,
        field_social_linkedin: partner.field_social_linkedin,
        field_description: partner.field_description,
      });
    }
  }, [partner]);

  const doSubmit = (e) => {
    e?.preventDefault();

    const data = {
      ...formState,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_logo",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData({ ...data, id });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={status === "updated"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.canGoBack() && router.goBack()}
        message="Profilen har uppdaterats."
      />
      <IonLoading isOpen={isLoading} />

      <PageWithCollapsableHeader
        title="Företagsinfo"
        mode="back"
        defaultHref="/u/profile"
      >
        <PartnerContainer>
          <PartnerText modifiers="partner-text--mini partner-text--margin-bottom partner-text--center">
            Som admin kan du redigera företagets generella info
          </PartnerText>

          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            modifiers="partner-media-with-button--company"
            img={image?.webPath ?? partner?.field_logo?.image_style_uri.medium}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.label}
              onChange={(e) =>
                setFormState({ ...formState, label: e.target.value })
              }
              label="Företagsnamn"
            />
            <PartnerInput
              value={formState.field_email}
              onChange={(e) =>
                setFormState({ ...formState, field_email: e.target.value })
              }
              label="E-post"
            />
            <PartnerInput
              value={formState.field_social_facebook}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_facebook: e.target.value,
                })
              }
              label="Facebook"
            />
            <PartnerInput
              value={formState.field_social_instagram}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_instagram: e.target.value,
                })
              }
              label="Instagram"
            />
            <PartnerInput
              value={formState.field_social_twitter}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_twitter: e.target.value,
                })
              }
              label="Twitter"
            />
            <PartnerInput
              value={formState.field_social_linkedin}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_linkedin: e.target.value,
                })
              }
              label="Linkedin"
            />
            <PartnerInput
              value={formState.field_description}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_description: e.target.value,
                })
              }
              label="Företagsbeskrivning"
              textarea={true}
            />
          </form>
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton
            text="Spara"
            expand="block"
            onClick={doSubmit}
          ></PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id={providerId} send-tags="membership">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group/partner/${id}?include=field_logo`}
          type="group--partner"
          attributes="label,field_email,field_social_facebook,field_social_instagram,field_social_twitter,field_social_linkedin,field_description"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default PartnerSettings;
