import { useMemo } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useTermCreateProvider(vocab) {
  const providerId = `term-create-provider-${vocab}`;

  const { data, isLoading, setData } = useZeusDataProvider(providerId, null);

  const Provider = useMemo(() => {
    const Component = () => {
      const url = `${getSetting("BACKEND")}/jsonapi/taxonomy_term/${vocab}`;
      return (
        <zeus-data-provider
          id={providerId}
          send-tags={`taxonomy_term-${vocab}`}
          write-only
        >
          <zeus-data-source-jsonapi
            url={url}
            data-action="create"
            attributes="name"
            type={`taxonomy_term--${vocab}`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [providerId, vocab]);

  return {
    setData,
    providerId,
    Provider,
    partner: data,
    isLoading,
  };
}
