import { useMemo } from "react";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function usePushCreateProvider(type) {
  const providerId = `message-create-provider-${type}`;
  const bundle = `push_message__${type}`;
  const attributes =
    "field_heading,field_message,field_app," +
    (type === "event" ? "field_related_group" : "field_related_content");
  const relationships =
    "field_app:group--app," +
    (type === "event"
      ? "field_related_group:group--event"
      : "field_related_content:node--news");
  const { isLoading, setData } = useZeusDataProvider(providerId, null);

  const Provider = useMemo(() => {
    const Component = ({ onCreated }) => {
      useZeusDataStatusSubscriber(providerId, (status) => {
        if (status === "created") {
          onCreated?.();
        }
      });
      const url = `${getSetting("BACKEND")}/jsonapi/message/${bundle}`;
      return (
        <zeus-data-provider
          id={providerId}
          send-tags={`message-${bundle}`}
          write-only
        >
          <zeus-data-source-jsonapi
            url={url}
            data-action="create"
            attributes={attributes}
            relationships={relationships}
            type={`message--${bundle}`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [attributes, bundle, providerId, relationships]);

  return {
    setData,
    providerId,
    Provider,
    isLoading,
  };
}
