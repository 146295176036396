import { useState } from "react";
import { useParams } from "react-router";
import {
  IonLoading,
  IonPage,
  IonAlert,
  useIonRouter,
  IonItem,
  IonToggle,
  IonLabel,
} from "@ionic/react";
import {
  PartnerContainer,
  PageWithCollapsableHeader,
  PartnerInput,
  PartnerButton,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../../settings";
import { validateEmail } from "../../helpers";

export const AdminPartnerInvite = () => {
  const { id } = useParams();
  const router = useIonRouter();
  const { setData, isLoading, status, errorStatus, errorData } =
    useZeusDataProvider("invite-provider");
  const [validationError, setValidationError] = useState("");
  const [formState, setFormState] = useState({
    partner_id: id,
    name: "",
    email: "",
    title: "",
  });
  const [admin, setAdmin] = useState(false);

  const doSendInvite = () => {
    if (!validateEmail(formState.email)) {
      setValidationError("Du måste ange en giltig e-postadress.");
      return;
    }

    if (!formState.name) {
      setValidationError('Du måste fylla i fältet "Namn".');
      return;
    }

    setData({ ...formState, admin });
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={errorStatus}
        buttons={["Ok"]}
        header={getSetting("APP_NAME")}
        message={errorData?.data}
      />
      <IonAlert
        isOpen={status === "updated"}
        buttons={["Ok"]}
        header={getSetting("APP_NAME")}
        message="En inbjudan har skickats via e-post."
        onDidDismiss={() => {
          router.canGoBack() && router.goBack();
        }}
      />
      <IonAlert
        isOpen={validationError !== ""}
        header={getSetting("APP_NAME")}
        message={validationError}
        onDidDismiss={() => setValidationError("")}
        buttons={["Ok"]}
      />
      <PageWithCollapsableHeader
        title="Skapa medlem"
        mode="back"
        defaultHref={`/u/admin/network/${id}`}
      >
        <PartnerContainer>
          <PartnerInput
            value={formState.name}
            onChange={(e) =>
              setFormState({ ...formState, name: e.target.value })
            }
            label="Namn"
          />
          <PartnerInput
            type="email"
            value={formState.email}
            onChange={(e) =>
              setFormState({ ...formState, email: e.target.value })
            }
            label="E-post"
          />
          <PartnerInput
            value={formState.title}
            onChange={(e) =>
              setFormState({ ...formState, title: e.target.value })
            }
            label="Position/Titel"
          />
          <IonItem lines="none">
            <IonLabel>Gör till administratör</IonLabel>
            <IonToggle
              checked={admin}
              onIonChange={(e) => setAdmin(e.detail.checked)}
            />
          </IonItem>
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton
            expand={true}
            onClick={doSendInvite}
            icon="icons/send.svg"
          >
            Skicka
          </PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id="invite-provider"
        write-only
        send-tags="partner-members"
      >
        <zeus-data-source-json
          data-action="create"
          url={`${getSetting("BACKEND")}/api/admin-user-add`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminPartnerInvite;
