import { PartnerHeading } from "@understandit/partnerplattformen-storybook-30876108";

const ArticleHeading = ({ children }) => (
  <PartnerHeading
    text={children}
    modifiers="partner-heading--start partner-heading--margin-top partner-heading--margin-bottom partner-heading--xl"
  />
);

export default ArticleHeading;
