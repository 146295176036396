import { IonLoading, IonPage, useIonRouter } from "@ionic/react";
import { useParams } from "react-router";
import {
  PageWithCollapsableHeader,
  PartnerContainer,
  PartnerText,
} from "@understandit/partnerplattformen-storybook-30876108";
import {
  useZeusDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import { useEventProvider } from "../providers/event-provider";
import Survey from "../survey/Survey";

const EventEditSubmission = () => {
  const { id, sid } = useParams();
  const { event, Provider } = useEventProvider(id);
  const {
    data: eventMembership,
    setData,
    isLoading,
  } = useZeusDataProvider(`event-membership-${sid}`);
  const router = useIonRouter();

  useZeusDataStatusSubscriber(`event-membership-${sid}`, (status) => {
    if (status === "updated") {
      router.push(`/u/event/${id}/submission`, "back");
    }
  });

  const survey = event?.field_survey ? JSON.parse(event.field_survey) : null;
  const surveyResults = eventMembership?.field_survey_results
    ? JSON.parse(eventMembership.field_survey_results)
    : null;

  const doSave = (data) => {
    setData({
      id: sid,
      field_survey_results: JSON.stringify(data),
    });
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <PageWithCollapsableHeader
        title="Redigera anmälan"
        mode="back"
        defaultHref={`/u/event/${id}/submission`}
      >
        <PartnerContainer>
          <PartnerText modifiers="partner-text--margin-bottom partner-text--weight-large">
            Dina uppgifter
          </PartnerText>

          {survey !== null && surveyResults !== null && (
            <Survey survey={survey} init={surveyResults} onSubmit={doSave} />
          )}
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id={`event-membership-${sid}`}
        send-tags={`event-membership-${id}`}
      >
        <zeus-data-source-jsonapi
          type="group_content--event-group_membership"
          attributes="field_survey_results"
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/event-group_membership/${sid}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
      <Provider />
    </IonPage>
  );
};

export default EventEditSubmission;
