import { useIonRouter, IonList, IonPage } from "@ionic/react";
import { useParams } from "react-router";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerTextWithBadge,
  PartnerButton,
  PartnerCard,
  PageWithCollapsableHeader,
  PartnerSkeletonCard,
} from "@understandit/partnerplattformen-storybook-30876108";
import dayjs from "dayjs";

import { stripTags } from "../helpers";
import { getSetting } from "../settings";

const ProfileRequest = () => {
  const { id } = useParams();
  const providerId = `my-requests-${id}`;
  const [myRequests, , , isLoading] = useDynamicDataProvider(providerId, []);
  const [myPartner] = useDynamicDataProvider("my-partner");
  const router = useIonRouter();

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Mina förfrågningar"
        mode="back"
        defaultHref="/u/profile"
      >
        <PartnerContainer>
          <PartnerTextWithBadge
            modifiers="partner-text-with-badge--margin-bottom"
            text="Förfrågningar kvar att skapa"
            badge={myPartner?.field_quota_request ?? 0}
          />
          <PartnerButton
            text="Skapa förfrågning"
            icon="icons/plus16.svg"
            expand="block"
            onClick={() =>
              (myPartner?.field_quota_request ?? 0) > 0 &&
              router.push("/u/request/create")
            }
            disabled={(myPartner?.field_quota_request ?? 0) < 1}
          />
        </PartnerContainer>

        <PartnerContainer>
          <IonList className="app-list">
            {isLoading && myRequests.length === 0 && (
              <PartnerSkeletonCard
                size="small"
                modifiers={"partner-card--small-and-long"}
              />
            )}
            {myRequests.map((item) => (
              <PartnerCard
                size="small"
                inactive={!item.status}
                showContextButton={!item.status}
                onContextButtonClick={(e) => {
                  e.stopPropagation();
                  router.push(`/u/admin/offer/${item.id}/edit`);
                }}
                modifiers="partner-card--small-and-long line-clamp-3"
                onClick={() => router.push(`/u/request/${item.id}`)}
                key={item.id}
                title={item.title}
                date={dayjs(item.created).format("D MMM, YYYY")}
                imageSrc={
                  item.field_image?.image_style_uri.large ??
                  getSetting("DEFAULT_IMAGE")
                }
                badgeSrc={
                  item.entitygroupfield[0]?.gid?.field_logo?.image_style_uri
                    .large
                }
              >
                {stripTags(item.field_preamble ?? item.body?.processed)}
              </PartnerCard>
            ))}
          </IonList>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id={providerId} listen-tags="request">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/request?filter[uid.id]=${id}&include=field_image&sort=-created`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default ProfileRequest;
