import { PartnerInput } from "@understandit/partnerplattformen-storybook-30876108";

/*

component 

{
  "version": "1.0",
  "components": [
    {
      "name": "id-123123",
      "title": "Har du några allergier",
      "type": "text",
      "isRequired": true
    },
    {
      "name": "id-321321",
      "title": "Vad heter din hund",
      "type": "text",
      "description": "Här kan du skriva vad din hund heter"
    }
  ],
  "guests": 1
}

*/

const SurveyComponent = ({ component, value, onChange }) => {
  return (
    <PartnerInput
      value={value}
      onChange={onChange}
      label={`${component.title}${component.isRequired ? "*" : ""}`}
      helpText={component.description}
    />
  );
};

export default SurveyComponent;
