import { useState } from "react";
import { Link } from "react-router-dom";
import { IonModal, IonPage, useIonRouter, isPlatform } from "@ionic/react";
import {
  PartnerButton,
  PartnerContainer,
  PartnerText,
  PartnerPersonCounter,
  PageWithHideableHeader,
  PartnerPopupWithOverlay,
  PartnerEventInfo,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import { useParams } from "react-router-dom";
import { AppLauncher } from "@capacitor/app-launcher";

import { getSetting } from "../settings";
import { isSuperAdmin, formatEmailUrl, formatUrl } from "../helpers";
import { addToSystemCalendar, useActionSheet } from "../hooks";
import Raw from "../components/Raw";
import ArticleHeading from "../components/ArticleHeading";
import DateRange from "../components/DateRange";
import EventMembersDownload from "./EventMembersDownload";
import InAppLink from "../components/InAppLink";

function isVisible(event, membership) {
  const eventPartnerAccess =
    event?.field_event_access_partner?.map((i) => i.id) ?? [];
  const eventPartnerTypeAccess =
    event?.field_event_access_term?.map((i) => i.id) ?? [];

  const membershipPartnerType = membership?.gid?.field_partner_type?.id;
  const membershipPartner = membership?.gid?.id;

  // If no access is set on event, it is visible for all members.
  if (eventPartnerAccess.length === 0 && eventPartnerTypeAccess.length === 0) {
    return true;
  }

  // Highest priority: Specific partners.
  if (eventPartnerAccess.includes(membershipPartner)) {
    return true;
  }

  // Second priority: Partner type.
  if (eventPartnerTypeAccess.includes(membershipPartnerType)) {
    return true;
  }

  return false;
}

export const EventDetail = () => {
  const router = useIonRouter();
  const { id } = useParams();
  const providerId = `event-item-${id}`;
  const [event] = useDynamicDataProvider(providerId, null);
  const [eventMembership, , , isLoading] = useDynamicDataProvider(
    `event-membership-${id}`,
    []
  );
  const [eventMembershipList] = useDynamicDataProvider(
    `event-membership-list-${id}`,
    []
  );
  const [profile] = useDynamicDataProvider("profile");
  const [membership] = useDynamicDataProvider("partner-group-membership");
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const showActionSheet = useActionSheet();

  const isHidden = !isVisible(event, membership);

  const doAddToCalendar = async () => {
    try {
      await addToSystemCalendar(
        event?.label,
        event?.field_location,
        event?.field_preamble,
        event?.field_date?.value,
        event?.field_date?.end_value
      );
    } catch (e) {
      console.warn(e);
    }
  };

  const doOpenMaps = async () => {
    if (!event?.field_location) {
      return;
    }

    const location = encodeURIComponent(event?.field_location);
    const googleUrl = `https://www.google.com/maps/search/?api=1&query=${location}`;

    if (isPlatform("android")) {
      AppLauncher.openUrl({ url: googleUrl });
      return;
    }

    const action = await showActionSheet({
      buttons: [
        { text: "Öppna i Google Maps", data: "google" },
        { text: "Öppna i Apple Maps", data: "apple" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });

    if (action === "google") {
      AppLauncher.openUrl({ url: googleUrl });
    }

    if (action === "apple") {
      const appleUrl = `https://maps.apple.com/?q=${location}`;
      AppLauncher.openUrl({ url: appleUrl });
    }
  };

  return (
    <IonPage>
      <IonModal
        isOpen={downloadModalOpen}
        onDidDismiss={() => setDownloadModalOpen(false)}
      >
        <EventMembersDownload
          id={id}
          event={event}
          close={() => setDownloadModalOpen(false)}
        />
      </IonModal>
      <PageWithHideableHeader
        title={event?.label}
        defaultHref="/u/event"
        headerImage={
          event?.field_image?.image_style_uri.wide ??
          getSetting("DEFAULT_IMAGE")
        }
      >
        <PartnerContainer modifiers="partner-container--rounded-top">
          <ArticleHeading>{event?.label}</ArticleHeading>

          <PartnerEventInfo
            dateTitle="Datum"
            dateInfo={
              <div>
                <DateRange
                  from={event?.field_date?.value}
                  to={event?.field_date?.end_value}
                />
              </div>
            }
            dateIcon="icons/clock16.svg"
            locationTitle="Plats"
            locationInfo={event?.field_location}
            locationIcon="icons/map16.svg"
            showLocationArrow={true}
            onLocationClick={doOpenMaps}
            modifiers="partner-event-info--margin-bottom"
          />

          {eventMembershipList.length > 0 && (
            <Link className="no-underline" to={`/u/event/${id}/participants`}>
              <PartnerText modifiers="partner-text--mini partner-text--small-margin-bottom partner-text--center">
                Deltagare
              </PartnerText>
              <PartnerPersonCounter
                modifiers="partner-person-counter--center"
                persons={new Array(
                  Math.min(eventMembershipList.length, 10)
                ).fill(null)}
                counter={
                  eventMembershipList.length > 40
                    ? "40+"
                    : `${eventMembershipList.length}`
                }
              />
            </Link>
          )}

          {event?.field_link && (
            <PartnerText>
              <InAppLink href={formatUrl(event.field_link)}>
                {event.field_link}
              </InAppLink>
            </PartnerText>
          )}

          {event?.field_contact_email && (
            <PartnerText>
              <a href={formatEmailUrl(event.field_contact_email)}>
                {event.field_contact_email}
              </a>
            </PartnerText>
          )}

          {event?.field_preamble && (
            <PartnerText modifiers="partner-text--preamble">
              <p>{event?.field_preamble}</p>
            </PartnerText>
          )}

          <PartnerText modifiers="partner-text--margin-top partner-text--margin-bottom">
            <Raw html={event?.field_description} />
          </PartnerText>

          {profile?.id && !isLoading && eventMembership.length === 0 && (
            <PartnerButton
              icon="icons/calendar24.svg"
              text="Anmäl dig till eventet"
              expand={true}
              routerLink={`/u/event/${id}/signup`}
            />
          )}

          {!isLoading && eventMembership.length !== 0 && (
            <>
              <PartnerButton
                text="Se din bokning"
                expand={true}
                routerLink={`/u/event/${id}/submission`}
                modifiers="partner-button--secondary"
              />
              <PartnerButton
                expand="expand"
                onClick={doAddToCalendar}
                icon="icons/calendar24.svg"
                modifiers="partner-button--margin-top"
              >
                Lägg till i kalender
              </PartnerButton>
            </>
          )}

          {isSuperAdmin(profile) && (
            <PartnerButton
              text="Ladda ner deltagarlista"
              expand
              onClick={() => setDownloadModalOpen(true)}
            />
          )}
        </PartnerContainer>
        {isHidden && (
          <PartnerPopupWithOverlay
            title="Låst innehåll!"
            text="Din partnernivå har inte möjlighet att ta del av detta innehåll."
            button="Tillbaka"
            onButtonClick={() => router.canGoBack() && router.goBack()}
          />
        )}
      </PageWithHideableHeader>
      <zeus-data-provider id={providerId} listen-tags="event">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group/event/${id}?include=field_image,field_event_access_term,field_event_access_partner`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider
        id={`event-membership-list-${id}`}
        listen-tags="event"
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/event-group_membership?filter[gid.id]=${id}&sort=-created`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      {profile?.id && (
        <zeus-data-provider id={`event-membership-${id}`} listen-tags="event">
          <zeus-data-source-jsonapi
            url={`${getSetting(
              "BACKEND"
            )}/jsonapi/group_content/event-group_membership?filter[gid.id]=${id}&filter[entity_id.id]=${
              profile.id
            }`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      )}
    </IonPage>
  );
};

export default EventDetail;
