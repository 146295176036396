import { useParams } from "react-router";
import { IonPage, IonList, useIonRouter } from "@ionic/react";
import {
  PageWithCollapsableHeader,
  PartnerContainer,
  PartnerButton,
  PartnerSkeletonItemPerson,
  PartnerItemPerson,
  PartnerLogo,
  PartnerHeading,
  PartnerText,
  PartnerItem,
} from "@understandit/partnerplattformen-storybook-30876108";

import {
  useConfirmDangerousAction,
  useActionModal,
  useActionSheet,
} from "../../hooks";
import { usePartnerProvider } from "../../providers/partner-provider";
import { usePartnerMembersProvider } from "../../providers/partner-members-provider";
import { formatDateTime, isPartnerAdmin, preventAndStop } from "../../helpers";

import SocialMediaIcons from "../../components/SocialMediaIcons";
import dayjs from "dayjs";

const TitleElem = ({ member }) => {
  let ts = dayjs(member.entity_id?.access).unix();

  const access =
    ts === 0
      ? "Inte loggat in"
      : `Senast inloggad: ${formatDateTime(member.entity_id?.access)}`;

  return `${member.field_job_title} | ${access}`;
};

const MemberVisibility = ({ member }) => {
  return (
    <>
      {isPartnerAdmin(member) && (
        <div>
          <ion-icon src="icons/settings12.svg"></ion-icon>
        </div>
      )}
      {member.field_hide_contact_details && (
        <div>
          <ion-icon src="icons/inactive16.svg"></ion-icon>
        </div>
      )}
    </>
  );
};

const AdminPartnerView = () => {
  const { id } = useParams();
  const { Provider, partner } = usePartnerProvider(id);
  const {
    Provider: MembersProvider,
    members,
    isLoading: membersIsLoading,
  } = usePartnerMembersProvider(id);
  const showActionSheet = useActionSheet();
  const confirmDangerousAction = useConfirmDangerousAction();
  const doAction = useActionModal();
  const router = useIonRouter();

  const doOpenAdminMenu = async (member) => {
    const toggleAdminText = isPartnerAdmin(member)
      ? "Ta bort som administratör"
      : "Gör till administratör";
    const toggleVisibleText = member.field_hide_contact_details
      ? "Visa kontaktuppgifter"
      : "Dölj kontaktuppgifter";
    const action = await showActionSheet({
      buttons: [
        { text: toggleAdminText, data: "toggle-admin" },
        { text: toggleVisibleText, data: "toggle-vis" },
        { text: "Redigera", data: "edit" },
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });

    if (
      action === "delete" &&
      (await confirmDangerousAction(
        "Är du säker på att du vill radera användaren?"
      ))
    ) {
      doAction("admin-delete-membership", {
        partnerId: partner.id,
        memberId: member.id,
      });
    } else if (action === "toggle-admin") {
      doAction("set-partner-admin", {
        partnerId: partner.id,
        memberId: member.id,
        value: !isPartnerAdmin(member),
      });
    } else if (action === "toggle-vis") {
      const field_hide_contact_details = !member.field_hide_contact_details;
      doAction("update", {
        type: "group_content",
        bundle: "partner-group_membership",
        id: member.id,
        attributes: { field_hide_contact_details },
        extraTags: `partner-members-${partner.id}`,
      });
    } else if (action === "edit") {
      router.push(`/u/admin/network/${partner.id}/member/${member.id}/edit`);
    }
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Hantera partner"
        mode="back"
        defaultHref="/u/admin/network"
      >
        <PartnerContainer>
          <PartnerLogo
            modifiers="partner-logo--center"
            logo={partner?.field_logo?.image_style_uri.large}
          />
          <PartnerHeading
            modifiers="partner-heading--margin-top"
            text={partner?.label}
          />
          {(partner?.field_email || partner?.field_partner_type?.name) && (
            <PartnerText modifiers="partner-text--center partner-text--small-margin-top partner-text--info-caption">
              {partner?.field_email && <span>{partner?.field_email}</span>}
              {partner?.field_partner_type?.name && (
                <span>{partner?.field_partner_type?.name}</span>
              )}
            </PartnerText>
          )}
          {partner?.field_description && (
            <PartnerText modifiers="partner-text--margin-top partner-text--margin-bottom">
              {partner?.field_description}
            </PartnerText>
          )}
        </PartnerContainer>
        <PartnerItem
          modifiers="partner-item--network partner-item--border-top partner-item--border-bottom"
          label={`Erbjudanden: ${
            partner?.field_quota_offer ?? 0
          } | Förfrågningar: ${partner?.field_quota_request ?? 0}`}
        />
        <PartnerContainer>
          <PartnerButton
            expand={true}
            routerLink={`/u/admin/network/${partner?.id}/invite`}
          >
            Skapa medlem
          </PartnerButton>
          <PartnerButton
            modifiers="partner-button--secondary"
            expand
            routerLink={`/u/admin/network/${partner?.id}/edit`}
            icon="icons/edit-icon16.svg"
          >
            Redigera partner
          </PartnerButton>
        </PartnerContainer>
        <PartnerContainer>
          <PartnerHeading
            text="Medlemmar"
            modifiers="partner-heading--start partner-heading--margin-bottom-large partner-heading--margin-top"
          />
          <IonList className="app-list">
            {membersIsLoading && members.length === 0 && (
              <>
                <PartnerSkeletonItemPerson />
                <PartnerSkeletonItemPerson />
                <PartnerSkeletonItemPerson />
              </>
            )}
            {members.map((item) => (
              <PartnerItemPerson
                key={item.id}
                name={item.field_name ?? item.entity_id.mail}
                img={item?.field_avatar?.image_style_uri.medium}
                title={<TitleElem member={item} />}
                routerLink={`/u/admin/network/${partner?.id}/member/${item.id}`}
                detail={false}
                visibility={<MemberVisibility member={item} />}
                admin={true}
                onAdminClick={preventAndStop(() => doOpenAdminMenu(item))}
                inactive={item.field_hide_contact_details}
              />
            ))}
          </IonList>
        </PartnerContainer>
        <PartnerContainer>
          <SocialMediaIcons membership={partner} />
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <Provider />
      <MembersProvider />
    </IonPage>
  );
};

export default AdminPartnerView;
