import { useEffect, useState } from "react";
import {
  IonLoading,
  IonPage,
  useIonRouter,
  IonAlert,
  IonLabel,
  IonItem,
  IonToggle,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import {
  PartnerContainer,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../../settings";
import { useCamera } from "../../hooks";
import { makeid, preventDefault } from "../../helpers";

export const AdminMemberEdit = () => {
  const router = useIonRouter();
  const { partner_id, id } = useParams();
  const providerId = `edit-member-${id}`;
  const {
    data: member,
    setData,
    isLoading,
    status,
  } = useZeusDataProvider(providerId);
  const { getPhoto } = useCamera();
  const [image, setImage] = useState(null);
  const [checked, setChecked] = useState(true);
  const [formState, setFormState] = useState({
    field_name: "",
    field_job_title: "",
    field_telephone: "",
    field_city: "",
    field_social_facebook: "",
    field_social_instagram: "",
    field_social_twitter: "",
    field_social_linkedin: "",
    field_description: "",
  });

  useEffect(() => {
    if (member) {
      setFormState({
        field_name: member.field_name,
        field_job_title: member.field_job_title,
        field_telephone: member.field_telephone,
        field_city: member.field_city,
        field_social_facebook: member.field_social_facebook,
        field_social_instagram: member.field_social_instagram,
        field_social_twitter: member.field_social_twitter,
        field_social_linkedin: member.field_social_linkedin,
        field_description: member.field_description,
      });
      setChecked(!Boolean(member.field_hide_contact_details));
    }
  }, [member]);

  const doSubmit = () => {
    const data = {
      id,
      ...formState,
      field_hide_contact_details: !checked,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_avatar",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData({ ...data, id });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={status === "updated"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.canGoBack() && router.goBack()}
        message="Profilen har uppdaterats."
      />
      <IonLoading isOpen={isLoading} />

      <PageWithCollapsableHeader
        title="Redigera medlem"
        mode="back"
        defaultHref={`/u/admin/network/${partner_id}`}
      >
        <PartnerContainer>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            modifiers="partner-media-with-button--profile"
            img={image?.webPath ?? member?.field_avatar?.image_style_uri.medium}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={preventDefault(doSubmit)}>
            <PartnerInput
              value={formState.field_name}
              onChange={updateFormState("field_name")}
              label="Namn"
            />
            <PartnerInput
              value={formState.field_job_title}
              onChange={updateFormState("field_job_title")}
              label="Position/Titel"
            />
            <PartnerInput
              value={formState.field_telephone}
              onChange={updateFormState("field_telephone")}
              label="Telefon"
            />
            <PartnerInput
              value={formState.field_city}
              onChange={updateFormState("field_city")}
              label="Ort"
            />
            <PartnerInput
              value={formState.field_social_facebook}
              onChange={updateFormState("field_social_facebook")}
              label="Facebook"
            />
            <PartnerInput
              value={formState.field_social_instagram}
              onChange={updateFormState("field_social_instagram")}
              label="Instagram"
            />
            <PartnerInput
              value={formState.field_social_twitter}
              onChange={updateFormState("field_social_twitter")}
              label="Twitter"
            />
            <PartnerInput
              value={formState.field_social_linkedin}
              onChange={updateFormState("field_social_linkedin")}
              label="Linkedin"
            />
            <PartnerInput
              value={formState.field_description}
              onChange={updateFormState("field_description")}
              label="Beskrivning av dig"
              textarea={true}
            />

            <IonItem lines="none">
              <IonLabel>Visa mina kontaktuppgifter</IonLabel>
              <IonToggle
                checked={checked}
                onIonChange={(e) => setChecked(e.detail.checked)}
              />
            </IonItem>
          </form>
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton
            text="Spara"
            expand="block"
            onClick={doSubmit}
          ></PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id={providerId}
        send-tags={`group_content-partner-group_membership-${id},partner-members-${partner_id}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/partner-group_membership/${id}?include=field_avatar`}
          type="group_content--partner-group_membership"
          attributes="field_name,field_job_title,field_telephone,field_city,field_social_facebook,field_social_instagram,field_social_twitter,field_social_linkedin,field_description"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminMemberEdit;
