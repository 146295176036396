import { PartnerSelect } from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";
import { usePartnerOptions } from "../hooks";

const PartnerSelectField = ({ onChange, value }) => {
  const partnerOptions = usePartnerOptions();
  const interfaceOptions = {
    message: getSetting("HELP_TEXT_PARTNER"),
  };

  return (
    <PartnerSelect
      icon="icons/chevron-down16.svg"
      label="Partner"
      placeholder="Välj"
      interface="alert"
      interfaceOptions={interfaceOptions}
      options={partnerOptions}
      value={value}
      onChange={onChange}
    />
  );
};
export default PartnerSelectField;
