import { useEffect, useState } from "react";
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonIcon,
  useIonRouter,
} from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerMedia,
  PartnerContainer,
  PartnerHeader,
} from "@understandit/partnerplattformen-storybook-30876108";
import { App } from "@capacitor/app";

import { isSuperAdmin } from "./helpers";
import { getSetting } from "./settings";
import { useAuthentication } from "./auth-context";

export const Menu = ({ contentId, disabled = false }) => {
  const [versionString, setVersionString] = useState(
    "build: 2315123 (2023.15)"
  );
  const menuItems = [
    {
      icon: "icons/dashboard24.svg",
      label: getSetting("DASHBOARD_TITLE"),
      route: `/u/dashboard`,
    },
    { icon: "icons/news24.svg", label: "Nyheter", route: `/u/news` },
    { icon: "icons/conversation24.svg", label: "Event", route: `/u/event` },
    { icon: "icons/offer24.svg", label: "Erbjudanden", route: `/u/offer` },
    {
      icon: "icons/question24.svg",
      label: "Förfrågningar",
      route: `/u/request`,
    },
    { icon: "icons/network24.svg", label: "Nätverket", route: `/u/network` },
  ];

  const [myPartner] = useDynamicDataProvider("my-partner", []);
  const [membership, , , membershipIsLoading] = useDynamicDataProvider(
    "partner-group-membership",
    null
  );
  const [profile] = useDynamicDataProvider("profile", null);

  const router = useIonRouter();

  useEffect(() => {
    App.getInfo()
      .then((info) => {
        if (info.id === "se.partnerplattformen.dev") {
          setVersionString(`${info.build} - ${info.version}`);
        }
      })
      .catch(() => {});
  }, []);

  return (
    <IonMenu
      contentId={contentId}
      type="overlay"
      swipeGesture={false}
      disabled={disabled}
    >
      <IonContent className="ion-content--menu">
        <PartnerMedia
          img={getSetting("LOGO")}
          modifiers="partner-media--menu-logo"
        />
        <PartnerContainer modifiers="partner-container--menu">
          <IonMenuToggle autoHide={false}>
            <PartnerHeader
              isLoading={membership === null || membershipIsLoading}
              onClick={() => router.push(`/u/profile`, "root")}
              headerBackground={getSetting("PROFILE_BG")}
              media={membership?.field_avatar?.image_style_uri.medium}
              logo={myPartner?.field_logo?.image_style_uri.medium}
              name={membership?.field_name ?? membership?.label}
              company={
                <>
                  {myPartner?.label && <span>{myPartner.label}</span>}
                  {membership?.field_job_title && (
                    <span>{membership.field_job_title}</span>
                  )}
                </>
              }
            />
          </IonMenuToggle>
          <IonList className="menu-list">
            {menuItems.map((item) => (
              <IonMenuToggle autoHide={false} key={item.route}>
                <IonItem
                  lines="full"
                  className="partner-item partner-item--menu-dashboard"
                  routerLink={item.route}
                  routerDirection="root"
                  detail="false"
                >
                  <div className="partner-item__container">
                    <div className="partner-item__container-start">
                      <IonIcon className="partner-item__icon" src={item.icon} />
                      <p className="partner-item__label">{item.label}</p>
                    </div>
                    <div className="partner-item__container-end">
                      <IonIcon
                        className="partner-item__iconEnd"
                        src="icons/chevron-right24.svg"
                      />
                    </div>
                  </div>
                </IonItem>
              </IonMenuToggle>
            ))}

            {isSuperAdmin(profile) && (
              <>
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    lines="full"
                    className="partner-item partner-item--menu-dashboard"
                    routerLink={`/u/admin/content`}
                    routerDirection="root"
                    detail="false"
                  >
                    <div className="partner-item__container">
                      <div className="partner-item__container-start">
                        <IonIcon
                          className="partner-item__icon"
                          src="icons/edit-page24.svg"
                        />
                        <p className="partner-item__label">Hantera innehåll</p>
                      </div>
                      <div className="partner-item__container-end">
                        <IonIcon
                          className="partner-item__iconEnd"
                          src="icons/chevron-right24.svg"
                        />
                      </div>
                    </div>
                  </IonItem>
                </IonMenuToggle>
                <IonMenuToggle autoHide={false}>
                  <IonItem
                    lines="full"
                    className="partner-item partner-item--menu-dashboard"
                    routerLink={`/u/admin/network`}
                    routerDirection="root"
                    detail="false"
                  >
                    <div className="partner-item__container">
                      <div className="partner-item__container-start">
                        <IonIcon
                          className="partner-item__icon"
                          src="icons/users24.svg"
                        />
                        <p className="partner-item__label">Hantera nätverket</p>
                      </div>
                      <div className="partner-item__container-end">
                        <IonIcon
                          className="partner-item__iconEnd"
                          src="icons/chevron-right24.svg"
                        />
                      </div>
                    </div>
                  </IonItem>
                </IonMenuToggle>
              </>
            )}
          </IonList>
        </PartnerContainer>
        {versionString && (
          <div style={{ fontSize: "12px", textAlign: "center" }}>
            {versionString}
          </div>
        )}
      </IonContent>
    </IonMenu>
  );
};

export const AuthMenu = () => {
  const { isAuthenticated } = useAuthentication();
  return <Menu contentId="main" disabled={!isAuthenticated} />;
};

export default AuthMenu;
