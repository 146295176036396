import { useCallback, useState, useEffect } from "react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  useIonActionSheet,
  isPlatform,
  useIonModal,
  useIonAlert,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "./settings";

import GroupActionModal from "./components/GroupActionModal";
import ActionModal from "./components/ActionModal";

export function useCamera() {
  const [presentSheet] = useIonActionSheet();
  const getPhoto = async () => {
    if (!isPlatform("capacitor")) {
      return Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        webUseInput: true,
        source: CameraSource.Photos,
      });
    }

    return new Promise((resolve, reject) => {
      presentSheet({
        buttons: [
          { text: "Från bilder", data: CameraSource.Photos },
          { text: "Ta foto", data: CameraSource.Camera },
          { text: "Avbryt", role: "cancel", data: "cancel" },
        ],
        onDidDismiss: (event) => {
          if (
            [CameraSource.Photos, CameraSource.Camera].includes(
              event.detail.data
            )
          ) {
            Camera.getPhoto({
              quality: 90,
              allowEditing: true,
              resultType: CameraResultType.Uri,
              webUseInput: true,
              source: event.detail.data,
            }).then(resolve, reject);
          } else {
            reject("canceled");
          }
        },
      });
    });
  };

  return { getPhoto };
}

export function useEventActions(id, callback) {
  const [actionState, setActionState] = useState({
    action: "",
    groupType: "event",
    id: id,
    subjectId: null,
    data: null,
  });

  const doCloseModal = () => {
    dismissModal();
    callback?.();
  };

  const [presentModal, dismissModal] = useIonModal(GroupActionModal, {
    ...actionState,
    onDone: doCloseModal,
  });

  const joinEvent = useCallback(
    (subjectId, field_partner_membership, field_survey_results) => {
      setActionState((current) => {
        return {
          ...current,
          action: "join",
          subjectId,
          data: { field_survey_results, field_partner_membership },
        };
      });
      presentModal({ backdropDismiss: false, cssClass: "action-modal" });
    },
    [presentModal]
  );

  const cancelEvent = useCallback(
    (subjectId) => {
      setActionState((current) => {
        return {
          ...current,
          action: "leave",
          subjectId,
          data: null,
        };
      });

      presentModal({ backdropDismiss: false, cssClass: "action-modal" });
    },
    [presentModal]
  );

  return {
    joinEvent,
    cancelEvent,
  };
}

export function useConfirmDangerousAction() {
  const [presentAlert] = useIonAlert();

  const showAlert = useCallback(
    (message, confirm = "Radera", cancel = "Avbryt") => {
      return new Promise((resolve) => {
        presentAlert({
          header: getSetting("APP_NAME"),
          message,
          buttons: [
            { text: cancel, role: "cancel" },
            {
              text: confirm,
              role: "destructive",
            },
          ],
          onDidDismiss: (event) => {
            resolve(event.detail.role === "destructive");
          },
        });
      });
    },
    [presentAlert]
  );

  return showAlert;
}

export function useInputAlert() {
  const [presentAlert] = useIonAlert();

  const showAlert = useCallback(
    (message, value) => {
      return new Promise((resolve) => {
        presentAlert({
          header: getSetting("APP_NAME"),
          message,
          inputs: [
            {
              placeholder: "Namn",
              value,
            },
          ],
          buttons: [
            {
              text: "Avbryt",
              role: "cancel",
              handler: () => resolve(false),
            },
            {
              text: "Ok",
              handler: (values) => {
                const name = values?.[0];
                if (!name) {
                  return false;
                }
                resolve(name);
              },
            },
          ],
        });
      });
    },
    [presentAlert]
  );

  return showAlert;
}

export function useAlert() {
  const [presentAlert] = useIonAlert();

  const showAlert = useCallback(
    (message) => {
      return new Promise((resolve) => {
        presentAlert({
          header: getSetting("APP_NAME"),
          message,
          buttons: ["Ok"],
          onDidDismiss: resolve,
        });
      });
    },
    [presentAlert]
  );

  return showAlert;
}

export function useTaxonomy(taxonomyId) {
  const { data } = useZeusDataProvider(`taxonomy-${taxonomyId}`, []);
  return data;
}

export function useActionModal() {
  const [actionState, setActionState] = useState({
    action: "",
    args: {},
  });
  const doCloseModal = () => dismissModal();
  const [presentModal, dismissModal] = useIonModal(ActionModal, {
    ...actionState,
    onDone: doCloseModal,
  });

  const doAction = useCallback(
    (action, args) => {
      setActionState({ action, args });
      presentModal({ backdropDismiss: false, cssClass: "action-modal" });
    },
    [presentModal]
  );

  return doAction;
}

export function addToSystemCalendar(label, location, notes, from, to) {
  if (!window.plugins?.calendar) {
    return Promise.reject("Plugin not available");
  }

  return new Promise((resolve, reject) => {
    window.plugins.calendar.createEventInteractively(
      label,
      location,
      notes,
      new Date(from),
      new Date(to),
      resolve,
      reject
    );
  });
}

export function useActionSheet() {
  const [present] = useIonActionSheet();
  return (params) => {
    return new Promise((resolve) => {
      present({
        ...params,
        onDidDismiss: (e) => resolve(e.detail?.data),
      });
    });
  };
}

export function usePartnerTypeOptions() {
  const partnerTypes = useTaxonomy("partner_type");
  const partnerTypeOptions = partnerTypes
    .map((term) => ({
      label: term.name,
      value: term.id,
    }))
    .sort((a, b) =>
      a.label?.localeCompare(b.label, undefined, { sensitivity: "base" })
    );

  return partnerTypeOptions;
}

export function usePartnerOptions() {
  const { data: partnerList } = useZeusDataProvider("partner-list", []);
  const partnerOptions = partnerList
    .map((partner) => ({
      label: partner.label,
      value: partner.id,
    }))
    .sort((a, b) =>
      a.label?.localeCompare(b.label, undefined, { sensitivity: "base" })
    );
  return partnerOptions;
}

export function useDynamicDataProvider2(id, initial) {
  const [data, setStateData] = useState(initial);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState(null);
  const [status, setStatus] = useState("");

  const setData = useCallback(
    (args) => {
      document.dispatchEvent(
        new CustomEvent(`zeusDataData:${id}`, { detail: args })
      );
    },
    [id]
  );

  useEffect(() => {
    function handleDataAvailable(event) {
      setStateData(event.detail);
      setIsLoading(false);
      setError("");
      setErrorData(null);
    }

    function handleStatus(event) {
      if (event.detail.status === "error") {
        setErrorData(event.detail.data);
        setError(event.detail.data);
        setIsLoading(false);
      }

      if (event.detail.status === "loading") {
        setError("");
        setErrorData(null);
        setIsLoading(true);
      }

      if (event.detail.status === "nodata") {
        setError("");
        setErrorData(null);
        setIsLoading(false);
        setStateData(initial);
      }

      setStatus(event.detail.status);
    }

    function handleDataArrive() {
      // Notify my existance.
      console.log("here too");
      document.dispatchEvent(new Event(`zeusDataListener:${id}`));
    }

    document.addEventListener(`zeusDataAvailable:${id}`, handleDataAvailable);
    document.addEventListener(`zeusDataArrive:${id}`, handleDataArrive);
    document.addEventListener(`zeusDataStatus:${id}`, handleStatus);

    // Notify my existance. TODO: check when this is triggered.
    console.log("here");
    document.dispatchEvent(new Event(`zeusDataListener:${id}`));

    return () => {
      document.removeEventListener(
        `zeusDataAvailable:${id}`,
        handleDataAvailable
      );
      document.removeEventListener(`zeusDataArrive:${id}`, handleDataArrive);
      document.removeEventListener(`zeusDataStatus:${id}`, handleStatus);
    };
  }, [id, initial]);

  return { data, setData, isLoading, error, status, errorData };
}
