import { Browser } from "@capacitor/browser";

export const Raw = ({ html }) => {
  const capture = (e) => {
    if (e?.target?.href) {
      e?.preventDefault();
      Browser.open({ url: e?.target?.href });
    }
  };
  return <div onClick={capture} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Raw;
