import { useMemo, useRef } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const providerId = `partners-provider-xxx`;

export function usePartnersProvider(searchString) {
  const { data, isLoading } = useZeusDataProvider(providerId, []);
  const ref = useRef();

  const Provider = useMemo(() => {
    const Component = () => {
      const params = {
        "filter[name][condition][path]": "label",
        "filter[name][condition][operator]": "CONTAINS",
        "filter[name][condition][value]": searchString,
        "page[limit]": 100,
        include: "field_partner_type,field_logo",
      };

      const query = new URLSearchParams(params);
      const url = `${getSetting("BACKEND")}/jsonapi/group/partner?${query}`;

      return (
        <zeus-data-provider
          ref={ref}
          id={providerId}
          listen-tags="group-partner"
        >
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [searchString]);

  return {
    ref,
    providerId,
    Provider,
    partners: data,
    isLoading,
  };
}
