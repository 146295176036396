import { useState } from "react";
import {
  IonLoading,
  IonPage,
  useIonRouter,
  IonAlert,
  IonLabel,
  IonItem,
  IonToggle,
  IonList,
} from "@ionic/react";
import {
  PartnerContainer,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerSelect,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../../settings";
import { useAlert, useCamera, useTaxonomy } from "../../hooks";
import { makeid, preventDefault } from "../../helpers";

export const AdminPartnerAdd = () => {
  const router = useIonRouter();
  const providerId = "admin-create-partner";
  const { setData, isLoading, status } = useZeusDataProvider(providerId);
  const showAlert = useAlert();
  const partnerTypes = useTaxonomy("partner_type");
  const { getPhoto } = useCamera();
  const [image, setImage] = useState(null);
  const [hideInList, setHideInList] = useState(null);
  const [formState, setFormState] = useState({
    label: "",
    field_email: "",
    field_social_facebook: "",
    field_social_instagram: "",
    field_social_twitter: "",
    field_social_linkedin: "",
    field_description: "",
    field_partner_type: "",
    field_quota_offer: "",
    field_quota_request: "",
  });

  const doSubmit = () => {
    // Validate form.
    if (!formState.label) {
      showAlert("Du måste fylla i ett namn.");
      return;
    }

    if (!formState.field_partner_type) {
      showAlert("Du måste välja en partnertyp.");
      return;
    }

    const data = {
      ...formState,
      field_hide_in_list: hideInList,
      _terms: [
        {
          uuid: formState.field_partner_type,
          field: "field_partner_type",
          vid: "partner_type",
        },
      ],
      _group: getSetting("APP_ID"),
      _type: "partner",
    };

    delete data.field_partner_type;

    if (image) {
      data._files = [
        {
          field: "field_logo",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData(data);
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={status === "created"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.push("/u/admin/network", "back")}
        message="Partnern har skapats."
      />
      <IonLoading isOpen={isLoading} />
      <PageWithCollapsableHeader
        title="Skapa partner"
        mode="back"
        defaultHref="/u/admin/network"
      >
        <PartnerContainer>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            modifiers="partner-media-with-button--company"
            img={image?.webPath}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>

        <form onSubmit={preventDefault(doSubmit)}>
          <IonList>
            <PartnerSelect
              icon="icons/chevron-down16.svg"
              label="Partnertyp"
              placeholder="Välj partnertyp"
              options={partnerTypes.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              value={formState.field_partner_type}
              onChange={updateFormState("field_partner_type")}
            />
          </IonList>

          <PartnerContainer>
            <PartnerInput
              value={formState.label}
              onChange={updateFormState("label")}
              label="Företagsnamn"
            />
            <PartnerInput
              value={formState.field_email}
              onChange={updateFormState("field_email")}
              label="E-post"
            />
            <PartnerInput
              value={formState.field_quota_offer}
              onChange={updateFormState("field_quota_offer")}
              label="Max antal erbjudanden"
            />
            <PartnerInput
              value={formState.field_quota_request}
              onChange={updateFormState("field_quota_request")}
              label="Max antal förfrågningar"
            />
            <PartnerInput
              value={formState.field_social_facebook}
              onChange={updateFormState("field_social_facebook")}
              label="Facebook"
            />
            <PartnerInput
              value={formState.field_social_instagram}
              onChange={updateFormState("field_social_instagram")}
              label="Instagram"
            />
            <PartnerInput
              value={formState.field_social_twitter}
              onChange={updateFormState("field_social_twitter")}
              label="Twitter"
            />
            <PartnerInput
              value={formState.field_social_linkedin}
              onChange={updateFormState("field_social_linkedin")}
              label="Linkedin"
            />
            <PartnerInput
              value={formState.field_description}
              onChange={updateFormState("field_description")}
              label="Företagsbeskrivning"
              textarea={true}
            />
            <IonItem lines="none">
              <IonLabel>Dölj i listningen</IonLabel>
              <IonToggle
                checked={hideInList}
                onIonChange={(e) => setHideInList(e.detail.checked)}
              />
            </IonItem>
          </PartnerContainer>
        </form>
        <PartnerContainer>
          <PartnerButton
            text="Spara"
            expand="block"
            onClick={doSubmit}
          ></PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id="admin-create-partner" send-tags="partner-list">
        <zeus-data-source-drupal-subgroup-create
          base-url={getSetting("BACKEND")}
        ></zeus-data-source-drupal-subgroup-create>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminPartnerAdd;
