import { useParams } from "react-router";
import {
  IonPage,
  IonList,
  IonReorderGroup,
  IonReorder,
  IonItem,
  IonButton,
  IonLoading,
} from "@ionic/react";
import {
  PageWithCollapsableHeader,
  PartnerContainer,
  PartnerButton,
} from "@understandit/partnerplattformen-storybook-30876108";

import {
  useInputAlert,
  useTaxonomy,
  useConfirmDangerousAction,
  useActionModal,
  useActionSheet,
} from "../../hooks";
import { useTermCreateProvider } from "../../providers/term-create-provider";

const taxonomyNames = {
  partner_type: "Partnertyp",
  industry: "Bransch",
};

const AdminTaxonomy = () => {
  const { id: vocabulary } = useParams();
  const terms = useTaxonomy(vocabulary);
  const { Provider, setData, isLoading } = useTermCreateProvider(vocabulary);
  const showAddInput = useInputAlert();
  const presentActionSheet = useActionSheet();
  const confirm = useConfirmDangerousAction();
  const doAction = useActionModal();

  const doHandleReorder = (event) => {
    const newOrder = [...terms];
    const removed = newOrder.splice(event.detail.from, 1);
    newOrder.splice(event.detail.to, 0, removed[0]);

    doAction("sort-terms", {
      bundle: vocabulary,
      terms: newOrder.map((item) => ({ id: item.id, weight: item.weight })),
    });

    event.detail.complete();
  };

  const doOnEdit = async (term) => {
    const data = await presentActionSheet({
      buttons: [
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Redigera", data: "edit" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });

    if (data === "delete") {
      if (await confirm("Är du säker på att du vill ta bort termen?")) {
        doAction("delete", {
          id: term.id,
          type: "taxonomy_term",
          bundle: vocabulary,
        });
      }
    } else if (data === "edit") {
      const name = await showAddInput(
        "Redigera namnet på vald term.",
        term.name
      );
      if (name) {
        doAction("update", {
          id: term.id,
          type: "taxonomy_term",
          bundle: vocabulary,
          attributes: { name },
        });
      }
    }
  };

  const doAddTerm = async () => {
    const name = await showAddInput("Skriv in namnet på den nya termen.");
    if (name) {
      setData({ name });
    }
  };

  return (
    <IonPage>
      <Provider />
      <IonLoading isOpen={isLoading} />
      <PageWithCollapsableHeader title={taxonomyNames[vocabulary]} mode="back">
        <PartnerContainer>
          <PartnerButton
            expand={true}
            onClick={doAddTerm}
            icon="icons/plus16.svg"
          >
            Skapa {taxonomyNames[vocabulary]}
          </PartnerButton>
        </PartnerContainer>
        <PartnerContainer>
          <IonList>
            <IonReorderGroup
              disabled={false}
              onIonItemReorder={doHandleReorder}
            >
              {terms.map((item) => (
                <IonItem
                  className="partner-item-reorder"
                  key={item.id}
                  lines="none"
                >
                  <IonReorder slot="start" />
                  <div className="partner-item-reorder__container">
                    <div className="partner-item-reorder__container-start">
                      <div className="partner-item-reorder__label">
                        {item.name}
                      </div>
                    </div>
                    <div className="partner-item-reorder__container-end">
                      <IonButton
                        className="partner-item-reorder__edit"
                        onClick={() => doOnEdit(item)}
                      >
                        <div className="partner-item-reorder__dot"></div>
                        <div className="partner-item-reorder__dot"></div>
                        <div className="partner-item-reorder__dot"></div>
                      </IonButton>
                    </div>
                  </div>
                </IonItem>
              ))}
            </IonReorderGroup>
          </IonList>
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default AdminTaxonomy;
