import { useState } from "react";
import { IonList, IonPage, useIonRouter } from "@ionic/react";
import {
  PartnerContainer,
  PartnerItem,
  PartnerCard,
  PageWithCollapsableHeader,
  PartnerSelect,
  PartnerSkeletonCard,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import dayjs from "dayjs";

import { stripTags, isPartnerAdmin } from "../helpers";
import { getSetting } from "../settings";

const RequestOverview = () => {
  const [requestList, , , isLoading] = useDynamicDataProvider(
    "request-list",
    []
  );
  const router = useIonRouter();
  const [profile] = useDynamicDataProvider("profile", []);
  const [membership] = useDynamicDataProvider("partner-group-membership");
  const [industryFilter, setIndustryFilter] = useState("all");

  const industryTerms = [
    { id: "all", name: "Alla" },
    ...requestList
      .reduce((acc, item) => {
        if (
          item.field_industry &&
          acc.findIndex((i) => i.id === item.field_industry.id) === -1
        ) {
          acc.push({
            id: item.field_industry.id,
            name: item.field_industry.name,
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name)),
  ];

  const filter = (items) =>
    industryFilter === "all"
      ? items
      : items.filter((item) => item.field_industry?.id === industryFilter);

  return (
    <IonPage>
      <PageWithCollapsableHeader title="Förfrågningar">
        <IonList>
          {isPartnerAdmin(membership) && (
            <PartnerItem
              label="Mina förfrågningar"
              icon="icons/question24.svg"
              iconEnd="icons/chevron-right16.svg"
              routerLink={`/u/profile/${profile?.id}/request`}
            />
          )}
          {industryTerms?.length > 1 && (
            <PartnerSelect
              icon="icons/chevron-down16.svg"
              label="Bransch"
              placeholder="Välj bransch"
              options={industryTerms.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              value={industryFilter}
              onChange={(e) => setIndustryFilter(e.detail.value)}
            />
          )}
        </IonList>
        <PartnerContainer>
          {isLoading && requestList.length === 0 && (
            <PartnerSkeletonCard
              size="small"
              modifiers="partner-card--small-and-long"
            />
          )}
          {filter(requestList).map((item) => (
            <PartnerCard
              size="small"
              modifiers="partner-card--small-and-long line-clamp-3"
              onClick={() => router.push(`/u/request/${item.id}`)}
              key={item.id}
              title={item.title}
              date={dayjs(item.created).format("D MMM, YYYY")}
              imageSrc={
                item.field_image?.image_style_uri.large ??
                getSetting("DEFAULT_IMAGE")
              }
              badgeSrc={
                item.entitygroupfield[0]?.gid?.field_logo?.image_style_uri.large
              }
            >
              {stripTags(item.field_preamble ?? item.body?.processed)}
            </PartnerCard>
          ))}
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default RequestOverview;
