import { rowsToCsv } from "../helpers";
import { getSetting } from "../settings";
import { Deserializer } from "jsonapi-serializer";

function deserializeJsonApi(data) {
  const opts = {
    keyForAttribute: (x) => x,
    typeAsAttribute: true,
  };
  const deserializer = new Deserializer(opts);
  return deserializer.deserialize(data);
}

function getToken() {
  const q = new Promise((resolve, reject) => {
    document.addEventListener(
      `zeusDataAvailable:auth`,
      (event) => {
        if (event.detail && event.detail.tokens) {
          resolve(event.detail.tokens);
        } else {
          reject("No token");
        }
      },
      { once: true }
    );
  });
  document.dispatchEvent(new Event(`zeusDataListener:auth`));
  return q;
}

export async function getEvent(id) {
  const url = `${getSetting(
    "BACKEND"
  )}/jsonapi/group/event/${id}?include=field_industry,field_image,field_event_access_term,field_event_access_partner`;
  const tokens = await getToken();
  const response = await fetch(url, {
    headers: { authorization: `Bearer ${tokens.access_token}` },
  });

  const body = await response.json();

  const opts = {
    keyForAttribute: (x) => x,
    typeAsAttribute: true,
  };
  const deserializer = new Deserializer(opts);
  const decoded = await deserializer.deserialize(body);
  return decoded;
}

async function getEventParticipantPage(url) {
  const tokens = await getToken();
  const response = await fetch(url, {
    headers: { authorization: `Bearer ${tokens.access_token}` },
  });
  return response.json();
}

export async function getEventParticipants(id) {
  let allPages = [];
  const url = `${getSetting(
    "BACKEND"
  )}/jsonapi/group_content/event-group_membership?filter[gid.id]=${id}&include=field_partner_membership.gid,entity_id`;

  let page = { links: { next: url } };

  while (page.links.next) {
    page = await getEventParticipantPage(page.links.next);
    allPages = allPages.concat(await deserializeJsonApi(page));
  }

  return allPages;
}

function surveyAnswers(survey, values) {
  return survey.components.map((component) => values[component.name] ?? "");
}

export function exportParticipants(survey, participants) {
  const headers = ["Name", "Email", "Telephone", "Partner", "Invited By"];
  for (const component of survey.components) {
    headers.push(component.title);
  }

  const rows = [];
  for (const row of participants) {
    const surveyResults = JSON.parse(row.field_survey_results);
    rows.push([
      row.field_partner_membership?.field_name,
      row.entity_id.mail,
      row.field_partner_membership?.field_telephone,
      row.field_partner_membership?.gid?.label,
      "",
      ...surveyAnswers(survey, surveyResults?.values ?? {}),
    ]);

    for (const guest of surveyResults?.guests ?? []) {
      rows.push([
        guest.name,
        guest.email,
        guest.telephone,
        row.field_partner_membership?.gid?.label,
        row.field_partner_membership?.field_name,
        ...surveyAnswers(survey, guest?.values ?? {}),
      ]);
    }
  }

  return rowsToCsv(rows, headers);
}
