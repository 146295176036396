import {
  PartnerGrid,
  PartnerMedia,
  PartnerHeading,
} from "@understandit/partnerplattformen-storybook-30876108";

import { formatUrl } from "../helpers";

const SocialMediaIcons = ({ membership }) => {
  if (
    !(
      membership?.field_social_facebook ||
      membership?.field_social_instagram ||
      membership?.field_social_twitter ||
      membership?.field_social_linkedin
    )
  ) {
    return null;
  }

  return (
    <>
      <PartnerHeading
        modifiers="partner-heading--start"
        text="Sociala medier"
      ></PartnerHeading>
      <PartnerGrid modifiers="partner-css-grid--social-media">
        {membership?.field_social_facebook && (
          <a
            href={formatUrl(membership?.field_social_facebook)}
            target="_blank"
            rel="noreferrer"
          >
            <PartnerMedia
              modifiers="partner-media--social-media"
              img="images/facebook-logo.svg"
            />
          </a>
        )}
        {membership?.field_social_instagram && (
          <a
            href={formatUrl(membership?.field_social_instagram)}
            target="_blank"
            rel="noreferrer"
          >
            <PartnerMedia
              modifiers="partner-media--social-media"
              img="images/instagram-logo.svg"
            />
          </a>
        )}
        {membership?.field_social_twitter && (
          <a
            href={formatUrl(membership?.field_social_twitter)}
            target="_blank"
            rel="noreferrer"
          >
            <PartnerMedia
              modifiers="partner-media--social-media"
              img="images/twitter-logo.svg"
            />
          </a>
        )}
        {membership?.field_social_linkedin && (
          <a
            href={formatUrl(membership?.field_social_linkedin)}
            target="_blank"
            rel="noreferrer"
          >
            <PartnerMedia
              modifiers="partner-media--social-media"
              img="images/linkedin-logo.svg"
            />
          </a>
        )}
      </PartnerGrid>
    </>
  );
};

export default SocialMediaIcons;
