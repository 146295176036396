import { useRef } from "react";
import { IonPage, useIonRouter } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerHeadingWithButton,
  PartnerContainer,
  PageWithCollapsableHeader,
  PartnerCard,
  PartnerSkeletonCard,
} from "@understandit/partnerplattformen-storybook-30876108";
import dayjs from "dayjs";

import { stripTags } from "../helpers";
import { getSetting } from "../settings";

import SlideSection from "../components/SlideSection";

const NewsOverview = ({
  defaultHref,
  detailNav = true,
  baseHref = "/u/news",
  anon = false,
}) => {
  const providerId = anon ? "anon-news-list" : "news-list";
  const [newsList, , , newsIsLoading] = useDynamicDataProvider(providerId, []);
  const router = useIonRouter();
  const pageElem = useRef(null);
  return (
    <IonPage ref={pageElem}>
      <PageWithCollapsableHeader
        title="Nyheter"
        defaultHref={defaultHref}
        mode={defaultHref ? "back" : null}
      >
        <SlideSection heading="Senaste nytt" slidesPerView={1.1}>
          {newsIsLoading && newsList.length === 0
            ? [
                <PartnerSkeletonCard
                  key="1"
                  modifiers="partner-card--margin"
                  size="x-large"
                />,
                <PartnerSkeletonCard key="2" size="x-large" />,
              ]
            : newsList.slice(0, 4).map((item, i) => (
                <PartnerCard
                  size="x-large"
                  key={item.id}
                  onClick={() =>
                    detailNav && router.push(`${baseHref}/${item.id}`)
                  }
                  modifiers={
                    newsList.length > 1 && i !== newsList.length - 1
                      ? "partner-card--margin"
                      : null
                  }
                  title={item.title}
                  date={dayjs(item.created).format("D MMM, YYYY")}
                  imageSrc={
                    item.field_image?.image_style_uri.large ??
                    getSetting("DEFAULT_IMAGE")
                  }
                >
                  {stripTags(item.field_preamble)}
                </PartnerCard>
              ))}
        </SlideSection>

        {newsList.length > 3 && (
          <PartnerContainer>
            <PartnerHeadingWithButton heading="Äldre nyheter" />
            {newsList.slice(4).map((item) => (
              <PartnerCard
                onClick={() =>
                  detailNav && router.push(`${baseHref}/${item.id}`)
                }
                size="medium"
                key={item.id}
                title={item.title}
                date={dayjs(item.created).format("D MMM, YYYY")}
                imageSrc={
                  item.field_image?.image_style_uri.large ??
                  getSetting("DEFAULT_IMAGE")
                }
              >
                {stripTags(item.field_preamble)}
              </PartnerCard>
            ))}
          </PartnerContainer>
        )}
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default NewsOverview;
