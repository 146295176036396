import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import {
  PartnerButton,
  PartnerContainer,
  PartnerHeading,
  PartnerMedia,
  PartnerText,
  PartnerCard,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";

const Start = () => {
  const router = useIonRouter();

  return (
    <IonPage>
      <IonContent>
        <PartnerMedia
          img={getSetting("LOGO")}
          modifiers="partner-media--team-logo partner-media--start-page"
        />
        <PartnerContainer modifiers="partner-container--start">
          <PartnerHeading
            text={getSetting("START_HEADLINE")}
            modifiers="partner-heading--margin-login"
          />
        </PartnerContainer>
        <PartnerContainer>
          <PartnerCard
            onClick={() => router.push(`/news`)}
            size="small"
            title="Nyheter"
            imageSrc={getSetting("START_NEWS_IMG")}
          >
            {getSetting("START_NEWS_TEXT")}
          </PartnerCard>
          <PartnerCard
            onClick={() => router.push(`/network`)}
            size="small"
            title="Partner"
            imageSrc={getSetting("START_PRTNR_IMG")}
          >
            {getSetting("START_PRTNR_TEXT")}
          </PartnerCard>
          <PartnerCard
            onClick={() => router.push(`/login`)}
            size="small"
            modifiers="partner-card--secondary"
            title="Logga in"
            imageSrc={getSetting("START_LOGIN_IMG")}
          >
            {getSetting("START_LOGIN_TEXT")}
          </PartnerCard>
        </PartnerContainer>
        <PartnerContainer modifiers="partner-container--extra-padding-bottom">
          <PartnerText modifiers="partner-text--start-page-disclaimer partner-text--small-margin-bottom">
            {getSetting("START_INFO_TEXT")}
          </PartnerText>

          <PartnerButton
            href={`mailto:${getSetting("START_EMAIL")}`}
            expand={true}
          >
            {getSetting("START_ACTION_BTN")}
          </PartnerButton>

          <PartnerButton
            text={getSetting("START_TERMS_BTN")}
            expand={true}
            modifiers="partner-button--secondary"
            routerLink="/terms"
          ></PartnerButton>
        </PartnerContainer>
      </IonContent>
    </IonPage>
  );
};

export default Start;
