import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import NewsOverview from "./pages/NewsOverview";
import EventOverview from "./pages/EventOverview";
import OfferOverview from "./pages/OfferOverview";
import RequestOverview from "./pages/RequestOverview";
import NetworkOverview from "./pages/NetworkOverview";
import NewsDetail from "./pages/NewsDetail";
import EventDetail from "./pages/EventDetail";
import OfferDetail from "./pages/OfferDetail";
import RequestDetail from "./pages/RequestDetail";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import ProfileEvent from "./pages/ProfileEvent";
import ProfileOffer from "./pages/ProfileOffer";
import ProfileInvite from "./pages/ProfileInvite";
import ProfileRequest from "./pages/ProfileRequest";
import NetworkDetail from "./pages/NetworkDetail";
import OfferCreate from "./pages/OfferCreate";
import RequestCreate from "./pages/RequestCreate";
import PushSettings from "./pages/PushSettings";
import PartnerSettings from "./pages/PartnerSettings";
import AdminContent from "./pages/admin/AdminContent";
import AdminCreateNews from "./pages/admin/AdminCreateNews";
import AdminCreateOffer from "./pages/admin/AdminCreateOffer";
import AdminCreateRequest from "./pages/admin/AdminCreateRequest";
import AdminCreateEvent from "./pages/admin/AdminCreateEvent";
import AdminEditNews from "./pages/admin/AdminEditNews";
import AdminEditEvent from "./pages/admin/AdminEditEvent";
import AdminEditOffer from "./pages/admin/AdminEditOffer";
import AdminEditRequest from "./pages/admin/AdminEditRequest";
import NetworkDetailProfile from "./pages/NetworkDetailProfile";
import ResetPassword from "./pages/ResetPassword";
import ProfilePassword from "./pages/ProfilePassword";
import TermsInfo from "./pages/TermsInfo";
import Start from "./pages/Start";
import EventParticipants from "./pages/EventParticipants";
import AdminPartner from "./pages/admin/AdminPartner";
import AdminPartnerEdit from "./pages/admin/AdminPartnerEdit";
import AdminPartnerView from "./pages/admin/AdminPartnerView";
import AdminPartnerInvite from "./pages/admin/AdminPartnerInvite";
import AdminPartnerAdd from "./pages/admin/AdminPartnerAdd";
import AdminTaxonomy from "./pages/admin/AdminTaxonomy";
import AdminMemberEdit from "./pages/admin/AdminMemberEdit";
import AdminMemberView from "./pages/admin/AdminMemberView";
import AdminNewsView from "./pages/admin/AdminNewsView";
import AdminCreatePush from "./pages/admin/AdminCreatePush";
import {
  AdminCreateEventSurvey,
  AdminCreateEventSurveyAdd,
  AdminCreateEventSurveyEdit,
} from "./pages/admin/AdminSurvey";
import EventSignUp from "./pages/EventSignUp";
import EventSubmission from "./pages/EventSubmission";
import EventEditSubmission from "./pages/EventEditSubmission";

export const routes = [
  { path: "/u/dashboard", component: Dashboard, params: {}, auth: "auth" },

  { path: "/u/profile", component: Profile, params: {}, auth: "auth" },
  {
    path: "/u/profile/:id/edit",
    component: ProfileEdit,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/profile/password/:email",
    component: ProfilePassword,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/profile/:id/event",
    component: ProfileEvent,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/profile/:id/offer",
    component: ProfileOffer,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/profile/:id/request",
    component: ProfileRequest,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/profile/:id/push",
    component: PushSettings,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/profile/network/:id",
    component: NetworkDetail,
    params: { title: "Mitt team", defaultHref: "/u/profile" },
    auth: "auth",
  },
  {
    path: "/u/profile/network/invite/:id",
    component: ProfileInvite,
    params: { title: "Bjud in till team", defaultHref: "/u/profile" },
    auth: "auth",
  },

  { path: "/u/news", component: NewsOverview, params: {}, auth: "auth" },
  { path: "/u/news/:id", component: NewsDetail, params: {}, auth: "auth" },

  { path: "/u/event", component: EventOverview, params: {}, auth: "auth" },
  { path: "/u/event/:id", component: EventDetail, params: {}, auth: "auth" },
  {
    path: "/u/event/:id/signup",
    component: EventSignUp,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/event/:id/submission",
    component: EventSubmission,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/event/:id/submission/:sid/edit",
    component: EventEditSubmission,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/event/:id/participants",
    component: EventParticipants,
    params: {},
    auth: "auth",
  },

  { path: "/u/offer", component: OfferOverview, params: {}, auth: "auth" },
  { path: "/u/offer/:id", component: OfferDetail, params: {}, auth: "auth" },
  { path: "/u/offer/create", component: OfferCreate, params: {}, auth: "auth" },

  { path: "/u/request", component: RequestOverview, params: {}, auth: "auth" },
  {
    path: "/u/request/:id",
    component: RequestDetail,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/request/create",
    component: RequestCreate,
    params: {},
    auth: "auth",
  },

  { path: "/u/network", component: NetworkOverview, params: {}, auth: "auth" },
  {
    path: "/u/network/:id",
    component: NetworkDetail,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/network/:id/edit",
    component: PartnerSettings,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/network/profile/:id",
    component: NetworkDetailProfile,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/admin/content",
    component: AdminContent,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/news",
    component: AdminCreateNews,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/offer",
    component: AdminCreateOffer,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/request",
    component: AdminCreateRequest,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/event",
    component: AdminCreateEvent,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/event/survey",
    component: AdminCreateEventSurvey,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/event/survey/add",
    component: AdminCreateEventSurveyAdd,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/create/event/survey/edit/:id",
    component: AdminCreateEventSurveyEdit,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/admin/news/:id",
    component: AdminNewsView,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/news/:id/edit",
    component: AdminEditNews,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/event/:id",
    component: EventDetail,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/event/:id/edit",
    component: AdminEditEvent,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/event/:id/edit/survey",
    component: AdminCreateEventSurvey,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/event/:id/edit/survey/add",
    component: AdminCreateEventSurveyAdd,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/event/:eid/edit/survey/edit/:id",
    component: AdminCreateEventSurveyEdit,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/admin/offer/:id",
    component: OfferDetail,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/offer/:id/edit",
    component: AdminEditOffer,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/request/:id",
    component: RequestDetail,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/request/:id/edit",
    component: AdminEditRequest,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/admin/:type/:id/push",
    component: AdminCreatePush,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/admin/network",
    component: AdminPartner,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/network/:id",
    component: AdminPartnerView,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/network/:id/edit",
    component: AdminPartnerEdit,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/network/:id/invite",
    component: AdminPartnerInvite,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/network/add",
    component: AdminPartnerAdd,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/network/:partner_id/member/:id",
    component: AdminMemberView,
    params: {},
    auth: "auth",
  },
  {
    path: "/u/admin/network/:partner_id/member/:id/edit",
    component: AdminMemberEdit,
    params: {},
    auth: "auth",
  },

  {
    path: "/u/admin/taxonomy/:id",
    component: AdminTaxonomy,
    params: {},
    auth: "auth",
  },

  {
    path: "/login",
    component: Login,
    auth: "noauth",
    params: { defaultHref: "/start" },
  },
  {
    path: "/login/password",
    component: ResetPassword,
    auth: "noauth",
    params: {},
  },
  { path: "/start", component: Start, auth: "noauth", params: {} },

  {
    path: "/news",
    component: NewsOverview,
    auth: "",
    params: { defaultHref: "/start", baseHref: "/news", anon: true },
  },
  {
    path: "/news/:id",
    component: NewsDetail,
    auth: "",
    params: { defaultHref: "/news" },
  },

  {
    path: "/network",
    component: NetworkOverview,
    auth: "",
    params: { defaultHref: "/start", detailNav: false },
  },

  { path: "/terms", component: TermsInfo, auth: "", params: {} },
];
