import { IonPage } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerGrid,
  PartnerHeading,
  PageWithCollapsableHeader,
  PartnerLogo,
} from "@understandit/partnerplattformen-storybook-30876108";
import { Link } from "react-router-dom";

const MaybeLinked = ({ logo, routerLink }) => {
  if (routerLink) {
    return (
      <Link to={routerLink}>
        <PartnerLogo logo={logo} />
      </Link>
    );
  }

  return <PartnerLogo logo={logo} />;
};

const NetworkOverview = ({ defaultHref, detailNav = true }) => {
  const [partnerList] = useDynamicDataProvider("partner-list", []);

  const sorted = partnerList
    .sort((a, b) => a.label?.localeCompare(b.label, "sv"))
    .reduce((acc, item) => {
      if (item.field_hide_in_list === true) {
        return acc;
      }
      if (item.field_partner_type?.id) {
        acc[item.field_partner_type.id] = acc[item.field_partner_type.id] ?? {
          id: item.field_partner_type.id,
          heading: item.field_partner_type.name,
          partners: [],
          weight: item.field_partner_type.weight,
        };
        acc[item.field_partner_type.id].partners.push(item);
      }
      return acc;
    }, {});

  const partnerGroups = Object.values(sorted).sort(
    (a, b) => a.weight - b.weight
  );

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Nätverket"
        defaultHref={defaultHref}
        mode={defaultHref ? "back" : null}
      >
        <PartnerContainer>
          {partnerGroups.map((group) => (
            <div key={group.id}>
              <PartnerHeading text={group.heading} />
              <PartnerGrid modifiers="partner-css-grid--logos">
                {group.partners.map((item) => (
                  <MaybeLinked
                    routerLink={detailNav && `/u/network/${item.id}`}
                    key={item.id}
                    logo={item.field_logo?.image_style_uri.large}
                  />
                ))}
              </PartnerGrid>
            </div>
          ))}
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default NetworkOverview;
