import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Redirect } from "react-router";

import { getSetting } from "./settings";

const AuthenticationContext = createContext();

function setData(args) {
  document.dispatchEvent(
    new CustomEvent(`zeusDataData:auth`, { detail: args })
  );
}

export function AuthenticatedRoute({ mode, children }) {
  const { isAuthenticated, isLoading } = useAuthentication();

  if (isLoading && !isAuthenticated) {
    return "Laddar...";
  }

  if (mode === "auth" && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (mode === "noauth" && isAuthenticated) {
    return <Redirect to="/u/dashboard" />;
  }

  return children;
}

export function AuthenticationProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = useCallback((username, password) => {
    setData({ action: "login", username, password });
    return new Promise((resolve, reject) => {
      function handleStatus(event) {
        if (event.detail.status === "error") {
          cleanup();
          reject(event.detail.data);
        }
      }

      function handleData(event) {
        if (event.detail.status === "authenticated") {
          cleanup();
          resolve(true);
        }
      }

      function cleanup() {
        document.removeEventListener(`zeusDataAvailable:auth`, handleData);
        document.removeEventListener(`zeusDataStatus:auth`, handleStatus);
      }

      document.addEventListener(`zeusDataAvailable:auth`, handleData);
      document.addEventListener(`zeusDataStatus:auth`, handleStatus);
    });
  }, []);

  useEffect(() => {
    function handleDataAvailable(event) {
      setIsAuthenticated(event.detail.status === "authenticated");
      setIsLoading(false);
    }

    function handleDataArrive() {
      // Notify my existance.
      document.dispatchEvent(new Event(`zeusDataListener:auth`));
    }

    document.addEventListener(`zeusDataAvailable:auth`, handleDataAvailable);
    document.addEventListener(`zeusDataArrive:auth`, handleDataArrive);

    document.dispatchEvent(new Event("zeusDataListener:auth"));
  }, []);

  const value = {
    login,
    isLoading,
    isAuthenticated,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
      <zeus-data-provider id="auth" no-cache>
        <zeus-data-source-oauth
          url={`${getSetting("BACKEND")}/oauth/token`}
          client-id={getSetting("CLIENT_ID")}
          client-secret={getSetting("CLIENT_SECRET")}
          grant-type="password"
          scope="super_admin"
        ></zeus-data-source-oauth>
      </zeus-data-provider>
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationProvider"
    );
  }
  return context;
}
