import { IonPage, IonList, IonItem, IonIcon } from "@ionic/react";
import { isPlatform } from "@ionic/core";
import {
  PartnerButton,
  PartnerContainer,
  PartnerMediaWithLogo,
  PartnerHeading,
  PartnerText,
  PageWithCollapsableHeader,
  PartnerItemInfo,
  PartnerAvatar,
} from "@understandit/partnerplattformen-storybook-30876108";
import {
  useDynamicDataProvider,
  useDynamicDataAuthentication,
} from "@understandit/dynamic-data-react";

import { isPartnerAdmin } from "../helpers";

import SocialMediaIcons from "../components/SocialMediaIcons";

const MenuItem = ({ routerLink, icon, children }) => (
  <IonItem
    lines="full"
    className="partner-item partner-item--menu-white"
    detail="false"
    routerLink={routerLink}
  >
    <div className="partner-item__container">
      <div className="partner-item__container-start">
        <IonIcon className="partner-item__icon" src={icon} />
        <p className="partner-item__label">{children}</p>
      </div>
      <div className="partner-item__container-end">
        <IonIcon
          className="partner-item__iconEnd"
          src="icons/chevron-right24.svg"
        />
      </div>
    </div>
  </IonItem>
);

const Profile = () => {
  const [profile] = useDynamicDataProvider("profile", []);
  const [myPartner] = useDynamicDataProvider("my-partner", []);
  const [membership] = useDynamicDataProvider("partner-group-membership", null);
  const { logout } = useDynamicDataAuthentication("auth");

  return (
    <IonPage>
      <PageWithCollapsableHeader title="Profil">
        <PartnerContainer>
          <PartnerMediaWithLogo
            media={membership?.field_avatar?.image_style_uri.medium}
            logo={myPartner?.field_logo?.image_style_uri.medium}
            inline={<PartnerAvatar />}
          />

          <PartnerHeading
            modifiers="partner-heading--margin-top"
            text={membership?.field_name}
          />

          <PartnerText modifiers="partner-text--center partner-text--small partner-text--color-medium">
            {isPartnerAdmin(membership) && (
              <>
                <IonIcon
                  style={{ fontSize: "12px" }}
                  src="icons/settings12.svg"
                />{" "}
                |{" "}
              </>
            )}
            {membership?.field_job_title}
          </PartnerText>

          <PartnerText modifiers="partner-text--margin-top partner-text--margin-bottom">
            {membership?.field_description}
          </PartnerText>

          <PartnerItemInfo
            mail={profile?.mail}
            phone={membership?.field_telephone}
          />
        </PartnerContainer>

        <PartnerContainer>
          <SocialMediaIcons membership={membership} />

          <IonList className="menu-list partner-menu--rounded">
            <MenuItem
              icon="icons/user-circle24.svg"
              routerLink={`/u/profile/${membership?.id}/edit`}
            >
              Redigera profil
            </MenuItem>
            <MenuItem
              icon="icons/lock24.svg"
              routerLink={`/u/profile/password/${profile?.mail}`}
            >
              Uppdatera lösenord
            </MenuItem>
            {isPartnerAdmin(membership) && (
              <MenuItem
                icon="icons/home24.svg"
                routerLink={`/u/network/${myPartner?.id}/edit`}
              >
                Företagsinfo
              </MenuItem>
            )}
            {isPlatform("hybrid") && (
              <MenuItem
                icon="icons/notification24.svg"
                routerLink={`/u/profile/${profile?.id}/push`}
              >
                Pushnotiser
              </MenuItem>
            )}
            <MenuItem
              icon="icons/conversation24.svg"
              routerLink={`/u/profile/${profile?.id}/event`}
            >
              Mina events
            </MenuItem>
            <MenuItem
              icon="icons/offer24.svg"
              routerLink={`/u/profile/${profile?.id}/offer`}
            >
              Mina erbjudanden
            </MenuItem>
            <MenuItem
              icon="icons/question24.svg"
              routerLink={`/u/profile/${profile?.id}/request`}
            >
              Mina förfrågningar
            </MenuItem>
            <MenuItem
              icon="icons/users24.svg"
              routerLink={`/u/profile/network/${myPartner?.id}`}
            >
              Mitt team
            </MenuItem>
            {isPartnerAdmin(membership) && (
              <MenuItem
                icon="icons/user-add24.svg"
                routerLink={`/u/profile/network/invite/${myPartner?.id}`}
              >
                Bjud in till team
              </MenuItem>
            )}
          </IonList>
        </PartnerContainer>

        <PartnerContainer modifiers="partner-container--extra-padding-bottom">
          <PartnerButton
            text="Logga ut"
            expand="block"
            onClick={logout}
          ></PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default Profile;
