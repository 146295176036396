import dayjs from "dayjs";

export function stripTags(input) {
  return input?.replace(/(<([^>]+)>)/gi, "") ?? "";
}

export function classList() {
  let classes = [];
  for (const item of arguments) {
    if (typeof item === "string") {
      classes = [...classes, ...item.trim().split(" ")];
    } else if (Array.isArray(item)) {
      classes = [...classes, ...item];
    }
  }
  return classes.filter((item) => Boolean(item)).join(" ");
}

export function isPartnerAdmin(membership) {
  return (
    membership?.group_roles?.some(
      (role) => role.drupal_internal__id === "partner-admin"
    ) ?? false
  );
}

export function isAdminForPartner(membership, partner) {
  const mem = membership?.gid?.id ?? "not";
  const par = partner?.id ?? "par";

  if (mem !== par) {
    return false;
  }

  return (
    membership?.group_roles?.some(
      (role) => role.drupal_internal__id === "partner-admin"
    ) ?? false
  );
}

export function isSuperAdmin(profile) {
  return (
    profile?.roles?.some(
      (role) => role.drupal_internal__id === "super_admin"
    ) ?? false
  );
}

export function makeid(length) {
  let result = "";
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

export function getContentTypeName(type) {
  const names = {
    event: "Event",
    news: "Nyhet",
    offer: "Erbjudande",
    request: "Förfrågan",
  };
  return names[type] ?? "Annan";
}

export function formatDateTimeRange(date1, date2) {
  if (date1 && date2) {
    if (dayjs(date1).isSame(date2, "year")) {
      return (
        dayjs(date1).format("D MMM HH:mm") +
        " - " +
        dayjs(date2).format("D MMM HH:mm, YYYY")
      );
    }
    return (
      dayjs(date1).format("D MMM HH:mm, YYYY") +
      " - " +
      dayjs(date2).format("D MMM HH:mm, YYYY")
    );
  }

  if (date1) {
    return dayjs(date1).format("D MMM HH:mm, YYYY");
  }

  return null;
}

export function formatDateRange(date1, date2) {
  if (date1 && date2) {
    if (dayjs(date1).isSame(date2, "year")) {
      return (
        dayjs(date1).format("D MMM") +
        " - " +
        dayjs(date2).format("D MMM, YYYY")
      );
    }
    return (
      dayjs(date1).format("D MMM, YYYY") +
      " - " +
      dayjs(date2).format("D MMM, YYYY")
    );
  }

  if (date1) {
    return dayjs(date1).format("D MMM, YYYY");
  }

  return null;
}

export function formatDate(date) {
  if (date) {
    return dayjs(date).format("D MMM, YYYY");
  }
  return null;
}

export function formatDateTime(date) {
  if (date) {
    return dayjs(date).format("D MMM, YYYY, HH:mm");
  }
  return null;
}

export function formatUrl(url) {
  const search = url.match(/^https?:\/\/(.*)$/i);
  if (search) {
    return url;
  }
  return `https://${url}`;
}

export function formatEmailUrl(url) {
  return `mailto:${url}`;
}

export function rowsToCsv(rows, headers = []) {
  function processRow(row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      let innerValue = !Boolean(row[j]) ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) {
        finalVal += ",";
      }
      finalVal += result;
    }
    return finalVal + "\n";
  }

  if (headers && headers.length > 0) {
    rows = [headers, ...rows];
  }

  return rows.map((row) => processRow(row)).join("");
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function stopPropagation(arg) {
  return (e) => {
    e?.stopPropagation();
    arg?.();
  };
}

export function preventDefault(arg) {
  return (e) => {
    e?.preventDefault();
    arg?.();
  };
}

export function preventAndStop(arg) {
  return (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    arg?.();
  };
}

export function isVisible(entity, membership) {
  const entityPartnerAccess =
    entity?.field_access_partner?.map((i) => i.id) ?? [];
  const entityPartnerTypeAccess =
    entity?.field_access_term?.map((i) => i.id) ?? [];

  const membershipPartnerType = membership?.gid?.field_partner_type?.id;
  const membershipPartner = membership?.gid?.id;

  // If no access is set on event, it is visible for all members.
  if (
    entityPartnerAccess.length === 0 &&
    entityPartnerTypeAccess.length === 0
  ) {
    return true;
  }

  // Highest priority: Specific partners.
  if (entityPartnerAccess.includes(membershipPartner)) {
    return true;
  }

  // Second priority: Partner type.
  if (entityPartnerTypeAccess.includes(membershipPartnerType)) {
    return true;
  }

  return false;
}
