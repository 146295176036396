import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  IonPage,
  IonLoading,
  IonAlert,
  IonList,
  useIonRouter,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerText,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PartnerDatePicker,
  PageWithCollapsableHeader,
  PartnerItem,
} from "@understandit/partnerplattformen-storybook-30876108";
import dayjs from "dayjs";

import { getSetting } from "../../settings";
import { makeid } from "../../helpers";
import { useAppState } from "../../app-context";
import { getEvent } from "../../api/event";
import { useCamera } from "../../hooks";
import DynamicTextArea from "../../components/DynamicTextarea";
import PartnerAccesField from "../../components/PartnerAccessField";
import TermAccesField from "../../components/TermAccessField";
import NavigationPrompt from "../../components/NavigationPrompt";

export const AdminEditEvent = () => {
  const [initialDataIsLoading, setInitialDataIsLoading] = useState(true);
  const { id } = useParams();
  const { formState, setFormState } = useAppState();
  const [image, setImage] = useState(null);
  const { getPhoto } = useCamera();
  const router = useIonRouter();
  const { setData, isLoading, error, status } = useZeusDataProvider(
    `admin-edit-event-${id}`,
    null
  );

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  const updateGuestState = (e) => {
    setFormState((current) => {
      return {
        ...current,
        field_survey: {
          ...current.field_survey,
          guests: e.detail.checked ? 1 : 0,
        },
      };
    });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const doSubmit = (e) => {
    e?.preventDefault();
    const data = {
      id,
      ...formState,
      field_event_access_partner: formState.field_event_access_partner.map(
        (id) => ({ id })
      ),
      field_event_access_term: formState.field_event_access_term.map((id) => ({
        id,
      })),
      field_survey: formState.field_survey
        ? JSON.stringify(formState.field_survey)
        : "",
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_image",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }
    delete data.field_image;

    setData(data);
  };

  useEffect(() => {
    setFormState({
      field_date: {
        value: dayjs().format(),
        end_value: dayjs().format(),
      },
      label: "",
      field_location: "",
      field_description: "",
      field_preamble: "",
      field_link: "",
      field_contact_email: "",
      field_event_access_term: [],
      field_event_access_partner: [],
      field_survey: {
        version: "1.0",
        components: [],
        guests: 0,
      },
    });
  }, [setFormState]);

  useEffect(() => {
    if (id) {
      getEvent(id).then((data) => {
        setFormState((current) => ({
          ...current,
          field_date: data.field_date,
          label: data.label,
          field_location: data.field_location,
          field_preamble: data.field_preamble,
          field_description: data.field_description,
          field_link: data.field_link,
          field_contact_email: data.field_contact_email,
          field_image: data.field_image,
          field_event_access_term:
            data.field_event_access_term?.map((term) => term.id) ?? [],
          field_event_access_partner:
            data.field_event_access_partner?.map((term) => term.id) ?? [],
          field_survey: data.field_survey
            ? JSON.parse(data.field_survey)
            : {
                version: "1.0",
                components: [],
                guests: 0,
              },
        }));
        setInitialDataIsLoading(false);
      });
    }
  }, [id, setFormState]);

  return (
    <IonPage>
      <IonLoading isOpen={isLoading || initialDataIsLoading} />
      <IonAlert
        isOpen={error !== ""}
        header={getSetting("APP_NAME")}
        message={error}
      />
      <IonAlert
        isOpen={status === "updated"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.canGoBack() && router.goBack()}
        message="Eventet har uppdaterats."
      />
      <NavigationPrompt when={status !== "updated"} />
      <PageWithCollapsableHeader
        title="Redigera event"
        mode="back"
        defaultHref={`/u/admin/content`}
      >
        <IonList>
          <PartnerDatePicker
            label="Från"
            value={formState.field_date?.value}
            format="D MMM, YYYY HH:mm"
            onChange={(e) =>
              setFormState((current) => ({
                ...current,
                field_date: {
                  value: e.detail.value,
                  end_value: current.field_date.end_value,
                },
              }))
            }
          />
          <PartnerDatePicker
            label="Till"
            value={formState.field_date?.end_value}
            format="D MMM, YYYY HH:mm"
            onChange={(e) =>
              setFormState((current) => ({
                ...current,
                field_date: {
                  value: current.field_date.value,
                  end_value: e.detail.value,
                },
              }))
            }
          />
          <PartnerAccesField
            value={formState.field_event_access_partner}
            onChange={updateFormState("field_event_access_partner")}
          />
          <TermAccesField
            value={formState.field_event_access_term}
            onChange={updateFormState("field_event_access_term")}
          />
        </IonList>

        <PartnerContainer>
          <PartnerText modifiers="partner-text--center partner-text--small-margin-bottom partner-text--mini">
            Ladda upp bild
          </PartnerText>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            img={
              image?.webPath ?? formState?.field_image?.image_style_uri?.wide
            }
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.label}
              onChange={updateFormState("label")}
              label="Rubrik"
            />
            <PartnerInput
              value={formState.field_location}
              onChange={updateFormState("field_location")}
              label="Plats"
            />
            <DynamicTextArea
              value={formState.field_preamble}
              onChange={updateFormState("field_preamble")}
              label="Ingress"
            />
            <DynamicTextArea
              value={formState.field_description}
              onChange={updateFormState("field_description")}
              label="Beskrivning"
            />
            <PartnerInput
              value={formState.field_link}
              onChange={updateFormState("field_link")}
              label="Länk"
            />
            <PartnerInput
              value={formState.field_contact_email}
              onChange={updateFormState("field_contact_email")}
              label="E-post för kontakt"
            />
            <PartnerItem
              label="Tillåt gästanmälan"
              toggle={true}
              modifiers="partner-item--no-padding"
              lines="none"
              toggleChecked={formState.field_survey?.guests > 0}
              toggleChanged={updateGuestState}
            />
            <PartnerButton
              text="Hantera anmälningsformulär"
              expand="block"
              modifiers="partner-button--margin-top-mega partner-button--secondary"
              routerLink={`/u/admin/event/${id}/edit/survey`}
            />
            <PartnerButton
              text="Spara"
              expand="block"
              modifiers="partner-button--margin-top"
              onClick={doSubmit}
            />
          </form>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        write-only
        id={`admin-edit-event-${id}`}
        key={`admin-edit-event-${id}`}
        send-tags={`group-event,group-event-${id}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group/event/${id}?include=field_industry,field_image,field_event_access_term,field_event_access_partner`}
          type="group--event"
          attributes="label,field_preamble,field_location,field_description,field_industry,field_image,field_date,field_event_access_partner,field_event_access_term,field_link,field_contact_email,field_survey"
          relationships="field_image:file--file,field_industry:taxonomy_term--industry,field_event_access_partner:group--partner,field_event_access_term:taxonomy_term--partner_type"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminEditEvent;
