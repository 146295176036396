import { Share } from "@capacitor/share";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";
import { PartnerButton } from "@understandit/partnerplattformen-storybook-30876108";

import { exportParticipants, getEventParticipants } from "../api/event";

const EventMembersDownload = ({ id, close, event }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [downloadLink, setDownloadLink] = useState("");

  useEffect(() => {
    const survey = event?.field_survey ? JSON.parse(event.field_survey) : {};
    getEventParticipants(id).then((participants) => {
      const csvData = exportParticipants(survey, participants);
      if (isPlatform("hybrid")) {
        Filesystem.writeFile({
          path: "deltagare.csv",
          data: csvData,
          directory: Directory.Cache,
          encoding: Encoding.UTF8,
        }).then((file) => {
          console.log(file);
          setDownloadLink(file.uri);
        });
      } else {
        const blob = new Blob([csvData], { type: "text/csv;charset=utf8;" });
        setDownloadLink(URL.createObjectURL(blob));
      }
      setIsLoading(false);
    });
  }, [event.field_survey, id]);

  const doShare = () => {
    Share.share({
      title: "Deltagarlista",
      url: downloadLink,
    });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Deltagare</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={close}>Stäng</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            <IonIcon src="icons/download.svg" style={{ fontSize: "40px" }} />
          </div>
          <div style={{ textAlign: "center", width: "100%" }}>
            Deltagarlistan som .csv-fil.
          </div>
          <div style={{ textAlign: "center", width: "100%" }}>
            {isLoading && <IonSpinner />}
            {!isLoading &&
              (isPlatform("hybrid") ? (
                <PartnerButton onClick={doShare}>Ladda ner</PartnerButton>
              ) : (
                <PartnerButton download="deltagare.csv" href={downloadLink}>
                  Ladda ner
                </PartnerButton>
              ))}
          </div>
        </div>
      </IonContent>
    </>
  );
};

export default EventMembersDownload;
