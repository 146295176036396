import { IonPage, useIonRouter } from "@ionic/react";
import {
  PartnerTextWithIcon,
  PartnerText,
  PartnerContainer,
  PageWithHideableHeader,
  PartnerPopupWithOverlay,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { formatUrl, formatEmailUrl, isVisible } from "../helpers";
import { getSetting } from "../settings";

import Raw from "../components/Raw";
import ArticleHeading from "../components/ArticleHeading";
import InAppLink from "../components/InAppLink";

export const NewsDetail = ({ defaultHref = "/u/news" }) => {
  const { id } = useParams();
  const router = useIonRouter();
  const providerId = `news-item-${id}`;
  const [membership] = useDynamicDataProvider("partner-group-membership");
  const [newsItem] = useDynamicDataProvider(providerId, null);
  const isHidden = !isVisible(newsItem, membership);
  return (
    <IonPage>
      <PageWithHideableHeader
        title={newsItem?.title}
        defaultHref={defaultHref}
        headerImage={
          newsItem?.field_image?.image_style_uri.wide ??
          getSetting("DEFAULT_IMAGE")
        }
      >
        <PartnerContainer modifiers="partner-container--rounded-top">
          <PartnerTextWithIcon
            icon="icons/clock16.svg"
            text={dayjs(newsItem?.created).format("D MMM, YYYY")}
          />
          <ArticleHeading>{newsItem?.title}</ArticleHeading>

          {newsItem?.field_link && (
            <PartnerText>
              <InAppLink href={formatUrl(newsItem.field_link)}>
                {newsItem.field_link}
              </InAppLink>
            </PartnerText>
          )}

          {newsItem?.field_contact_email && (
            <PartnerText>
              <a href={formatEmailUrl(newsItem.field_contact_email)}>
                {newsItem.field_contact_email}
              </a>
            </PartnerText>
          )}

          <PartnerText modifiers="partner-text--preamble">
            {newsItem?.field_preamble && <p>{newsItem?.field_preamble}</p>}
          </PartnerText>
          <PartnerText>
            <Raw html={newsItem?.body?.processed} />
          </PartnerText>
        </PartnerContainer>
        {isHidden && (
          <PartnerPopupWithOverlay
            title="Låst innehåll!"
            text="Din partnernivå har inte möjlighet att ta del av detta innehåll."
            button="Tillbaka"
            onButtonClick={() => router.canGoBack() && router.goBack()}
          />
        )}
      </PageWithHideableHeader>
      <zeus-data-provider id={`news-item-${id}`}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/news/${id}?include=field_image,field_access_term,field_access_partner`}
        ></zeus-data-source-jsonapi>
      </zeus-data-provider>
    </IonPage>
  );
};

export default NewsDetail;
