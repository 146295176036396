const defaultSettings = {
  BASENAME: "/",

  APP_ID: "96b35485-d04b-4579-9c20-71663755e851",
  BACKEND: "http://localhost:3000",
  ONESIGNAL_APP_ID: "58991296-7abc-4e84-afb3-7aa2f11a6ca4",
  CLIENT_ID: "c86bb510-d7be-45f2-a63b-ab824b86153b",
  CLIENT_SECRET: "31e2b711-fdfb-45cd-bf8f-5b33993fc1a9",
  MAIN_DOMAIN: "dev.partnerplattformen.se",
  INVITE_EMAIL:
    'Du har blivit inbjuden till Partnerplattformen!\r\n\r\nFör att logga in, öppna appen och klicka på "logga in" och sedan på länken "Återställ lösenord".\r\n\r\nLadda ner appen här:\r\n\r\niOS, App Store\r\nhttps://appstore.com/\r\n\r\nAndroid, Google Play Store\r\nhttps://googleplay.com/',
  REQUEST_HELP_TEXT:
    "Här kan du som partner skapa en förfrågan i nätverket. Förfrågan visas när administratör har godkänt innehållet.",
  START_HEADLINE: "Partner +",
  START_NEWS_TEXT:
    "Läs nyheter om våra partners och allt som händer i klubben.  Artiklar med tränare, spelare och våra matcher.",
  TERMS_CTCT_EMAIL: "demo@example.com",
  TERMS_CTCT_NAME: "Demo Person",
  TERMS_HEADING:
    "Din integritet är viktig för oss. Vi arbetar för att säkerställa en hög nivå av dataskydd som väntar om din personliga integritet vid användning av våra tjänster och webbplatser. Därför har vi utvecklat en integritetspolicy som beskriver hur vi samlar in, använder, delar, överför och lagrar dina personuppgifter.",
  TERMS_TEXT: "Läs mer om vår integritetspolicy",
  APP_NAME: "Partner +",
  DASHBOARD_TITLE: "Partner +",
  FOOTER_TEXT: "© Understandit/Gameday 2022",
  OFFER_HELP_TEXT:
    "Här kan du som partner skapa ett erbjudande i nätverket. Erbjudandet visas när administratör har godkänt innehållet.",
  START_ACTION_BTN: "Bli partner",
  START_EMAIL: "demo@example.com",
  START_INFO_TEXT:
    "För att logga in krävs en inbjudan till Partner +. Vill ditt företag vara en del av vårt partnernätverk? Kontakta oss nedan.",
  START_LOGIN_TEXT:
    "Ta del av gemenskapen, boka event, skapa erbjudanden och gör affärer i nätverket.",
  START_PRTNR_TEXT: "Se vilka företag som är anslutna till partnernätverket.",
  START_TERMS_BTN: "Integritetspolicy",
  TERMS_CTCT_PHONE: "",
  TERMS_TITLE: "Integritetspolicy",
  PROFILE_BG: "",
  START_NEWS_IMG: "",
  START_PRTNR_IMG: "",
  DEFAULT_IMAGE:
    "https://lingering-surf-3363.a.udev.se/sites/default/files/2023-03/default-image.jpg",
  FOOTER_IMAGE:
    "https://lingering-surf-3363.a.udev.se/sites/default/files/2023-03/partner-plus-logo_0.png",
  LOGO: "https://lingering-surf-3363.a.udev.se/sites/default/files/2023-03/partner-plus-logo.png",
  START_LOGIN_IMG:
    "https://lingering-surf-3363.a.udev.se/sites/default/files/2023-03/partner-plus-icon.png",

  HELP_TEXT_ACCESS_PARTNER:
    "Utvalda partners har behörighet att läsa innehållet medan övriga möts av en åtkomstbegränsning och kan inte läsa, men de kan se att innehållet finns där.",
  HELP_TEXT_ACCESS_TERM:
    "Utvalda partnergrupper har behörighet att läsa innehållet medan övriga möts av en åtkomstbegränsning och kan inte läsa, men de kan se att innehållet finns där.",
  HELP_TEXT_PARTNER:
    "Välj den partner som ska synas som skapare av innehållet.",
  NAVIGATION_PROMPT: "Vill du gå tillbaka utan att spara?",
};

let settings = {};

export async function loadSettings() {
  try {
    const data = JSON.parse(document.getElementById("root")?.dataset?.settings);
    if (data) {
      settings = {
        ...defaultSettings,
        ...data,
      };
    } else {
      throw new Error("Could not parse settings.");
    }
  } catch (e) {
    console.warn("No settings found, using defaults.");
    settings = { ...defaultSettings };
  }

  settings = {
    ...defaultSettings,
    ...settings,
  };
}

export function getSetting(name) {
  return settings?.[name];
}
