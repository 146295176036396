import { SplashScreen } from "@capacitor/splash-screen";
import { isPlatform, setupIonicReact } from "@ionic/react";
import OneSignal from "onesignal-cordova-plugin";

// Import and register components for zeus data source.
import "@understandit/dynamic-data";

// Import dayjs for init.
import dayjs from "dayjs";
import "dayjs/locale/sv";

import { loadSettings, getSetting } from "./settings";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

/* Theme css */
import "@understandit/partnerplattformen-storybook-30876108/theme.css";
import "@understandit/partnerplattformen-storybook-30876108/dist/index.css";

dayjs.locale("sv"); // use locale

loadSettings().then(() => {
  setupIonicReact();
  if (isPlatform("hybrid")) {
    if (getSetting("ONESIGNAL_APP_ID")) {
      OneSignal.setAppId(getSetting("ONESIGNAL_APP_ID"));
    }
    setTimeout(() => {
      SplashScreen.hide();
    }, 500);
  }
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
});
