import { IonPage, useIonRouter } from "@ionic/react";
import { useParams } from "react-router";
import {
  PageWithCollapsableHeader,
  PartnerButton,
  PartnerContainer,
  PartnerText,
  PartnerEventSummaryItem,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import { useEventProvider } from "../providers/event-provider";
import { useEventActions } from "../hooks";

function getSurveySummary(survey, values) {
  if (!survey || !values) {
    return [];
  }

  const summary = [];

  for (const component of survey.components) {
    if (values[component.name]) {
      summary.push({
        label: component.title,
        value: values[component.name],
      });
    }
  }

  return summary;
}

const EventSubmission = () => {
  const { id } = useParams();
  const { event, Provider } = useEventProvider(id);
  const [eventMemberships] = useDynamicDataProvider(`event-membership-${id}`);
  const eventMembership = eventMemberships?.[0];
  const [membership] = useDynamicDataProvider("partner-group-membership");
  const [profile] = useDynamicDataProvider("profile");
  const router = useIonRouter();
  const { cancelEvent } = useEventActions(id, () => {
    router.push(`/u/event/${id}`, "back");
  });

  const survey = event?.field_survey ? JSON.parse(event.field_survey) : null;
  const surveyResults = eventMembership?.field_survey_results
    ? JSON.parse(eventMembership.field_survey_results)
    : null;

  const doCancelEvent = () => {
    cancelEvent(eventMembership.id);
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Tack för din anmälan"
        mode="back"
        defaultHref={`/u/event/${id}`}
      >
        <PartnerContainer>
          <PartnerText>
            Här kommer en sammanfattning av dina uppgifter.
          </PartnerText>
          <PartnerText modifiers="partner-text--weight-large partner-text--margin-top">
            Dina uppgifter
          </PartnerText>
        </PartnerContainer>

        <PartnerContainer modifiers="partner-container--border-top">
          <PartnerEventSummaryItem
            name={membership?.field_name}
            items={[
              { label: "E-post", value: profile?.mail },
              { label: "Tel", value: membership?.field_telephone },
              ...getSurveySummary(survey, surveyResults?.values),
            ]}
          />
        </PartnerContainer>
        {surveyResults?.guests?.length > 0 && (
          <PartnerContainer modifiers="partner-container--border-top">
            <PartnerText modifiers="partner-text--weight-large partner-text--margin-top">
              Gäster
            </PartnerText>
          </PartnerContainer>
        )}
        {surveyResults?.guests?.map((guest, i) => (
          <PartnerContainer key={i} modifiers="partner-container--border-top">
            <PartnerEventSummaryItem
              name={guest.name}
              items={[
                { label: "E-post", value: guest.email },
                { label: "Tel", value: guest.telephone },
                ...getSurveySummary(survey, guest.values),
              ]}
            ></PartnerEventSummaryItem>
          </PartnerContainer>
        ))}

        <PartnerContainer modifiers="partner-container--border-top">
          <PartnerButton
            text="Avboka din anmälan"
            expand="block"
            modifiers="partner-button--inactive"
            onClick={doCancelEvent}
          />
          <PartnerButton
            text="Redigera anmälan"
            expand="block"
            icon="icons/edit-icon16.svg"
            modifiers="partner-button--secondary partner-button--margin-top"
            routerLink={`/u/event/${id}/submission/${eventMembership?.id}/edit`}
          />
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <Provider />

      {profile?.id && (
        <zeus-data-provider
          id={`event-membership-${id}`}
          listen-tags={`event-membership-${id}`}
        >
          <zeus-data-source-jsonapi
            url={`${getSetting(
              "BACKEND"
            )}/jsonapi/group_content/event-group_membership?filter[gid.id]=${id}&filter[entity_id.id]=${
              profile.id
            }`}
          ></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      )}
    </IonPage>
  );
};

export default EventSubmission;
