import { useState } from "react";
import {
  IonPage,
  useIonRouter,
  IonList,
  IonItem,
  IonButton,
  IonReorderGroup,
  IonReorder,
} from "@ionic/react";
import { useParams } from "react-router";
import {
  PartnerContainer,
  PartnerText,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerItem,
} from "@understandit/partnerplattformen-storybook-30876108";

import { makeid } from "../../helpers";
import { useActionSheet } from "../../hooks";
import { useAppState } from "../../app-context";

export const AdminCreateEventSurvey = () => {
  const { formState, setFormState } = useAppState();
  const openActionSheet = useActionSheet();
  const router = useIonRouter();

  const handleReorder = (ev) => {
    const newComponents = ev.detail.complete(formState.field_survey.components);
    setFormState((formState) => {
      const newState = {
        ...formState,
        field_survey: {
          ...formState.field_survey,
          components: newComponents,
        },
      };
      return newState;
    });
  };

  const doDelete = (name) => {
    const newComponents = formState.field_survey.components.filter(
      (i) => i.name !== name
    );
    setFormState((formState) => {
      const newState = {
        ...formState,
        field_survey: {
          ...formState.field_survey,
          components: newComponents,
        },
      };
      return newState;
    });
  };

  const doOpenSheet = async (name) => {
    const action = await openActionSheet({
      buttons: [
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Redigera", data: "edit" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });

    if (action === "edit") {
      router.push(`/u/admin/create/event/survey/edit/${name}`);
    }

    if (action === "delete") {
      doDelete(name);
    }
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Anmälningsformulär"
        defaultHref="/u/admin/create/event"
        mode="back"
      >
        <PartnerContainer>
          <PartnerText>
            Lägg till extra fält vid anmälan som t.ex. allergier eller
            motsvarande. Fält för namn och e-post kommer automatiskt.
          </PartnerText>
          <PartnerButton
            text="Lägg till textfält"
            icon="icons/plus16.svg"
            expand="block"
            modifiers="partner-button--margin-mega"
            routerLink="/u/admin/create/event/survey/add"
          />
          {formState.field_survey?.components?.length > 0 && (
            <IonList>
              <IonReorderGroup
                disabled={false}
                onIonItemReorder={handleReorder}
              >
                {formState.field_survey.components.map((component) => (
                  <IonItem
                    className="partner-item-reorder"
                    key={component.name}
                    lines="none"
                  >
                    <IonReorder slot="start" />
                    <div className="partner-item-reorder__container">
                      <div className="partner-item-reorder__container-start">
                        <div className="partner-item-reorder__label">
                          {component.title}
                        </div>
                      </div>
                      <div className="partner-item-reorder__container-end">
                        <IonButton
                          className="partner-item-reorder__edit"
                          onClick={() => doOpenSheet(component.name)}
                        >
                          <div className="partner-item-reorder__dot"></div>
                          <div className="partner-item-reorder__dot"></div>
                          <div className="partner-item-reorder__dot"></div>
                        </IonButton>
                      </div>
                    </div>
                  </IonItem>
                ))}
              </IonReorderGroup>
            </IonList>
          )}

          {formState.field_survey?.components?.length === 0 && (
            <PartnerText modifiers="partner-text--center partner-text--mini">
              Det finns inga formulär skapade
            </PartnerText>
          )}
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export const AdminCreateEventSurveyAdd = () => {
  const { setFormState } = useAppState();
  const router = useIonRouter();
  const [localState, setLocalState] = useState({
    title: "",
    type: "text",
    description: "",
    isRequired: false,
  });

  const doAddTextField = () => {
    setFormState((formState) => {
      const id = makeid(10);
      const newState = {
        ...formState,
        field_survey: {
          ...formState.field_survey,
          components: [
            ...formState.field_survey.components,
            { name: id, ...localState },
          ],
        },
      };
      return newState;
    });
    router.canGoBack()
      ? router.goBack()
      : router.push("/u/admin/create/event/survey");
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Skapa textfält"
        defaultHref="/u/admin/create/event/survey"
        mode="back"
      >
        <PartnerContainer>
          <PartnerText>
            Skriv i namnet/label för ditt fält. Hjälptexten visas under
            input-fältet vid anmälan.
          </PartnerText>
        </PartnerContainer>
        <PartnerContainer modifiers="partner-container--border-top">
          <PartnerInput
            label="Namn"
            value={localState.title}
            onChange={(e) =>
              setLocalState((current) => ({
                ...current,
                title: e.target.value,
              }))
            }
          />
          <PartnerInput
            label="Hjälptext"
            helpText="Hjälptext visas här"
            value={localState.description}
            onChange={(e) =>
              setLocalState((current) => ({
                ...current,
                description: e.target.value,
              }))
            }
          />
          <PartnerItem
            label="Obligatoriskt fält"
            toggleChecked={localState.isRequired}
            toggleChanged={(e) =>
              setLocalState((current) => ({
                ...current,
                isRequired: e.detail.checked,
              }))
            }
            toggle={true}
            modifiers="partner-item--no-padding"
            lines="none"
          />
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton text="Spara" expand="block" onClick={doAddTextField} />
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export const AdminCreateEventSurveyEdit = () => {
  const router = useIonRouter();
  const { id } = useParams();
  const { formState, setFormState } = useAppState();
  const idx = formState.field_survey.components.findIndex((c) => c.name === id);
  const initialState = formState.field_survey.components[idx];
  const [localState, setLocalState] = useState({ ...initialState });

  const doSaveTextField = () => {
    const newComponents = [...formState.field_survey.components];
    newComponents[idx] = localState;
    setFormState((formState) => {
      const newState = {
        ...formState,
        field_survey: {
          ...formState.field_survey,
          components: newComponents,
        },
      };
      return newState;
    });
    router.canGoBack()
      ? router.goBack()
      : router.push("/u/admin/create/event/survey");
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Skapa textfält"
        defaultHref="/u/admin/create/event/survey"
        mode="back"
      >
        <PartnerContainer>
          <PartnerText>
            Skriv i namnet/label för ditt fält. Hjälptexten visas under
            input-fältet vi anmälan.
          </PartnerText>
        </PartnerContainer>
        <PartnerContainer modifiers="partner-container--border-top">
          <PartnerInput
            label="Namn"
            value={localState.title}
            onChange={(e) =>
              setLocalState((current) => ({
                ...current,
                title: e.target.value,
              }))
            }
          />
          <PartnerInput
            label="Hjälptext"
            helpText="Hjälptext visas här"
            value={localState.description}
            onChange={(e) =>
              setLocalState((current) => ({
                ...current,
                description: e.target.value,
              }))
            }
          />
          <PartnerItem
            label="Obligatoriskt fält"
            toggleChecked={localState.isRequired}
            toggleChanged={(e) =>
              setLocalState((current) => ({
                ...current,
                isRequired: e.detail.checked,
              }))
            }
            toggle={true}
            modifiers="partner-item--no-padding"
            lines="none"
          />
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton
            text="Spara"
            expand="block"
            onClick={doSaveTextField}
          />
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};
