import { useEffect, useState } from "react";
import {
  IonLoading,
  IonPage,
  useIonRouter,
  IonAlert,
  IonLabel,
  IonItem,
  IonToggle,
  IonList,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import {
  PartnerContainer,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerSelect,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../../settings";
import { useCamera, useTaxonomy } from "../../hooks";
import { makeid, preventDefault } from "../../helpers";

export const AdminPartnerEdit = () => {
  const router = useIonRouter();
  const { id } = useParams();
  const providerId = `edit-partner-${id}`;
  const {
    data: partner,
    setData,
    isLoading,
    status,
  } = useZeusDataProvider(providerId);
  const partnerTypes = useTaxonomy("partner_type");
  const { getPhoto } = useCamera();
  const [image, setImage] = useState(null);
  const [hideInList, setHideInList] = useState(null);
  const [formState, setFormState] = useState({
    label: "",
    field_email: "",
    field_social_facebook: "",
    field_social_instagram: "",
    field_social_twitter: "",
    field_social_linkedin: "",
    field_description: "",
    field_partner_type: "",
    field_quota_offer: "",
    field_quota_request: "",
  });

  useEffect(() => {
    if (partner) {
      setFormState({
        label: partner.label,
        field_email: partner.field_email,
        field_social_facebook: partner.field_social_facebook,
        field_social_instagram: partner.field_social_instagram,
        field_social_twitter: partner.field_social_twitter,
        field_social_linkedin: partner.field_social_linkedin,
        field_description: partner.field_description,
        field_quota_offer: partner.field_quota_offer,
        field_quota_request: partner.field_quota_request,
        field_partner_type: partner.field_partner_type?.id,
      });
      setHideInList(partner.field_hide_in_list);
    }
  }, [partner]);

  const doSubmit = () => {
    const data = {
      ...formState,
      field_partner_type: {
        id: formState.field_partner_type,
      },
      field_hide_in_list: hideInList,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_logo",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData({ ...data, id });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={status === "updated"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.canGoBack() && router.goBack()}
        message="Profilen har uppdaterats."
      />
      <IonLoading isOpen={isLoading} />

      <PageWithCollapsableHeader
        title="Redigera partner"
        mode="back"
        defaultHref="/u/admin/network"
      >
        <PartnerContainer>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            modifiers="partner-media-with-button--company"
            img={image?.webPath ?? partner?.field_logo?.image_style_uri.medium}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <form onSubmit={preventDefault(doSubmit)}>
          <IonList>
            <PartnerSelect
              icon="icons/chevron-down16.svg"
              label="Partnertyp"
              placeholder="Välj partnertyp"
              options={partnerTypes.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              value={formState.field_partner_type}
              onChange={updateFormState("field_partner_type")}
            />
          </IonList>
          <PartnerContainer>
            <PartnerInput
              value={formState.label}
              onChange={updateFormState("label")}
              label="Företagsnamn"
            />
            <PartnerInput
              value={formState.field_email}
              onChange={updateFormState("field_email")}
              label="E-post"
            />

            <PartnerInput
              value={formState.field_quota_offer}
              onChange={updateFormState("field_quota_offer")}
              label="Max antal erbjudanden"
            />
            <PartnerInput
              value={formState.field_quota_request}
              onChange={updateFormState("field_quota_request")}
              label="Max antal förfrågningar"
            />
            <PartnerInput
              value={formState.field_social_facebook}
              onChange={updateFormState("field_social_facebook")}
              label="Facebook"
            />
            <PartnerInput
              value={formState.field_social_instagram}
              onChange={updateFormState("field_social_instagram")}
              label="Instagram"
            />
            <PartnerInput
              value={formState.field_social_twitter}
              onChange={updateFormState("field_social_twitter")}
              label="Twitter"
            />
            <PartnerInput
              value={formState.field_social_linkedin}
              onChange={updateFormState("field_social_linkedin")}
              label="Linkedin"
            />
            <PartnerInput
              value={formState.field_description}
              onChange={updateFormState("field_description")}
              label="Företagsbeskrivning"
              textarea={true}
            />
            <IonItem lines="none">
              <IonLabel>Dölj i listningen</IonLabel>
              <IonToggle
                checked={hideInList}
                onIonChange={(e) => setHideInList(e.detail.checked)}
              />
            </IonItem>
          </PartnerContainer>
        </form>
        <PartnerContainer>
          <PartnerButton
            text="Spara"
            expand="block"
            onClick={doSubmit}
          ></PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id={providerId}
        send-tags={`group-partner-${id},group-partner`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group/partner/${id}?include=field_logo,field_partner_type`}
          type="group--partner"
          attributes="label,field_email,field_social_facebook,field_social_instagram,field_social_twitter,field_social_linkedin,field_description,field_partner_type,field_quota_offer,field_quota_request,field_hide_in_list"
          relationships="field_partner_type:taxonomy_term--partner_type"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminPartnerEdit;
