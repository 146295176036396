import { useState, useEffect } from "react";
import { IonPage, IonList, isPlatform, useIonRouter } from "@ionic/react";
import {
  PartnerItem,
  PartnerText,
  PartnerContainer,
  PartnerButton,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import OneSignal from "onesignal-cordova-plugin";

const ToggleItem = ({ icon, onChange, checked, children, disabled }) => (
  <PartnerItem
    modifiers="partner-item--menu-white"
    label={children}
    icon={`icons/${icon}24.svg`}
    toggle={true}
    toggleChecked={checked}
    toggleChanged={onChange}
    toggleDisabled={disabled}
  />
);

export const RequestCreate = () => {
  const availableTags = [
    "disable_news",
    "disable_event",
    "disable_offer",
    "disable_request",
  ];

  const { data, setData } = useZeusDataProvider("push-settings", {
    disable_news: false,
    disable_event: false,
    disable_offer: false,
    disable_request: false,
    disabled: false,
  });
  const [settings, setSettings] = useState({
    disable_news: false,
    disable_event: false,
    disable_offer: false,
    disable_request: false,
    disabled: false,
  });
  const router = useIonRouter();

  useEffect(() => setSettings(data), [data]);

  const save = () => {
    setData(settings);
    if (isPlatform("hybrid")) {
      OneSignal.disablePush(settings.disabled);
      OneSignal.deleteTags(availableTags);
      OneSignal.sendTags(
        availableTags.reduce((acc, item) => {
          if (settings[item]) {
            acc[item] = "on";
          }
          return acc;
        }, {})
      );
    }
    router.canGoBack() && router.goBack();
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Push"
        mode="back"
        defaultHref="/u/profile"
      >
        <PartnerContainer>
          <PartnerText modifiers="partner-text--small partner-text--margin-bottom partner-text--center">
            Bestäm vilka typer av notifikationer som skickas.
          </PartnerText>
          <IonList className="partner-profile-menu">
            <ToggleItem
              icon="close"
              checked={settings.disabled}
              onChange={(e) =>
                setSettings((old) => ({ ...old, disabled: e.detail.checked }))
              }
            >
              Stäng av alla notiser
            </ToggleItem>
          </IonList>
        </PartnerContainer>

        {!settings.disabled && (
          <PartnerContainer>
            <IonList className="partner-profile-menu">
              <ToggleItem
                disabled={settings.disabled}
                icon="news"
                checked={!settings.disable_news}
                onChange={(e) =>
                  setSettings((old) => ({
                    ...old,
                    disable_news: !e.detail.checked,
                  }))
                }
              >
                Nyheter
              </ToggleItem>
              <ToggleItem
                disabled={settings.disabled}
                icon="conversation"
                checked={!settings.disable_event}
                onChange={(e) =>
                  setSettings((old) => ({
                    ...old,
                    disable_event: !e.detail.checked,
                  }))
                }
              >
                Event
              </ToggleItem>
              <ToggleItem
                disabled={settings.disabled}
                icon="offer"
                checked={!settings.disable_offer}
                onChange={(e) =>
                  setSettings((old) => ({
                    ...old,
                    disable_offer: !e.detail.checked,
                  }))
                }
              >
                Erbjudanden
              </ToggleItem>
              <ToggleItem
                disabled={settings.disabled}
                icon="question"
                checked={!settings.disable_request}
                onChange={(e) =>
                  setSettings((old) => ({
                    ...old,
                    disable_request: !e.detail.checked,
                  }))
                }
              >
                Förfrågningar
              </ToggleItem>
            </IonList>
          </PartnerContainer>
        )}

        <PartnerContainer>
          <PartnerButton text="Spara" expand="block" onClick={save} />
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id="push-settings">
        <zeus-data-source-local></zeus-data-source-local>
      </zeus-data-provider>
    </IonPage>
  );
};

export default RequestCreate;
