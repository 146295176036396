import { IonPage } from "@ionic/react";
import {
  PartnerContainer,
  PartnerText,
  PartnerBadgeButton,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";

import Raw from "../components/Raw";

const TermsInfo = () => (
  <IonPage>
    <PageWithCollapsableHeader
      title={getSetting("TERMS_TITLE")}
      mode="back"
      defaultHref="/start"
    >
      <PartnerContainer>
        <PartnerText modifiers="partner-text--preamble">
          <p>{getSetting("TERMS_HEADING")}</p>
        </PartnerText>
        <PartnerText>
          <Raw html={getSetting("TERMS_TEXT")} />
        </PartnerText>
      </PartnerContainer>
      <PartnerContainer>
        {getSetting("TERMS_CTCT_PHONE") && (
          <PartnerBadgeButton
            icon="icons/phone24.svg"
            href={`tel:${getSetting("TERMS_CTCT_PHONE")}`}
            text={getSetting("TERMS_CTCT_PHONE")}
          />
        )}
        {getSetting("TERMS_CTCT_EMAIL") && (
          <PartnerBadgeButton
            icon="icons/mail24.svg"
            href={`mailto:${getSetting("TERMS_CTCT_EMAIL")}`}
            text={getSetting("TERMS_CTCT_EMAIL")}
          />
        )}
      </PartnerContainer>
    </PageWithCollapsableHeader>
  </IonPage>
);

export default TermsInfo;
