import { IonPage, useIonRouter } from "@ionic/react";
import {
  PartnerContainer,
  PartnerTextWithIcon,
  PartnerText,
  PageWithHideableHeader,
  PartnerPopupWithOverlay,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { getSetting } from "../settings";
import { formatUrl, formatEmailUrl, isVisible } from "../helpers";

import Raw from "../components/Raw";
import ArticleHeading from "../components/ArticleHeading";
import InAppLink from "../components/InAppLink";

export const NewsDetail = () => {
  const { id } = useParams();
  const router = useIonRouter();
  const providerId = `request-item-${id}`;
  const [membership] = useDynamicDataProvider("partner-group-membership");
  const [request] = useDynamicDataProvider(providerId, null);
  const isHidden = !isVisible(request, membership);
  return (
    <IonPage>
      <PageWithHideableHeader
        title={request?.title}
        defaultHref="/u/request"
        headerImage={
          request?.field_image?.image_style_uri.wide ??
          getSetting("DEFAULT_IMAGE")
        }
      >
        <PartnerContainer modifiers="partner-container--rounded-top">
          <PartnerTextWithIcon
            icon="icons/clock16.svg"
            text={dayjs(request?.created).format("D MMM, YYYY")}
          />
          <ArticleHeading>{request?.title}</ArticleHeading>
          {request?.field_link && (
            <PartnerText>
              <InAppLink href={formatUrl(request.field_link)}>
                {request.field_link}
              </InAppLink>
            </PartnerText>
          )}
          {request?.field_contact_email && (
            <PartnerText>
              <a href={formatEmailUrl(request.field_contact_email)}>
                {request.field_contact_email}
              </a>
            </PartnerText>
          )}

          {request?.field_preamble && (
            <PartnerText modifiers="partner-text--preamble">
              <p>{request?.field_preamble}</p>
            </PartnerText>
          )}
          <PartnerText>
            <Raw html={request?.body?.processed} />
          </PartnerText>
        </PartnerContainer>
        {isHidden && (
          <PartnerPopupWithOverlay
            title="Låst innehåll!"
            text="Din partnernivå har inte möjlighet att ta del av detta innehåll."
            button="Tillbaka"
            onButtonClick={() => router.canGoBack() && router.goBack()}
          />
        )}
      </PageWithHideableHeader>
      <zeus-data-provider id={providerId}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/request/${id}?include=field_image,field_access_term,field_access_partner`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default NewsDetail;
