import { useState } from "react";
import { IonPage, IonLoading, IonAlert, useIonRouter } from "@ionic/react";
import { useParams } from "react-router-dom";
import {
  PartnerText,
  PartnerContainer,
  PartnerButton,
  PartnerInput,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";

const ProfilePassword = () => {
  const { email: currentEmail } = useParams();
  const [formState, setFormState] = useState({ code: "", newPassword: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const router = useIonRouter();

  const doSubmitEmail = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `${getSetting("BACKEND")}/user/change-password-code?_format=json`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "omit",
          body: JSON.stringify({ mail: currentEmail }),
        }
      );

      if (response.status !== 200) {
        throw new Error("request error");
      }

      setMessage(`Ett e-post har skickats till ${currentEmail}.`);
    } catch (error) {
      setMessage(
        "Ett fel uppstod i kommunikationen med servern. Var god försök senare."
      );
    }

    setIsLoading(false);
  };

  const doSubmitPassword = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `${getSetting("BACKEND")}/user/change-password?_format=json`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "omit",
          body: JSON.stringify({
            mail: currentEmail,
            code: formState.code,
            password: formState.newPassword,
          }),
        }
      );

      if (response.status === 200) {
        document.dispatchEvent(
          new CustomEvent("zeusDataData:auth", {
            detail: { action: "invalidate" },
          })
        );
        setShowSuccess(true);
      } else {
        setMessage(
          "Kunde inte uppdatera ditt lösenord. Kontrollera att e-postadress och engångskod stämmer."
        );
      }
    } catch (error) {
      setMessage(
        "Ett fel uppstod i kommunikationen med servervn. Var god försök senare."
      );
    }

    setIsLoading(false);
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={message !== ""}
        message={message}
        buttons={["OK"]}
        onDidDismiss={() => setMessage("")}
        header={getSetting("APP_NAME")}
      />
      <IonAlert
        isOpen={showSuccess}
        message="Ditt lösenord har uppdaterats."
        buttons={["OK"]}
        onDidDismiss={() => {
          setShowSuccess(false);
          router.canGoBack() && router.goBack();
        }}
        header={getSetting("APP_NAME")}
      />
      <PageWithCollapsableHeader
        title="Uppdatera lösenord"
        mode="back"
        defaultHref="/login"
      >
        <PartnerContainer>
          <PartnerText>
            <p>
              Klicka på knappen nedan för så skickar vi en engångskod till din
              e-post som du kan använda för att återställa ditt lösenord.
            </p>
            <p>
              Glöm inte att kolla i spam-mappen om du inte hittar koden i din
              inkorg. Engångskoden är giltig i 15 minuter.
            </p>
          </PartnerText>
        </PartnerContainer>

        <PartnerContainer>
          <PartnerButton
            text="Begär kod"
            expand={true}
            onClick={doSubmitEmail}
          ></PartnerButton>
        </PartnerContainer>

        <PartnerContainer modifiers="partner-container--login-form">
          <PartnerInput
            value={formState?.code}
            onChange={(event) =>
              setFormState({ ...formState, code: event.target.value })
            }
            placeholder="XXXX-XXXX"
            label="Kod från e-post"
          />
          <PartnerInput
            value={formState?.newPassword}
            onChange={(event) =>
              setFormState({ ...formState, newPassword: event.target.value })
            }
            label="Nytt lösenord"
            type="password"
          />
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton
            text="Uppdatera lösenord"
            expand={true}
            onClick={doSubmitPassword}
          ></PartnerButton>
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default ProfilePassword;
