import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IonLoading, IonPage, useIonRouter } from "@ionic/react";

import {
  PageWithCollapsableHeader,
  PartnerButton,
  PartnerContainer,
  PartnerInput,
} from "@understandit/partnerplattformen-storybook-30876108";

import { useContentProvider } from "../../providers/content-provider";
import { preventDefault } from "../../helpers";
import { usePushCreateProvider } from "../../providers/push-create-provider";
import { useAlert } from "../../hooks";
import { getSetting } from "../../settings";

import DynamicTextArea from "../../components/DynamicTextarea";

const AdminCreatePush = () => {
  const { type, id } = useParams();
  const [formState, setFormState] = useState({
    field_heading: "",
    field_message: "",
  });
  const { Provider, entity, isLoading } = useContentProvider(type, id);
  const {
    Provider: PushCreateProvider,
    setData,
    isLoading: createIsLoading,
  } = usePushCreateProvider(type);
  const router = useIonRouter();
  const showAlert = useAlert();

  useEffect(() => {
    if (entity) {
      setFormState({
        field_heading: entity.title ?? entity.label ?? "",
        field_message: entity.field_preamble,
      });
    }
  }, [entity]);

  const doSubmit = () => {
    const data = {
      ...formState,
      field_app: { id: getSetting("APP_ID") },
    };

    if (type === "event") {
      data.field_related_group = { id };
    } else {
      data.field_related_content = { id };
    }

    setData(data);
  };

  const onCreated = async () => {
    await showAlert("Pushnotis har skickats.");
    router.canGoBack()
      ? router.goBack()
      : router.push("/u/admin/content", "back");
  };

  return (
    <IonPage>
      <Provider />
      <PushCreateProvider onCreated={onCreated} />
      <IonLoading isOpen={isLoading || createIsLoading} />
      <PageWithCollapsableHeader
        title="Skapa pushnotis"
        mode="back"
        defaultHref="/u/admin/content"
      >
        <PartnerContainer>
          <form onSubmit={preventDefault(doSubmit)}>
            <PartnerInput
              value={formState.field_heading}
              onChange={(e) =>
                setFormState({ ...formState, field_heading: e.target.value })
              }
              label="Titel"
            />
            <DynamicTextArea
              value={formState.field_message}
              onChange={(e) =>
                setFormState({ ...formState, field_message: e.target.value })
              }
              label="Meddelande"
            />
            <PartnerButton
              text="Skapa push"
              icon="icons/plus16.svg"
              expand="block"
              modifiers="partner-button--margin-bottom"
              onClick={doSubmit}
            />
          </form>
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default AdminCreatePush;
