import OneSignal from "onesignal-cordova-plugin";
import { SavePassword } from "capacitor-ios-autofill-save-password";
import { useEffect } from "react";
import { IonPage, useIonRouter, isPlatform } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerFooter,
  PartnerHeader,
  PartnerCard,
  PartnerHeadingWithButton,
  PageWithCollapsableHeader,
  PartnerSkeletonCard,
} from "@understandit/partnerplattformen-storybook-30876108";

import { formatDate, formatDateRange, stripTags } from "../helpers";
import { getSetting } from "../settings";
import { getGlobal } from "../globals";

import SlideSection from "../components/SlideSection";

const Dashboard = () => {
  const [myPartner] = useDynamicDataProvider("my-partner", []);
  const [newsList, , , newsIsLoading] = useDynamicDataProvider("news-list", []);
  const [eventList, , , eventIsLoading] = useDynamicDataProvider(
    "event-list",
    []
  );
  const [offersList, , , offersIsLoading] = useDynamicDataProvider(
    "offers-list",
    []
  );
  const [requestList, , , requestsIsLoading] = useDynamicDataProvider(
    "request-list",
    []
  );
  const [membership, , , membershipIsLoading] = useDynamicDataProvider(
    "partner-group-membership",
    null
  );

  const router = useIonRouter();

  useEffect(() => {
    if (isPlatform("ios") && isPlatform("hybrid")) {
      const login = getGlobal("loginSuccess");
      if (login) {
        console.log("Promting to save password.");
        SavePassword.promptDialog(login);
      }
    }
    if (isPlatform("hybrid")) {
      OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        console.log("User accepted notifications: " + accepted);
      });
    }
  }, []);

  return (
    <IonPage>
      <PageWithCollapsableHeader title={getSetting("DASHBOARD_TITLE")}>
        <PartnerHeader
          isLoading={membership === null || membershipIsLoading}
          onClick={() => router.push(`/u/profile`, "root")}
          headerBackground={getSetting("PROFILE_BG")}
          media={membership?.field_avatar?.image_style_uri.medium}
          logo={myPartner?.field_logo?.image_style_uri.medium}
          name={membership?.field_name ?? membership?.label}
          company={
            <>
              {myPartner?.label && <span>{myPartner.label}</span>}
              {membership?.field_job_title && (
                <span>{membership.field_job_title}</span>
              )}
            </>
          }
        />

        <SlideSection
          heading="Senaste nytt"
          buttonText="Fler nyheter"
          onClick={() => router.push(`/u/news`, "root")}
          badge={newsList.length !== 0 ? newsList.length : null}
          slidesPerView={1.7}
        >
          {newsIsLoading && newsList.length === 0
            ? [
                <PartnerSkeletonCard
                  key="1"
                  modifiers="partner-card--margin"
                />,
                <PartnerSkeletonCard key="2" />,
              ]
            : newsList.slice(0, 4).map((item, i) => (
                <PartnerCard
                  key={item.id}
                  onClick={() => router.push(`/u/news/${item.id}`)}
                  modifiers={
                    newsList.length > 1 && i !== newsList.length - 1
                      ? "partner-card--margin"
                      : null
                  }
                  title={item.title}
                  date={formatDate(item.created)}
                  imageSrc={
                    item.field_image?.image_style_uri.large ??
                    getSetting("DEFAULT_IMAGE")
                  }
                >
                  {stripTags(item.field_preamble)}
                </PartnerCard>
              ))}
        </SlideSection>

        {(eventIsLoading || eventList.length > 0) && (
          <PartnerContainer>
            <PartnerHeadingWithButton
              heading="Kommande event"
              buttonText="Se alla"
              badge={eventList.length !== 0 ? eventList.length : null}
              onClick={() => router.push(`/u/event`, "root")}
            />
            {eventList.length === 0 && eventIsLoading && (
              <PartnerSkeletonCard size="medium" />
            )}
            {eventList.slice(0, 4).map((item) => (
              <PartnerCard
                size="medium"
                onClick={() => router.push(`/u/event/${item.id}`)}
                key={item.id}
                title={item.label}
                date={formatDateRange(
                  item.field_date?.value,
                  item.field_date?.end_value
                )}
                imageSrc={
                  item.field_image?.image_style_uri.large ??
                  getSetting("DEFAULT_IMAGE")
                }
              >
                {stripTags(item.field_preamble)}
              </PartnerCard>
            ))}
          </PartnerContainer>
        )}

        <SlideSection
          heading="Nya erbjudanden"
          buttonText="Se alla"
          onClick={() => router.push(`/u/offer`, "root")}
          badge={offersList.length !== 0 ? offersList.length : null}
        >
          {offersIsLoading && offersList.length === 0
            ? [
                <PartnerSkeletonCard
                  key="1"
                  modifiers="partner-card--margin"
                  size="small"
                />,
                <PartnerSkeletonCard key="2" size="small" />,
              ]
            : offersList.slice(0, 4).map((item, i) => (
                <PartnerCard
                  key={item.id}
                  onClick={() => router.push(`/u/offer/${item.id}`)}
                  modifiers={`line-clamp-2 ${
                    offersList.length > 1 && i !== offersList.length - 1
                      ? "partner-card--margin"
                      : null
                  }`}
                  size="small"
                  title={item.title}
                  date={formatDate(item.created)}
                  imageSrc={
                    item.field_image?.image_style_uri.large ??
                    getSetting("DEFAULT_IMAGE")
                  }
                  badgeSrc={
                    item.entitygroupfield[0]?.gid?.field_logo?.image_style_uri
                      .large
                  }
                >
                  {stripTags(item.field_preamble)}
                </PartnerCard>
              ))}
        </SlideSection>

        <SlideSection
          heading="Förfrågningar"
          buttonText="Se alla"
          onClick={() => router.push(`/u/request`, "root")}
          badge={requestList.length !== 0 ? requestList.length : null}
        >
          {requestsIsLoading && requestList.length === 0
            ? [
                <PartnerSkeletonCard
                  key="1"
                  modifiers="partner-card--margin"
                  size="small"
                />,
                <PartnerSkeletonCard key="2" size="small" />,
              ]
            : requestList.slice(0, 4).map((item, i) => (
                <PartnerCard
                  key={item.id}
                  size="small"
                  modifiers={`line-clamp-2 ${
                    requestList.length > 1 && i !== requestList.length - 1
                      ? "partner-card--margin"
                      : ""
                  }`}
                  title={item.title}
                  date={formatDate(item.created)}
                  imageSrc={
                    item.field_image?.image_style_uri.large ??
                    getSetting("DEFAULT_IMAGE")
                  }
                  onClick={() => router.push(`/u/request/${item.id}`)}
                  badgeSrc={
                    item.entitygroupfield[0]?.gid?.field_logo?.image_style_uri
                      .large
                  }
                >
                  {stripTags(item.field_preamble)}
                </PartnerCard>
              ))}
        </SlideSection>
        <PartnerFooter
          img={getSetting("FOOTER_IMAGE")}
          text={getSetting("FOOTER_TEXT")}
        />
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default Dashboard;
