import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";
import { classList } from "../helpers";

export const PartnerSlides = ({ modifiers, slidesPerView = 1.1, children }) => {
  return (
    <div className={classList("partner-slides", modifiers)}>
      <Swiper
        modules={[Pagination]}
        pagination={true}
        slidesPerView={slidesPerView}
      >
        {children &&
          children.map((slide, i) => (
            <SwiperSlide key={i}>{slide}</SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default PartnerSlides;
