import dayjs from "dayjs";

const DateRange = ({ from, to }) => {
  if (from && to) {
    if (dayjs(from).isSame(to, "year")) {
      return (
        <>
          <time>{dayjs(from).format("D MMMM HH:mm")}</time> -{" "}
          <time>{dayjs(to).format("D MMMM HH:mm, YYYY")}</time>
        </>
      );
    }
    return (
      <>
        <time>{dayjs(from).format("D MMMM HH:mm, YYYY")}</time> -{" "}
        <time>{dayjs(to).format("D MMMM HH:mm, YYYY")}</time>
      </>
    );
  }

  if (from) {
    return <time>{dayjs(from).format("D MMMM HH:mm, YYYY")}</time>;
  }

  return null;
};

export default DateRange;
