import { IonList, IonPage } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerHeading,
  PartnerItemPerson,
  PartnerEventCard,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { useParams } from "react-router-dom";
import { formatDateRange } from "../helpers";
import { getSetting } from "../settings";

export const EventParticipants = () => {
  const { id } = useParams();
  const providerId = `event-item-particip-${id}`;
  const [event] = useDynamicDataProvider(providerId, null);
  const [eventMembershipList] = useDynamicDataProvider(
    `event-particip-membership-list-${id}`
  );

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Deltagarlista"
        defaultHref={`/u/event/${id}`}
        mode="back"
      >
        <PartnerContainer>
          <PartnerHeading
            text="Event"
            modifiers="partner-heading--start partner-heading--margin-bottom partner-heading--margin-top-small"
          ></PartnerHeading>
          <IonList className="app-list">
            <PartnerEventCard
              size="small"
              title={event?.label}
              location={event?.field_location}
              date={formatDateRange(
                event?.field_date?.value,
                event?.field_date?.end_value
              )}
              imageSrc={
                event?.field_image?.image_style_uri.large ??
                getSetting("DEFAULT_IMAGE")
              }
            />
          </IonList>
          <PartnerHeading
            text="Deltagare"
            modifiers="partner-heading--start partner-heading--margin-bottom partner-heading--margin-top-large"
          ></PartnerHeading>
          <IonList className="app-list">
            {eventMembershipList?.map((person) => (
              <PartnerItemPerson
                routerLink={`/u/network/profile/${person.field_partner_membership?.id}`}
                key={person.id}
                name={
                  person.field_partner_membership?.field_name ??
                  person.entity_id?.display_name
                }
                img={person.entity_id?.field_avatar}
                title={
                  <>
                    {person.field_partner_membership?.gid.label && (
                      <span>{person.field_partner_membership.gid.label}</span>
                    )}
                    {person.field_partner_membership?.field_job_title && (
                      <span>
                        {person.field_partner_membership.field_job_title}
                      </span>
                    )}
                  </>
                }
              />
            ))}
          </IonList>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id={providerId} listen-tags="event">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group/event/${id}?include=field_image`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider
        id={`event-particip-membership-list-${id}`}
        listen-tags="event"
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/event-group_membership?filter[gid.id]=${id}&sort=-created&include=field_partner_membership.gid,entity_id`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default EventParticipants;
