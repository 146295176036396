import { useMemo, useRef } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function usePartnerMembersProvider(partnerId) {
  const providerId = `partner-members-provider-${partnerId}`;

  const { data, isLoading } = useZeusDataProvider(providerId, []);
  const ref = useRef();

  const Provider = useMemo(() => {
    const Component = () => {
      const params = {
        "filter[gid.id]": partnerId,
        include: "field_avatar,entity_id,group_roles",
      };

      const query = new URLSearchParams(params);
      const url = `${getSetting(
        "BACKEND"
      )}/jsonapi/group_content/partner-group_membership?${query}`;

      return (
        <zeus-data-provider
          id={providerId}
          listen-tags={`partner-members-${partnerId}`}
        >
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [providerId, partnerId]);

  return {
    ref,
    providerId,
    Provider,
    members: data,
    isLoading,
  };
}
