import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import { IonReactHashRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

import { useConfirmDangerousAction } from "./hooks";
import { getSetting } from "./settings";
import { routes } from "./routes";
import {
  AuthenticatedRoute,
  AuthenticationProvider,
  useAuthentication,
} from "./auth-context";
import AuthMenu from "./Menu";
import NotificationReciever from "./NotificationReciever";
import { AppStateProvider } from "./app-context";
import { useCallback } from "react";

const AnonNewsProvider = () => {
  const params = new URLSearchParams({
    "filter[status]": "1",
    include: "field_image",
    sort: "-created",
    "filter[access-filter][condition][path]": "field_access_partner.id",
    "filter[access-filter][condition][operator]": "IS NULL",
    "filter[access-filter2][condition][path]": "field_access_term.id",
    "filter[access-filter2][condition][operator]": "IS NULL",
  });
  const url = `${getSetting("BACKEND")}/jsonapi/node/news?${params}`;
  return (
    <zeus-data-provider id="anon-news-list" listen-tags="node-news,news">
      <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
    </zeus-data-provider>
  );
};

const EventList = () => {
  const params = new URLSearchParams({
    "filter[status]": "1",
    include: "field_image,entitygroupfield.gid",
    sort: "field_date.value",
  });
  const url = `${getSetting("BACKEND")}/jsonapi/group/event?${params}`;
  return (
    <zeus-data-provider id="event-list" listen-tags="group-event,event">
      <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
      <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
    </zeus-data-provider>
  );
};

const AuthDataSources = () => {
  const { isAuthenticated } = useAuthentication();
  const [userId] = useDynamicDataProvider("user-id", "");
  const [membership] = useDynamicDataProvider("my-partner");

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <zeus-data-provider id="user-id">
        <zeus-data-source-json
          url={`${getSetting("BACKEND")}/jsonapi`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        <zeus-data-processor-jmespath filter="meta.links.me.meta.id"></zeus-data-processor-jmespath>
      </zeus-data-provider>

      {userId && (
        <>
          <zeus-data-provider id="profile">
            <zeus-data-source-jsonapi
              url={`${getSetting(
                "BACKEND"
              )}/jsonapi/user/user/${userId}?include=field_avatar,entitygroupfield,roles`}
              type="user--user"
              attributes="field_name,field_city,field_boat_name,field_boat_model,field_motor_brand,field_motor_serial_number"
            ></zeus-data-source-jsonapi>
            <zeus-data-middleware-oauth
              token-provider="auth"
              invalidate-token-on="patch"
            ></zeus-data-middleware-oauth>
          </zeus-data-provider>

          <zeus-data-provider
            id="partner-group-membership"
            listen-tags="membership"
          >
            <zeus-data-source-jsonapi
              url={`${getSetting(
                "BACKEND"
              )}/jsonapi/group_content/partner-group_membership?include=group_roles,gid.field_logo,gid.field_partner_type,field_avatar&filter[entity_id.id]=${userId}`}
            ></zeus-data-source-jsonapi>
            <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
            <zeus-data-processor-jmespath filter="[0]"></zeus-data-processor-jmespath>
          </zeus-data-provider>

          <zeus-data-provider id="my-partner">
            <zeus-data-source-pipe provider="partner-group-membership"></zeus-data-source-pipe>
            <zeus-data-processor-jmespath filter="gid"></zeus-data-processor-jmespath>
          </zeus-data-provider>
        </>
      )}

      <zeus-data-provider
        id="taxonomy-partner_type"
        listen-tags="taxonomy_term-partner_type"
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/taxonomy_term/partner_type?sort=weight`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider
        id="taxonomy-industry"
        listen-tags="taxonomy_term-industry"
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/taxonomy_term/industry?sort=weight`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="news-list" listen-tags="node-news,news">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/news?filter[status]=1&include=field_image&sort=-created`}
        ></zeus-data-source-jsonapi>
      </zeus-data-provider>

      <zeus-data-provider id="offers-list" listen-tags="node-offer,offer">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/offer?filter[status]=1&include=field_image,entitygroupfield.gid.field_logo,field_industry&sort=-created`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      <zeus-data-provider id="request-list" listen-tags="node-request,request">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/request?filter[status]=1&include=field_image,entitygroupfield.gid.field_logo,field_industry&sort=-created`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>

      {membership && <EventList membership={membership} />}
    </>
  );
};

const App = () => {
  const confirm = useConfirmDangerousAction();

  const test = useCallback(
    (message, cb) => {
      confirm(message, "OK").then(cb);
    },
    [confirm]
  );

  return (
    <AppStateProvider>
      <IonApp>
        <AuthenticationProvider>
          <IonReactHashRouter getUserConfirmation={test}>
            <NotificationReciever />
            <IonSplitPane contentId="main">
              <AuthMenu />
              <IonRouterOutlet id="main">
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact ?? true}
                  >
                    <AuthenticatedRoute mode={route.auth}>
                      <route.component {...route.params} />
                    </AuthenticatedRoute>
                  </Route>
                ))}
                <Route path="/" exact>
                  <Redirect to="/start" />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactHashRouter>
          <AuthDataSources />
        </AuthenticationProvider>

        <zeus-data-provider id="partner-list" listen-tags="group-partner">
          <zeus-data-source-jsonapi
            auto-pager
            url={`${getSetting(
              "BACKEND"
            )}/jsonapi/group_content/app-subgroup-partner?filter[gid.id]=${getSetting(
              "APP_ID"
            )}&include=entity_id.field_logo,entity_id.field_partner_type`}
          ></zeus-data-source-jsonapi>
          <zeus-data-processor-jmespath filter="[*].entity_id"></zeus-data-processor-jmespath>
        </zeus-data-provider>

        <AnonNewsProvider />
      </IonApp>
    </AppStateProvider>
  );
};

export default App;
