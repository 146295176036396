import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IonPage, IonLoading, IonSpinner, IonButton } from "@ionic/react";
import {
  PartnerContainer,
  PartnerText,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { setGlobal } from "../globals";
import { useAuthentication } from "../auth-context";
import { useAlert } from "../hooks";

const Login = ({ defaultHref }) => {
  const emailField = useRef(null);
  const passwordField = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuthentication();
  const presentAlert = useAlert();

  const doLogin = async (event) => {
    event?.preventDefault();
    setIsLoading(true);
    const username = emailField.current.value;
    const password = passwordField.current.value;
    try {
      await login(username, password);
      setGlobal("loginSuccess", { username, password });
    } catch (error) {
      setIsLoading(false);
      presentAlert(
        error === "invalid_credentials"
          ? "Fel användarnamn eller lösenord."
          : "Kunde inte kontakta servern. Var god försök igen senare."
      );
    }
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      {/* Hack to fool the bundler to include ion-spinner element */}
      <IonSpinner className="hidden" />
      <PageWithCollapsableHeader
        title="Logga in"
        defaultHref={defaultHref}
        mode="back"
      >
        <form onSubmit={(event) => doLogin(event)} action="/">
          <PartnerContainer>
            <div className="partner-native-input">
              <label>E-post</label>
              <input
                ref={emailField}
                type="email"
                autoComplete="username"
                name="username"
              />
            </div>
            <div className="partner-native-input">
              <label>Lösenord</label>
              <input
                ref={passwordField}
                type="password"
                autoComplete="current-password"
                name="password"
              />
            </div>
            <PartnerText modifiers="partner-text--password">
              <Link to="login/password">Återställ lösenord</Link>
            </PartnerText>
          </PartnerContainer>
          <PartnerContainer>
            <button
              type="submit"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                visibility: "hidden",
              }}
            >
              Submit
            </button>
            <IonButton className="partner-button" type="submit" expand="block">
              <div className="partner-button__text">Logga in</div>
            </IonButton>
          </PartnerContainer>
        </form>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default Login;
