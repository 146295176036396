import {
  PartnerContainer,
  PartnerHeadingWithButton,
} from "@understandit/partnerplattformen-storybook-30876108";

import PartnerSlides from "../components/PartnerSlides";

export const SlideSection = ({
  children,
  heading,
  buttonText,
  badge,
  slidesPerView,
  onClick,
}) => {
  if (!children || children.length === 0) {
    return null;
  }

  return (
    <PartnerContainer>
      <PartnerHeadingWithButton
        heading={heading}
        buttonText={buttonText}
        badge={badge}
        onClick={onClick}
      />
      {children.length === 1 && children}
      {children.length > 1 && (
        <PartnerSlides slidesPerView={slidesPerView}>{children}</PartnerSlides>
      )}
    </PartnerContainer>
  );
};

export default SlideSection;
