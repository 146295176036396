import { useMemo, useRef } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

export function useContentProvider(bundle, id) {
  const entityType = bundle === "event" ? "group" : "node";
  const providerId = `content-provider-${bundle}-${id}`;

  const { data, isLoading } = useZeusDataProvider(providerId, null);
  const ref = useRef();

  const Provider = useMemo(() => {
    const Component = () => {
      const params = {};
      const query = new URLSearchParams(params);
      const url = `${getSetting(
        "BACKEND"
      )}/jsonapi/${entityType}/${bundle}/${id}?${query}`;

      return (
        <zeus-data-provider
          ref={ref}
          id={providerId}
          listen-tags={`${entityType}-${bundle}-${id}`}
        >
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [entityType, bundle, id, providerId]);

  return {
    ref,
    providerId,
    Provider,
    entity: data,
    isLoading,
  };
}
