import OneSignal from "onesignal-cordova-plugin";
import { useEffect } from "react";
import { useIonRouter, isPlatform } from "@ionic/react";

const NotificationReciever = () => {
  // Members in router object are stable and will not refire Effect.
  const { push } = useIonRouter();
  useEffect(() => {
    if (isPlatform("hybrid")) {
      OneSignal.setNotificationOpenedHandler((jsonData) => {
        if (
          jsonData?.notification?.additionalData?.link &&
          jsonData.notification.additionalData.link.length > 1
        ) {
          push(`/u/${jsonData.notification.additionalData.link}`);
        }
      });
    }
  }, [push]);

  return null;
};

export default NotificationReciever;
