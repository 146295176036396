import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  IonPage,
  IonLoading,
  IonAlert,
  IonList,
  useIonRouter,
} from "@ionic/react";
import {
  useDynamicDataProvider,
  useZeusDataProvider,
} from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerText,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerSelect,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../../settings";
import { makeid } from "../../helpers";
import { useCamera } from "../../hooks";

import DynamicTextArea from "../../components/DynamicTextarea";
import PartnerAccesField from "../../components/PartnerAccessField";
import TermAccesField from "../../components/TermAccessField";
import NavigationPrompt from "../../components/NavigationPrompt";

export const AdminEditOffer = () => {
  const { id } = useParams();
  const [formState, setFormState] = useState({
    id,
    title: "",
    body: "",
    field_link: "",
    field_preamble: "",
    field_contact_email: "",
    field_access_term: [],
    field_access_partner: [],
  });
  const [image, setImage] = useState(null);
  const [industryTerm, setIndustryTerm] = useState(null);
  const { data, setData, isLoading, error, status } = useZeusDataProvider(
    `admin-edit-offer-${id}`,
    null
  );
  const [industryTerms] = useDynamicDataProvider("industry-term-list", []);
  const [validationError, setValidationError] = useState("");
  const { getPhoto } = useCamera();
  const router = useIonRouter();

  useEffect(() => {
    if (data) {
      setFormState((current) => ({
        ...current,
        title: data.title,
        field_preamble: data.field_preamble,
        body: data.body?.value ?? "",
        field_link: data.field_link,
        field_contact_email: data.field_contact_email,
        field_access_term: data.field_access_term?.map((term) => term.id) ?? [],
        field_access_partner:
          data.field_access_partner?.map((term) => term.id) ?? [],
      }));
      if (data.field_industry) {
        setIndustryTerm(data.field_industry.id);
      }
    }
  }, [data]);

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const doSubmit = (e) => {
    e?.preventDefault();

    if (!formState.title) {
      setValidationError("Du måste fylla i en rubrik!");
      return;
    }

    const data = {
      ...formState,
      field_access_partner: formState.field_access_partner.map((id) => ({
        id,
      })),
      field_access_term: formState.field_access_term.map((id) => ({
        id,
      })),
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_image",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    if (industryTerm) {
      data.field_industry = { id: industryTerm };
    }

    setData(data);
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error !== ""}
        header={getSetting("APP_NAME")}
        message={error}
      />
      <IonAlert
        isOpen={validationError !== ""}
        header={getSetting("APP_NAME")}
        message={validationError}
        onDidDismiss={() => setValidationError("")}
        buttons={["Ok"]}
      />
      <IonAlert
        isOpen={status === "updated"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.canGoBack() && router.goBack()}
        message="Erbjudandet har uppdaterats."
      />
      <NavigationPrompt when={status !== "updated"} />
      <PageWithCollapsableHeader
        title="Redigera erbjudande"
        mode="back"
        defaultHref="/u/admin/content"
      >
        <IonList>
          {industryTerms.length > 0 && (
            <PartnerSelect
              icon="icons/chevron-down16.svg"
              label="Bransch"
              placeholder="Välj bransch"
              options={industryTerms.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              value={industryTerm}
              onChange={(e) => setIndustryTerm(e.detail.value)}
            />
          )}
          <PartnerAccesField
            value={formState.field_access_partner}
            onChange={updateFormState("field_access_partner")}
          />
          <TermAccesField
            value={formState.field_access_term}
            onChange={updateFormState("field_access_term")}
          />
        </IonList>

        <PartnerContainer>
          <PartnerText modifiers="partner-text--center partner-text--small-margin-bottom partner-text--mini">
            Ladda upp bild
          </PartnerText>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            img={image?.webPath ?? data?.field_image?.image_style_uri.wide}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.title}
              onChange={(e) =>
                setFormState({ ...formState, title: e.target.value })
              }
              label="Rubrik"
            />
            <DynamicTextArea
              value={formState.field_preamble}
              onChange={(e) =>
                setFormState({ ...formState, field_preamble: e.target.value })
              }
              label="Ingress"
              textarea={true}
            />
            <DynamicTextArea
              value={formState.body}
              onChange={(e) =>
                setFormState({ ...formState, body: e.target.value })
              }
              label="Beskrivning"
              textarea={true}
            />
            <PartnerInput
              value={formState.field_link}
              onChange={(e) =>
                setFormState({ ...formState, field_link: e.target.value })
              }
              label="Länk"
            />
            <PartnerInput
              value={formState.field_contact_email}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_contact_email: e.target.value,
                })
              }
              label="E-post för kontakt"
              modifiers="partner-input__item--margin-bottom"
            />
            <PartnerButton
              text="Spara"
              expand="block"
              modifiers="partner-button--margin-top"
              onClick={doSubmit}
            />
          </form>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id={`admin-edit-offer-${id}`}
        send-tags={`node-offer,node-offer-${id}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/node/offer/${id}?include=field_industry,field_image,field_access_term,field_access_partner`}
          type="node--offer"
          attributes="title,body,field_preamble,field_link,field_industry,field_image,field_contact_email,field_access_term,field_access_partner"
          relationships="field_image:file--file,field_industry:taxonomy_term--industry,field_access_term:taxonomy_term--partner_type,field_access_partner:group--partner"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminEditOffer;
