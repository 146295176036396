import { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  PartnerButton,
  PartnerText,
  PartnerItem,
  PartnerContainer,
  PartnerInput,
} from "@understandit/partnerplattformen-storybook-30876108";

import { classList } from "../helpers";
import { useAlert, useActionSheet } from "../hooks";
import SurveyComponent from "./SurveyComponent";

function validateSurvey(values, survey) {
  if (!values) {
    return false;
  }

  for (const component of survey.components) {
    if (component.isRequired && !values[component.name]) {
      return false;
    }
  }

  return true;
}

function validateGuest(guest, survey) {
  if (!guest.name) {
    return false;
  }
  if (!guest.email) {
    return false;
  }

  return validateSurvey(guest.values, survey);
}

const Survey = ({ survey, onSubmit, init }) => {
  const showAlert = useAlert();
  const showSheet = useActionSheet();
  const [guestIndex, setGuestIndex] = useState(-1);
  const [guestState, setGuestState] = useState({});
  const [formState, setFormState] = useState(
    init ?? {
      version: "1.0",
      values: {},
      guests: [],
    }
  );

  const surveyComponent = (prop) => {
    return {
      value: formState.values[prop],
      onChange: (e) => {
        setFormState((current) => {
          return {
            ...current,
            values: {
              ...current.values,
              [prop]: e.target.value,
            },
          };
        });
      },
    };
  };

  const guestFormComponent = (prop) => {
    return {
      value: guestState[prop],
      onChange: (e) => {
        setGuestState((current) => {
          return {
            ...current,
            [prop]: e.target.value,
          };
        });
      },
    };
  };

  const guestSurveyComponent = (prop) => {
    return {
      value: guestState.values?.[prop],
      onChange: (e) => {
        setGuestState((current) => {
          return {
            ...current,
            values: {
              ...current.values,
              [prop]: e.detail.value,
            },
          };
        });
      },
    };
  };

  const showActions = async (index) => {
    const action = await showSheet({
      buttons: [
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Redigera", data: "edit" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });

    if (action === "delete") {
      setFormState((current) => {
        const newGuests = current.guests.filter((_, i) => i !== index);
        return {
          ...current,
          guests: newGuests,
        };
      });
    }

    if (action === "edit") {
      setGuestState({ ...formState.guests[index] });
      setGuestIndex(index);
    }
  };

  const saveSurvey = () => {
    if (!validateSurvey(formState.values, survey)) {
      showAlert("Du måste fylla i alla obligatoriska fält.");
      return;
    }

    onSubmit?.(formState);
  };

  return (
    <>
      <IonModal isOpen={guestIndex !== -1}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Lägg till gäst</IonTitle>
            <IonButtons>
              <IonButton onClick={() => setGuestIndex(-1)}>Stäng</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <PartnerContainer>
            <PartnerText>
              Kul att du vill ta med en vän! Fyll i uppgifterna så har vi lite
              koll på vilka som kommer.
            </PartnerText>
          </PartnerContainer>
          <PartnerContainer modifiers="partner-container--border-top">
            <PartnerInput label="Namn*" {...guestFormComponent("name")} />
            <PartnerInput label="E-post*" {...guestFormComponent("email")} />
            <PartnerInput label="Tel" {...guestFormComponent("telephone")} />
            {survey.components.map((component) => (
              <SurveyComponent
                key={component.name}
                component={component}
                {...guestSurveyComponent(component.name)}
              />
            ))}

            <PartnerButton
              text="Spara"
              modifiers="partner-button--margin-top-mega"
              expand="block"
              onClick={() => {
                if (!validateGuest(guestState, survey)) {
                  showAlert("Du måste fylla i alla obligatoriska fält.");
                  return;
                }

                const newGuests = [...formState.guests];
                newGuests[guestIndex] = { ...guestState };
                setFormState({
                  ...formState,
                  guests: newGuests,
                });
                setGuestState({});
                setGuestIndex(-1);
              }}
            />
          </PartnerContainer>
        </IonContent>
      </IonModal>
      {survey.components.map((component) => (
        <SurveyComponent
          key={component.name}
          component={component}
          {...surveyComponent(component.name)}
        />
      ))}

      {formState.guests.length > 0 && (
        <>
          <PartnerText modifiers="partner-text--weight-large partner-text--x-small-margin-bottom">
            Gäster
          </PartnerText>
          <IonList className="app-list">
            {formState.guests.map((guest, i) => (
              <PartnerItem
                key={i}
                label={guest.name}
                edit={true}
                modifiers="partner-item--with-background"
                onEditClick={() => showActions(i)}
              />
            ))}
          </IonList>
        </>
      )}

      {survey.guests > 0 && (
        <PartnerButton
          text="Lägg till gäst"
          modifiers="partner-button--secondary partner-button--margin-top-mega"
          expand="block"
          onClick={() => {
            setGuestState({});
            setGuestIndex(formState.guests.length);
          }}
        />
      )}
      <PartnerButton
        text="Skicka anmälan"
        modifiers={
          survey.guests === 0
            ? "partner-button--margin-top-mega"
            : "partner-button--margin-top"
        }
        expand="block"
        onClick={saveSurvey}
      />
    </>
  );
};

export default Survey;
