import { IonPage, useIonRouter } from "@ionic/react";
import { useParams } from "react-router";
import {
  PageWithCollapsableHeader,
  PartnerContainer,
  PartnerText,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";

import { useEventProvider } from "../providers/event-provider";
import { useEventActions } from "../hooks";
import Survey from "../survey/Survey";

const EventSignUp = () => {
  const { id } = useParams();
  const { event, Provider } = useEventProvider(id);
  const [profile] = useDynamicDataProvider("profile");
  const [membership] = useDynamicDataProvider("partner-group-membership");
  const router = useIonRouter();

  const { joinEvent } = useEventActions(id, () => {
    router.push(`/u/event/${id}/submission`, "forward", "replace");
  });

  const doJoinEvent = (data) => {
    joinEvent(
      profile?.id,
      membership?.drupal_internal__id,
      JSON.stringify(data)
    );
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Anmälan event"
        mode="back"
        defaultHref={`/u/event/${id}`}
      >
        <PartnerContainer>
          <PartnerText modifiers="partner-text--margin-bottom partner-text--weight-large">
            Dina uppgifter
          </PartnerText>

          {event?.field_survey && (
            <Survey
              survey={JSON.parse(event.field_survey)}
              onSubmit={doJoinEvent}
            />
          )}
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <Provider />
    </IonPage>
  );
};

export default EventSignUp;
