import { useEffect } from "react";
import { IonLoading } from "@ionic/react";
import {
  useDynamicDataProvider,
  useZeusDataStatusSubscriber,
} from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const EntityStatusUpdateAction = ({
  type,
  bundle,
  id,
  status,
  onDone = () => {},
}) => {
  const [actionResponse] = useDynamicDataProvider("action-provider");
  const data = { id, status };
  useEffect(() => {
    if (actionResponse?.status === status) {
      onDone();
    }
  }, [actionResponse, onDone, status]);
  return (
    <>
      <IonLoading
        isOpen={true}
        showBackdrop={false}
        message="Uppdaterar status"
      />
      ;
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`${type}-${bundle},${type}-${bundle}-${id}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/jsonapi/${type}/${bundle}/${id}`}
          attributes="status"
          type={`${type}--${bundle}`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityUpdateAction = ({
  type,
  bundle,
  id,
  attributes,
  extraTags,
  onDone = () => {},
}) => {
  const providerId = `action-provider-update-${id}`;
  const data = { id, ...attributes };
  const keys = Object.keys(attributes).join(",");
  let tags = `${type}-${bundle},${type}-${bundle}-${id}`;

  if (extraTags) {
    tags += `,${extraTags}`;
  }
  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider
        id={providerId}
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={tags}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/jsonapi/${type}/${bundle}/${id}`}
          attributes={keys}
          type={`${type}--${bundle}`}
          data-action="update"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const EntityDeleteAction = ({ type, bundle, id, onDone = () => {} }) => {
  const providerId = `action-provider-delete-${id}`;
  const data = { id };
  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "deleted") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Raderar" />
      <zeus-data-provider
        id={providerId}
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`${type}-${bundle},${type}-${bundle}-${id}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting("BACKEND")}/jsonapi/${type}/${bundle}/${id}`}
          attributes="status"
          type={`${type}--${bundle}`}
          data-action="delete"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const MembershipDeleteAction = ({ partnerId, memberId, onDone = () => {} }) => {
  const data = { partner_id: partnerId, member_id: memberId };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Raderar" />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`partner-members-${partnerId}`}
      >
        <zeus-data-source-json
          data-action="create"
          url={`${getSetting("BACKEND")}/api/remove-membership`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const AdminMembershipDeleteAction = ({
  partnerId,
  memberId,
  onDone = () => {},
}) => {
  const data = { partner_id: partnerId, member_id: memberId };
  useZeusDataStatusSubscriber("action-provider", (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Raderar" />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`partner-members-${partnerId}`}
      >
        <zeus-data-source-json
          data-action="create"
          url={`${getSetting("BACKEND")}/api/admin-user-del`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const TermsSortAction = ({ bundle, terms, onDone = () => {} }) => {
  const data = { bundle, terms };
  const providerId = `action-provider-terms-sort-${bundle}`;
  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider
        id={providerId}
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`taxonomy_term-${bundle}`}
      >
        <zeus-data-source-json
          data-action="create"
          url={`${getSetting("BACKEND")}/api/sort-terms`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const SetPartnerAdminAction = ({
  memberId,
  partnerId,
  value,
  onDone = () => {},
}) => {
  const data = { memberId, partnerId, value };
  const providerId = `action-provider-set-partner-admin-${memberId}`;
  useZeusDataStatusSubscriber(providerId, (status) => {
    if (status === "updated") {
      onDone();
    }
  });
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} message="Uppdaterar" />
      <zeus-data-provider
        id={providerId}
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={`partner-members-${partnerId}`}
      >
        <zeus-data-source-json
          data-action="create"
          url={`${getSetting("BACKEND")}/api/set-partner-admin`}
        ></zeus-data-source-json>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const ActionModal = ({ action, onDone, args }) => {
  if (action === "publish") {
    return (
      <EntityStatusUpdateAction
        type={args.type}
        bundle={args.bundle}
        id={args.id}
        status={true}
        onDone={onDone}
      />
    );
  } else if (action === "unpublish") {
    return (
      <EntityStatusUpdateAction
        type={args.type}
        bundle={args.bundle}
        id={args.id}
        status={false}
        onDone={onDone}
      />
    );
  } else if (action === "delete") {
    return (
      <EntityDeleteAction
        type={args.type}
        bundle={args.bundle}
        id={args.id}
        onDone={onDone}
      />
    );
  } else if (action === "update") {
    return <EntityUpdateAction {...args} onDone={onDone} />;
  } else if (action === "delete-membership") {
    return <MembershipDeleteAction {...args} onDone={onDone} />;
  } else if (action === "admin-delete-membership") {
    return <AdminMembershipDeleteAction {...args} onDone={onDone} />;
  } else if (action === "sort-terms") {
    return <TermsSortAction {...args} onDone={onDone} />;
  } else if (action === "set-partner-admin") {
    return <SetPartnerAdminAction {...args} onDone={onDone} />;
  }

  return null;
};

export default ActionModal;
