import { useParams } from "react-router";
import { IonPage } from "@ionic/react";
import {
  PartnerContainer,
  PartnerMediaWithLogo,
  PartnerHeading,
  PartnerText,
  PageWithCollapsableHeader,
  PartnerItemInfo,
  PartnerAvatar,
  PartnerButton,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../../settings";

import SocialMediaIcons from "../../components/SocialMediaIcons";

const AdminMemberView = () => {
  const { partner_id, id } = useParams();
  const providerId = `member-${id}`;
  const { data: member } = useZeusDataProvider(providerId, null);
  const partner = member?.gid;

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Användarprofil"
        mode="back"
        defaultHref={"/u/network"}
      >
        <PartnerContainer>
          <PartnerMediaWithLogo
            media={member?.field_avatar?.image_style_uri.medium}
            inline={<PartnerAvatar />}
            logo={partner?.field_logo?.image_style_uri.medium}
          />
          <PartnerHeading
            modifiers="partner-heading--margin-top"
            text={member?.field_name}
          />
          <PartnerText modifiers="partner-text--center partner-text--small partner-text--color-medium">
            {member?.field_job_title}
          </PartnerText>
          <PartnerText modifiers="partner-text--margin-top partner-text--margin-bottom">
            {member?.field_description}
          </PartnerText>
        </PartnerContainer>

        <PartnerContainer>
          <PartnerButton
            modifiers="partner-button--secondary"
            expand
            routerLink={`/u/admin/network/${partner_id}/member/${id}/edit`}
            icon="icons/edit-icon16.svg"
          >
            Redigera medlem
          </PartnerButton>
        </PartnerContainer>
        <PartnerContainer>
          <PartnerItemInfo
            mail={member?.label}
            phone={member?.field_telephone}
          />
        </PartnerContainer>

        <PartnerContainer>
          <SocialMediaIcons membership={member} />
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id={providerId}
        listen-tags={`group_content-partner-group_membership-${id}`}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/partner-group_membership/${id}?include=field_avatar,gid.field_logo`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminMemberView;
