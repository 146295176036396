import { useState } from "react";
import {
  IonPage,
  IonLoading,
  useIonRouter,
  IonAlert,
  IonList,
} from "@ionic/react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerText,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerSelect,
} from "@understandit/partnerplattformen-storybook-30876108";

import { makeid } from "../../helpers";
import { getSetting } from "../../settings";
import { useCamera, useTaxonomy } from "../../hooks";

import DynamicTextArea from "../../components/DynamicTextarea";
import PartnerAccesField from "../../components/PartnerAccessField";
import TermAccesField from "../../components/TermAccessField";
import PartnerSelectField from "../../components/PartnerSelectField";
import NavigationPrompt from "../../components/NavigationPrompt";

export const AdminCreateOffer = () => {
  const [formState, setFormState] = useState({
    title: "",
    body: "",
    field_link: "",
    field_preamble: "",
    field_contact_email: "",
    field_access_term: [],
    field_access_partner: [],
  });
  const [image, setImage] = useState(null);
  const [industryTerm, setIndustryTerm] = useState(null);
  const [selecedPartner, setSelectedPartner] = useState(null);
  const [validationError, setValidationError] = useState("");
  const industryTerms = useTaxonomy("industry");
  const { data, status, setData, isLoading, error } = useZeusDataProvider(
    "create-offer",
    null
  );
  const router = useIonRouter();
  const { getPhoto } = useCamera();

  const updateFormState = (field) => {
    return (e) =>
      setFormState((current) => {
        return { ...current, [field]: e.target.value };
      });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const doSubmit = (e) => {
    e?.preventDefault();

    if (!selecedPartner) {
      setValidationError("Du måste välja en partner!");
      return;
    }

    if (!formState.title) {
      setValidationError("Du måste fylla i en rubrik!");
      return;
    }

    const data = {
      ...formState,
      _group: selecedPartner,
    };

    if (image) {
      data._files = [
        {
          field: "field_image",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    if (industryTerm) {
      data._terms = [
        { field: "field_industry", uuid: industryTerm, vid: "industry" },
      ];
    }

    data._relationships = [
      {
        field: "field_access_term",
        data: formState.field_access_term.map((id) => ({
          id,
          type: "taxonomy_term--partner_type",
        })),
      },
      {
        field: "field_access_partner",
        data: formState.field_access_partner.map((id) => ({
          id,
          type: "group--partner",
        })),
      },
    ];

    delete data.field_access_partner;
    delete data.field_access_term;

    setData(data);
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error !== ""}
        header={getSetting("APP_NAME")}
        message={error}
      />
      <IonAlert
        isOpen={validationError !== ""}
        header={getSetting("APP_NAME")}
        message={validationError}
        onDidDismiss={() => setValidationError("")}
        buttons={["Ok"]}
      />
      <IonAlert
        isOpen={data !== null}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.goBack()}
        message="Ditt erbjudande har skapats."
      />
      <NavigationPrompt when={status !== "created"} />
      <PageWithCollapsableHeader
        title="Skapa erbjudande"
        mode="back"
        defaultHref="/u/admin/content"
      >
        <IonList>
          <PartnerSelectField
            value={selecedPartner}
            onChange={(e) => setSelectedPartner(e.detail.value)}
          />
          {industryTerms.length > 0 && (
            <PartnerSelect
              icon="icons/chevron-down16.svg"
              label="Bransch"
              placeholder="Välj"
              options={industryTerms.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              value={industryTerm}
              onChange={(e) => setIndustryTerm(e.detail.value)}
            />
          )}
          <PartnerAccesField
            value={formState.field_access_partner}
            onChange={updateFormState("field_access_partner")}
          />
          <TermAccesField
            value={formState.field_access_term}
            onChange={updateFormState("field_access_term")}
          />
        </IonList>

        <PartnerContainer>
          <PartnerText modifiers="partner-text--center partner-text--small-margin-bottom partner-text--mini">
            Ladda upp bild
          </PartnerText>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            img={image?.webPath}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.title}
              onChange={(e) =>
                setFormState({ ...formState, title: e.target.value })
              }
              label="Rubrik"
            />
            <DynamicTextArea
              value={formState.field_preamble}
              onChange={(e) =>
                setFormState({ ...formState, field_preamble: e.target.value })
              }
              label="Ingress"
            />
            <DynamicTextArea
              value={formState.body}
              onChange={(e) =>
                setFormState({ ...formState, body: e.target.value })
              }
              label="Beskrivning"
            />
            <PartnerInput
              value={formState.field_link}
              onChange={(e) =>
                setFormState({ ...formState, field_link: e.target.value })
              }
              label="Länk"
            />
            <PartnerInput
              value={formState.field_contact_email}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_contact_email: e.target.value,
                })
              }
              label="E-post för kontakt"
              modifiers="partner-input__item--margin-bottom"
            ></PartnerInput>
            <PartnerButton
              text="Spara"
              expand="block"
              modifiers="partner-button--margin-top"
              onClick={doSubmit}
            />
          </form>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id="create-offer" send-tags="node-offer">
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          content-bundle="offer"
          group-bundle="partner"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminCreateOffer;
