import { useIonRouter, IonList, IonPage } from "@ionic/react";
import { useParams } from "react-router";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerHeading,
  PartnerCard,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";
import { stripTags, formatDateRange } from "../helpers";

// const TeamEvents = ({partnerId}) => {

//   const providerId = `team-events-${partnerId}`;
//   const [teamEvents] = useDynamicDataProvider(providerId, []);
//   const router = useIonRouter();

//   return (
//     <PartnerContainer>
//       <PartnerHeading text="Teamets events" modifiers="partner-heading--start partner-heading--margin-bottom" />
//       <IonList className="app-list">
//         {teamEvents.map(item => {
//           const event = item.entity_id;
//           return <PartnerCard
//             size="small"
//             modifiers="partner-card--small-and-long line-clamp-3"
//             onClick={() => router.push(`/u/event/${event.id}`)}
//             key={event.id}
//             title={event.label}
//             date={formatDateRange(event.field_date?.value, event.field_date?.end_value)}
//             imageSrc={event.field_image?.image_style_uri.large}
//             badgeSrc={event.entitygroupfield[0]?.gid?.field_logo?.image_style_uri.large}
//           >
//             {stripTags(event.field_preamble)}
//           </PartnerCard>
//         })}
//       </IonList>
//       <zeus-data-provider id={providerId} listen-tags="event">
//         <zeus-data-source-jsonapi url={`${getSetting('BACKEND')}/jsonapi/group_content/partner-subgroup-event?filter[gid.id]=${partnerId}&filter[entity_id.status]=1&include=entity_id.field_image`}></zeus-data-source-jsonapi>
//         <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
//       </zeus-data-provider>
//     </PartnerContainer>
//   );
// }

const ProfileEvents = () => {
  const { id } = useParams();
  const providerId = `my-events-${id}`;
  const [myEvents] = useDynamicDataProvider(providerId, []);

  const router = useIonRouter();
  return (
    <IonPage>
      <PageWithCollapsableHeader
        title="Mina event"
        mode="back"
        defaultHref="/u/profile"
      >
        <PartnerContainer>
          <PartnerHeading
            text="Bokade event"
            modifiers="partner-heading--start partner-heading--margin-bottom"
          />
          <IonList className="app-list">
            {myEvents.map((item) => {
              const event = item.gid;
              return (
                <PartnerCard
                  size="small"
                  modifiers="partner-card--small-and-long line-clamp-3"
                  onClick={() => router.push(`/u/event/${event.id}`)}
                  key={event.id}
                  title={event.label}
                  date={formatDateRange(
                    event.field_date?.value,
                    event.field_date?.end_value
                  )}
                  imageSrc={
                    event.field_image?.image_style_uri.large ??
                    getSetting("DEFAULT_IMAGE")
                  }
                  badgeSrc={
                    event.entitygroupfield[0]?.gid?.field_logo?.image_style_uri
                      .large
                  }
                >
                  {stripTags(event.field_preamble ?? event.body?.processed)}
                </PartnerCard>
              );
            })}
          </IonList>
        </PartnerContainer>
        {/* {myPartner?.id && <TeamEvents partnerId={myPartner.id} />} */}
      </PageWithCollapsableHeader>
      <zeus-data-provider id={providerId} listen-tags="event">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/event-group_membership?filter[entity_id.id]=${id}&include=gid.field_image`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default ProfileEvents;
