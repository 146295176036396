import { useCallback } from "react";
import { IonLoading } from "@ionic/react";
import { useZeusDataStatusSubscriber } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";

const GroupJoinAction = ({ groupType, groupId, data, onDone = () => {} }) => {
  const preparedData = { ...data, _group: groupId };
  const cb = useCallback(
    (status) => {
      if (status === "created") {
        onDone();
      }
    },
    [onDone]
  );
  useZeusDataStatusSubscriber("action-provider-123", cb);
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} />
      <zeus-data-provider
        id="action-provider-123"
        set-data={JSON.stringify(preparedData)}
        write-only={true}
        send-tags={groupType}
      >
        <zeus-data-source-drupal-group-join
          url={getSetting("BACKEND")}
        ></zeus-data-source-drupal-group-join>
        <zeus-data-middleware-oauth
          token-provider="auth"
          invalidate-token-on="post"
        ></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const GroupLeaveAction = ({ groupType, membershipId, onDone = () => {} }) => {
  const data = { id: membershipId };
  const cb = useCallback(
    (status) => {
      if (status === "deleted") {
        onDone();
      }
    },
    [onDone]
  );
  useZeusDataStatusSubscriber("action-provider", cb);
  return (
    <>
      <IonLoading isOpen={true} showBackdrop={false} />
      <zeus-data-provider
        id="action-provider"
        set-data={JSON.stringify(data)}
        write-only={true}
        send-tags={groupType}
      >
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/${groupType}-group_membership/${membershipId}`}
          type={`group_content--${groupType}-group_membership`}
          data-action="delete"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth
          token-provider="auth"
          invalidate-token-on="delete"
        ></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </>
  );
};

const GroupActionModal = ({
  action,
  groupType,
  id,
  subjectId,
  data,
  onDone,
}) => {
  if (action === "join") {
    return (
      <GroupJoinAction
        groupType={groupType}
        groupId={id}
        data={data}
        onDone={onDone}
      />
    );
  } else if (action === "leave") {
    return (
      <GroupLeaveAction
        groupType={groupType}
        membershipId={subjectId}
        onDone={onDone}
      />
    );
  }

  return null;
};

export default GroupActionModal;
