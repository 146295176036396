import { isPlatform } from "@ionic/react";
import { PartnerInput } from "@understandit/partnerplattformen-storybook-30876108";

const DynamicTextArea = ({ value, onChange, label, rows }) => (
  <PartnerInput
    value={value}
    onChange={onChange}
    label={label}
    textarea={true}
    rows={rows ?? (isPlatform("desktop") ? 10 : 4)}
  />
);

export default DynamicTextArea;
