import { useIonRouter, IonList, IonPage } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerHeading,
  PartnerItem,
  PartnerEventCard,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { formatDateRange } from "../helpers";
import { getSetting } from "../settings";

const EventOverview = () => {
  const [eventList] = useDynamicDataProvider("event-list", []);
  const [profile] = useDynamicDataProvider("profile", []);
  const router = useIonRouter();
  return (
    <IonPage>
      <PageWithCollapsableHeader title="Event">
        <PartnerItem
          label="Mina event"
          icon="icons/conversation-bubble24.svg"
          iconEnd="icons/chevron-right16.svg"
          routerLink={`/u/profile/${profile?.id}/event`}
        />
        <PartnerContainer>
          <PartnerHeading
            text="Kommande event"
            modifiers="partner-heading--start partner-heading--margin-bottom"
          />
          <IonList className="app-list">
            {eventList.map((item) => (
              <PartnerEventCard
                size="small"
                modifiers="partner-card--small-and-long"
                onClick={() => router.push(`/u/event/${item.id}`)}
                key={item.id}
                title={item.label}
                location={item.field_location}
                date={formatDateRange(
                  item.field_date?.value,
                  item.field_date?.end_value
                )}
                imageSrc={
                  item.field_image?.image_style_uri.large ??
                  getSetting("DEFAULT_IMAGE")
                }
              />
            ))}
          </IonList>
        </PartnerContainer>
      </PageWithCollapsableHeader>
    </IonPage>
  );
};

export default EventOverview;
