import { useState } from "react";
import {
  IonPage,
  IonLoading,
  useIonRouter,
  IonAlert,
  IonList,
} from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerText,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerSelect,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";
import { makeid } from "../helpers";
import { useCamera } from "../hooks";

export const RequestCreate = () => {
  const [formState, setFormState] = useState({
    title: "",
    body: "",
    field_link: "",
    field_preamble: "",
    field_contact_email: "",
  });
  const [image, setImage] = useState(null);
  const [industryTerm, setIndustryTerm] = useState(null);
  const [myPartner] = useDynamicDataProvider("my-partner");
  const [profile] = useDynamicDataProvider("profile");
  const [data, , setData, isLoading, error] = useDynamicDataProvider(
    "create-request",
    null
  );
  const [industryTerms] = useDynamicDataProvider("industry-term-list", []);
  const router = useIonRouter();

  const { getPhoto } = useCamera();

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  const doSubmit = (e) => {
    e?.preventDefault();

    const data = {
      ...formState,
      _group: myPartner.id,
      _postAddData: { status: 0 },
    };

    if (image) {
      data._files = [
        {
          field: "field_image",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    if (industryTerm) {
      data._terms = [
        { field: "field_industry", uuid: industryTerm, vid: "industry" },
      ];
    }

    data._quota = {
      field: "field_quota_request",
      value: Math.max(myPartner?.field_quota_request - 1, 0),
    };

    setData(data);
  };

  return (
    <IonPage>
      <IonLoading isOpen={isLoading} />
      <IonAlert
        isOpen={error !== ""}
        header={getSetting("APP_NAME")}
        message={error}
      />
      <IonAlert
        isOpen={data !== null}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.goBack()}
        message="Din förfrågan har skapats. En administratör kommer granska inlägget så snart som möjligt."
      />

      <PageWithCollapsableHeader
        title="Skapa förfrågan"
        mode="back"
        defaultHref={`/u/profile/${profile?.id}/request`}
      >
        <PartnerContainer>
          <PartnerText>{getSetting("REQUEST_HELP_TEXT")}</PartnerText>
        </PartnerContainer>

        {industryTerms.length > 0 && (
          <IonList>
            <PartnerSelect
              icon="icons/chevron-down16.svg"
              label="Bransch"
              placeholder="Välj"
              value={industryTerm}
              onChange={(e) => setIndustryTerm(e.detail.value)}
              options={industryTerms.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
            />
          </IonList>
        )}

        <PartnerContainer>
          <PartnerText modifiers="partner-text--center partner-text--small-margin-bottom partner-text--mini">
            Ladda upp bild
          </PartnerText>
          <PartnerMediaWithButton
            buttonClick={() => takePicture()}
            img={image?.webPath}
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.title}
              onChange={(e) =>
                setFormState({ ...formState, title: e.target.value })
              }
              label="Rubrik"
            />
            <PartnerInput
              value={formState.field_preamble}
              onChange={(e) =>
                setFormState({ ...formState, field_preamble: e.target.value })
              }
              label="Ingress"
              textarea={true}
            />
            <PartnerInput
              value={formState.body}
              onChange={(e) =>
                setFormState({ ...formState, body: e.target.value })
              }
              label="Beskrivning"
              textarea={true}
            />
            <PartnerInput
              value={formState.field_link}
              onChange={(e) =>
                setFormState({ ...formState, field_link: e.target.value })
              }
              label="Länk"
            />
            <PartnerInput
              value={formState.field_contact_email}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_contact_email: e.target.value,
                })
              }
              label="E-post för kontakt"
              modifiers="partner-input__item--margin-bottom"
            />
            <PartnerButton
              text="Skicka in för granskning"
              expand="block"
              modifiers="partner-button--margin-top"
              onClick={doSubmit}
            />
          </form>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id="create-request" send-tags="request,membership">
        <zeus-data-source-drupal-group-member-content-create
          base-url={getSetting("BACKEND")}
          content-bundle="request"
          group-bundle="partner"
          token-provider="auth"
        ></zeus-data-source-drupal-group-member-content-create>
      </zeus-data-provider>
    </IonPage>
  );
};

export default RequestCreate;
