import { useRef, useState } from "react";
import { IonPage, IonSearchbar, IonList, useIonRouter } from "@ionic/react";
import {
  PageWithCollapsableHeader,
  PartnerContainer,
  PartnerButton,
  PartnerSkeletonItemPerson,
  PartnerItemPerson,
} from "@understandit/partnerplattformen-storybook-30876108";

import { preventAndStop, preventDefault } from "../../helpers";
import {
  useActionSheet,
  useConfirmDangerousAction,
  useActionModal,
} from "../../hooks";
import { usePartnersProvider } from "../../providers/partners-provider";

const PartnerVisibility = ({ partner }) => (
  <>
    {partner.field_hide_in_list && (
      <div>
        <ion-icon src="icons/inactive16.svg"></ion-icon>
      </div>
    )}
  </>
);

const AdminPartner = () => {
  const searchbar = useRef();
  const [searchString, setSearchString] = useState("");
  const { Provider, partners, isLoading } = usePartnersProvider(searchString);
  const presentActionSheet = useActionSheet();
  const confirm = useConfirmDangerousAction();
  const doAction = useActionModal();
  const router = useIonRouter();

  const doSearch = () => {
    setSearchString(searchbar.current?.value ?? "");
  };

  const doOpenAdmin = async (partner) => {
    const action = await presentActionSheet({
      buttons: [
        { text: "Redigera", data: "edit" },
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });

    if (action === "delete") {
      if (await confirm("Är du säker på att du vill ta bort partnern?")) {
        doAction("delete", {
          id: partner.id,
          type: "group",
          bundle: "partner",
        });
      }
    } else if (action === "edit") {
      router.push(`/u/admin/network/${partner.id}/edit`);
    }
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader title="Partners">
        <PartnerContainer>
          <PartnerButton expand={true} routerLink="/u/admin/network/add">
            Skapa partner
          </PartnerButton>
          <PartnerButton
            expand={true}
            modifiers="partner-button--secondary"
            routerLink="/u/admin/taxonomy/partner_type"
          >
            Hantera partnertyper
          </PartnerButton>

          <div className="partner-searchbar partner-searchbar--margin-top">
            <form onSubmit={preventDefault(doSearch)}>
              <IonSearchbar
                ref={searchbar}
                placeholder="Sök efter partners..."
                searchIcon="icons/search24.svg"
              />
            </form>
          </div>
        </PartnerContainer>
        <PartnerContainer>
          <IonList className="app-list">
            {isLoading && partners.length === 0 && (
              <>
                <PartnerSkeletonItemPerson />
                <PartnerSkeletonItemPerson />
                <PartnerSkeletonItemPerson />
              </>
            )}
            {partners.map((item) => (
              <PartnerItemPerson
                routerLink={`/u/admin/network/${item.id}`}
                key={item.id}
                name={item.label}
                title={item.field_partner_type?.name}
                img={item.field_logo?.image_style_uri.large}
                modifiers="partner-item-person--company"
                visibility={<PartnerVisibility partner={item} />}
                inactive={item.field_hide_in_list}
                admin
                onAdminClick={preventAndStop(() => doOpenAdmin(item))}
              />
            ))}
          </IonList>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <Provider />
    </IonPage>
  );
};

export default AdminPartner;
