import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  IonLoading,
  IonPage,
  useIonRouter,
  IonAlert,
  IonItem,
  IonLabel,
  IonToggle,
  IonList,
} from "@ionic/react";
import {
  PartnerContainer,
  PartnerMediaWithButton,
  PartnerInput,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerText,
} from "@understandit/partnerplattformen-storybook-30876108";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import { useCamera } from "../hooks";
import { makeid } from "../helpers";

export const EditProfile = () => {
  const { id } = useParams();
  const providerId = `membership-${id}`;
  const {
    data: membership,
    setData,
    isLoading,
    status,
  } = useZeusDataProvider(providerId, null);
  const router = useIonRouter();
  const [checked, setChecked] = useState(true);
  const { getPhoto } = useCamera();
  const [image, setImage] = useState(null);
  const [formState, setFormState] = useState({
    field_name: "",
    field_job_title: "",
    field_telephone: "",
    field_city: "",
    field_social_facebook: "",
    field_social_instagram: "",
    field_social_twitter: "",
    field_social_linkedin: "",
    field_description: "",
  });

  useEffect(() => {
    if (membership) {
      setFormState({
        field_name: membership.field_name,
        field_job_title: membership.field_job_title,
        field_telephone: membership.field_telephone,
        field_city: membership.field_city,
        field_social_facebook: membership.field_social_facebook,
        field_social_instagram: membership.field_social_instagram,
        field_social_twitter: membership.field_social_twitter,
        field_social_linkedin: membership.field_social_linkedin,
        field_description: membership.field_description,
      });
      setChecked(!Boolean(membership.field_hide_contact_details));
    }
  }, [membership]);

  const doSubmit = (e) => {
    e?.preventDefault();

    const data = {
      ...formState,
    };

    if (image) {
      data._drupalFiles = [
        {
          field: "field_avatar",
          path: image.webPath,
          type: image.format,
          filename: `${makeid(10)}.${image.format}`,
        },
      ];
    }

    setData({ ...data, id, field_hide_contact_details: !checked });
  };

  const takePicture = async () => {
    try {
      setImage(await getPhoto());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={status === "updated"}
        header={getSetting("APP_NAME")}
        buttons={["Ok"]}
        onDidDismiss={() => router.canGoBack() && router.goBack()}
        message="Profilen har uppdaterats."
      />
      <IonLoading isOpen={isLoading} />
      <PageWithCollapsableHeader
        title="Redigera Profil"
        mode="back"
        defaultHref="/u/profile"
      >
        <PartnerContainer>
          <PartnerMediaWithButton
            buttonClick={takePicture}
            modifiers="partner-media-with-button--profile"
            img={
              image?.webPath ?? membership?.field_avatar?.image_style_uri.medium
            }
            buttonIcon="icons/camera.svg"
          />
        </PartnerContainer>
        <PartnerContainer>
          <form onSubmit={doSubmit}>
            <PartnerInput
              value={formState.field_name}
              onChange={(e) =>
                setFormState({ ...formState, field_name: e.target.value })
              }
              label="Namn"
            />
            <PartnerInput
              value={formState.field_job_title}
              onChange={(e) =>
                setFormState({ ...formState, field_job_title: e.target.value })
              }
              label="Position/Titel"
            />
            <PartnerInput
              value={formState.field_telephone}
              onChange={(e) =>
                setFormState({ ...formState, field_telephone: e.target.value })
              }
              label="Telefon"
            />
            <PartnerInput
              value={formState.field_city}
              onChange={(e) =>
                setFormState({ ...formState, field_city: e.target.value })
              }
              label="Ort"
            />
            <PartnerInput
              value={formState.field_social_facebook}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_facebook: e.target.value,
                })
              }
              label="Facebook"
            />
            <PartnerInput
              value={formState.field_social_instagram}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_instagram: e.target.value,
                })
              }
              label="Instagram"
            />
            <PartnerInput
              value={formState.field_social_twitter}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_twitter: e.target.value,
                })
              }
              label="Twitter"
            />
            <PartnerInput
              value={formState.field_social_linkedin}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_social_linkedin: e.target.value,
                })
              }
              label="Linkedin"
            />
            <PartnerInput
              value={formState.field_description}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  field_description: e.target.value,
                })
              }
              label="Beskrivning av dig"
              textarea={true}
            />
            <IonList>
              <IonItem lines="none" className="partner-item-with-toggle">
                <IonLabel>Visa mina kontaktuppgifter</IonLabel>
                <IonToggle
                  checked={checked}
                  onIonChange={(e) => setChecked(e.detail.checked)}
                />
              </IonItem>
            </IonList>
          </form>
          <PartnerText modifiers="partner-text--start-page-disclaimer">
            Här kan du välja att vara anonym i partnernätverket. Du kommer inte
            synas för andra företag.
          </PartnerText>
        </PartnerContainer>
        <PartnerContainer>
          <PartnerButton text="Spara" expand="block" onClick={doSubmit} />
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider id={providerId} send-tags="membership">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/partner-group_membership/${id}?include=field_avatar`}
          type="group_content--partner-group_membership"
          attributes="field_name,field_job_title,field_telephone,field_city,field_social_facebook,field_social_instagram,field_social_twitter,field_social_linkedin,field_description,field_hide_contact_details"
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default EditProfile;
