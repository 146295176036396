import { useState } from "react";
import { IonList, IonPage, IonSearchbar, useIonRouter } from "@ionic/react";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerButton,
  PageWithCollapsableHeader,
  PartnerSelect,
  PartnerSkeletonItemContent,
  PartnerItemContent,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../../settings";
import { getContentTypeName, formatDate } from "../../helpers";
import {
  useActionSheet,
  useActionModal,
  useConfirmDangerousAction,
} from "../../hooks";

const AdminContent = () => {
  const [filter, setFilter] = useState("");
  const [content, , , isLoading] = useDynamicDataProvider("admin-content", []);
  const [contentFilter, setContentFilter] = useState("news");
  const doAction = useActionModal();
  const confirm = useConfirmDangerousAction();
  const showActionSheet = useActionSheet();
  const router = useIonRouter();
  const entityType = contentFilter === "event" ? "group" : "node";

  const doPresentContextMenu = async (type, id, status) => {
    const action = await showActionSheet({
      buttons: [
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Skapa push", data: "push" },
        { text: "Redigera", data: "edit" },
        {
          text: status ? "Avpublicera" : "Publicera",
          data: status ? "unpublish" : "publish",
        },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });
    if (["unpublish", "publish"].includes(action)) {
      doAction(action, { type: entityType, bundle: type, id });
    } else if (
      action === "delete" &&
      (await confirm("Är du säker på att du vill ta bort innehållet?"))
    ) {
      doAction("delete", { type: entityType, bundle: type, id });
    } else if (action === "edit") {
      router.push(`/u/admin/${type}/${id}/edit`);
    } else if (action === "push") {
      router.push(`/u/admin/${type}/${id}/push`);
    }
  };

  const doPresentCreateContentMenu = async () => {
    const type = await showActionSheet({
      buttons: [
        { text: "Nyhet", data: "news" },
        { text: "Event", data: "event" },
        { text: "Erbjudande", data: "offer" },
        { text: "Förfrågan", data: "request" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
    });
    if (type) {
      router.push(`/u/admin/create/${type}`);
    }
  };

  const getContentUrl = (type) => {
    if (type === "offer" || type === "request") {
      return `${getSetting(
        "BACKEND"
      )}/jsonapi/node/${type}?sort=-created&include=entitygroupfield.gid`;
    } else if (type === "event") {
      return `${getSetting("BACKEND")}/jsonapi/group/${type}?sort=-created`;
    }
    return `${getSetting("BACKEND")}/jsonapi/node/${type}?sort=-created`;
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader title="Hantera innehåll">
        <PartnerContainer>
          <PartnerButton
            icon="icons/plus16.svg"
            text="Skapa innehåll"
            expand={true}
            onClick={doPresentCreateContentMenu}
          />
          <PartnerButton
            expand={true}
            modifiers="partner-button--secondary"
            routerLink="/u/admin/taxonomy/industry"
          >
            Hantera branscher
          </PartnerButton>

          <div className="partner-searchbar partner-searchbar--margin-top">
            <IonSearchbar
              placeholder="Sök efter innehåll..."
              searchIcon="icons/search24.svg"
              value={filter}
              onIonChange={(e) => setFilter(e.detail.value)}
              onIonClear={() => setFilter("")}
            />
          </div>
        </PartnerContainer>

        <IonList>
          <PartnerSelect
            icon="icons/chevron-down16.svg"
            label="Typ av innehåll"
            value={contentFilter}
            onChange={(e) => setContentFilter(e.detail.value)}
            options={[
              { label: "Nyheter", value: "news" },
              { label: "Event", value: "event" },
              { label: "Erbjudande", value: "offer" },
              { label: "Förfrågan", value: "request" },
            ]}
          />
        </IonList>

        <PartnerContainer>
          <IonList className="app-list">
            {isLoading && content.length === 0 && (
              <>
                <PartnerSkeletonItemContent />
                <PartnerSkeletonItemContent />
                <PartnerSkeletonItemContent />
              </>
            )}
            {content
              .filter(
                (item) =>
                  filter === "" ||
                  (item.title ?? item.label ?? "")
                    .toLowerCase()
                    .includes(filter.toLowerCase())
              )
              .map((item) => (
                <PartnerItemContent
                  key={item.id}
                  type={getContentTypeName(contentFilter)}
                  date={
                    contentFilter === "event"
                      ? formatDate(item.field_date?.value)
                      : formatDate(item.created)
                  }
                  onClick={() =>
                    router.push(`/u/admin/${contentFilter}/${item.id}`)
                  }
                  info={item.title ?? item.label}
                  inactive={!item.status}
                  admin={true}
                  onAdminClick={(e) => {
                    e.stopPropagation();
                    doPresentContextMenu(contentFilter, item.id, item.status);
                  }}
                />
              ))}
          </IonList>
        </PartnerContainer>
      </PageWithCollapsableHeader>
      <zeus-data-provider
        id="admin-content"
        listen-tags={`${entityType}-${contentFilter}`}
      >
        <zeus-data-source-jsonapi
          url={getContentUrl(contentFilter)}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default AdminContent;
