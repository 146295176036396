import { IonList, IonPage, isPlatform, useIonActionSheet } from "@ionic/react";
import { useParams } from "react-router";
import { useDynamicDataProvider } from "@understandit/dynamic-data-react";
import {
  PartnerContainer,
  PartnerHeading,
  PartnerLogo,
  PartnerText,
  PartnerItemPerson,
  PageWithCollapsableHeader,
} from "@understandit/partnerplattformen-storybook-30876108";

import { getSetting } from "../settings";
import { classList, isAdminForPartner } from "../helpers";
import { useConfirmDangerousAction, useActionModal } from "../hooks";
import SocialMediaIcons from "../components/SocialMediaIcons";

const NetworkDetail = ({ title, defaultHref }) => {
  const { id } = useParams();
  const providerId = `partner-${id}`;
  const memberProviderId = `partner-members-${id}`;
  const [membership] = useDynamicDataProvider("partner-group-membership", null);
  const [partner] = useDynamicDataProvider(providerId, []);
  const [members] = useDynamicDataProvider(memberProviderId, []);
  const [present] = useIonActionSheet();
  const confirmDangerousAction = useConfirmDangerousAction();
  const doAction = useActionModal();

  const isAdmin = isAdminForPartner(membership, partner);

  const doOpenAdminMenu = async (e, memberId) => {
    e?.preventDefault();
    e?.stopPropagation();

    present({
      buttons: [
        { text: "Radera", role: "destructive", data: "delete" },
        { text: "Avbryt", role: "cancel", data: "cancel" },
      ],
      onDidDismiss: async (event) => {
        if (event.detail.data === "delete") {
          const confirm = await confirmDangerousAction(
            "Är du säker på att du vill radera användaren?"
          );
          if (confirm) {
            doAction("delete-membership", { partnerId: partner.id, memberId });
          }
        }
      },
    });
  };

  return (
    <IonPage>
      <PageWithCollapsableHeader
        title={title ?? "Nätverket"}
        mode="back"
        defaultHref={defaultHref ?? "/u/network"}
      >
        <PartnerContainer>
          <PartnerLogo
            modifiers="partner-logo--center"
            logo={partner?.field_logo?.image_style_uri.large}
          ></PartnerLogo>
          <PartnerHeading
            modifiers="partner-heading--margin-top"
            text={partner?.label}
          />
          <PartnerText modifiers="partner-text--center partner-text--small-margin-top partner-text--info-caption">
            {partner?.field_email && <span>{partner?.field_email}</span>}
            {partner?.field_partner_type?.name && (
              <span>{partner?.field_partner_type?.name}</span>
            )}
          </PartnerText>
          <PartnerText modifiers="partner-text--margin-top partner-text--margin-bottom">
            {partner?.field_description}
          </PartnerText>
          <IonList className="app-list">
            {members
              .filter((item) => !Boolean(item.field_hide_contact_details))
              .map((item) => (
                <PartnerItemPerson
                  modifiers={classList(
                    isAdmin &&
                      item.id !== membership.id &&
                      isPlatform("ios") &&
                      "partner-item-person--admin-with-arrow"
                  )}
                  routerLink={`/u/network/profile/${item.id}`}
                  key={item.id}
                  name={item.field_name ?? item.entity_id.mail}
                  img={item?.field_avatar?.image_style_uri.medium}
                  title={item.field_job_title}
                  admin={isAdmin && item.id !== membership.id}
                  onAdminClick={(e) => doOpenAdminMenu(e, item.id)}
                />
              ))}
          </IonList>
        </PartnerContainer>
        <PartnerContainer>
          <SocialMediaIcons membership={partner} />
        </PartnerContainer>
      </PageWithCollapsableHeader>

      <zeus-data-provider id={providerId}>
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group/partner/${id}?include=field_logo,field_partner_type`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
      <zeus-data-provider id={memberProviderId} listen-tags="partner-members">
        <zeus-data-source-jsonapi
          url={`${getSetting(
            "BACKEND"
          )}/jsonapi/group_content/partner-group_membership?filter[gid.id]=${id}&include=field_avatar,entity_id`}
        ></zeus-data-source-jsonapi>
        <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
      </zeus-data-provider>
    </IonPage>
  );
};

export default NetworkDetail;
