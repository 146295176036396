import { useMemo, useRef, useState } from "react";
import { useZeusDataProvider } from "@understandit/dynamic-data-react";

import { getSetting } from "../settings";
import { makeid } from "../helpers";

export function useEventProvider(eventId) {
  const [randomId] = useState(makeid(10));
  const providerId = `event-provider-${eventId}-${randomId}`;

  const { data, isLoading } = useZeusDataProvider(providerId, null);
  const ref = useRef();

  const Provider = useMemo(() => {
    const Component = () => {
      const params = {
        include:
          "field_image,field_event_access_term,field_event_access_partner",
      };

      const query = new URLSearchParams(params);
      const url = `${getSetting(
        "BACKEND"
      )}/jsonapi/group/event/${eventId}?${query}`;

      return (
        <zeus-data-provider
          ref={ref}
          id={providerId}
          listen-tags={`event,event-${eventId}`}
        >
          <zeus-data-source-jsonapi url={url}></zeus-data-source-jsonapi>
          <zeus-data-middleware-oauth token-provider="auth"></zeus-data-middleware-oauth>
        </zeus-data-provider>
      );
    };
    return Component;
  }, [providerId, eventId]);

  return {
    ref,
    providerId,
    Provider,
    event: data,
    isLoading,
  };
}
