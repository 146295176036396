import { Browser } from "@capacitor/browser";

const InAppLink = ({ href, children }) => {
  const openInApp = (e) => {
    e.preventDefault();
    Browser.open({ url: e.target.href });
  };

  return (
    <a target="_blank" href={href} rel="noreferrer" onClick={openInApp}>
      {children}
    </a>
  );
};

export default InAppLink;
